import { useSelector } from 'react-redux';
import { authSelectors } from '../store/slices/auth';
/**
 * @description Get current Magic Link (If using one) logo data to display on page
 * @returns Media Item
 */
export var useMagicLinkLogo = function () {
    var _a;
    // TODO: Make a request to redux selector here to retrieve Current Magic Link Media Data
    var magicLinkData = useSelector(authSelectors.magicLinkData);
    return {
        media: magicLinkData === null || magicLinkData === void 0 ? void 0 : magicLinkData.logo,
        logoStyles: (_a = magicLinkData === null || magicLinkData === void 0 ? void 0 : magicLinkData.metaData) === null || _a === void 0 ? void 0 : _a.logoStyles,
    };
};
