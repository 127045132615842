import { URL_PARAMS } from '../constants/url';
import { isLocalhost } from './getServerUrl';
/**
 * If the current URL contains a magic link, this will return it.
 *
 * It is still unknown whether this is a *valid* magic link until it's verified
 * by the Hub.
 */
export var getMagicLinkFromCurrentUrl = function () {
    var url = new URL(window.location.href);
    return url.searchParams.get(URL_PARAMS.MAGIC_LINK);
};
export var getTestCodeFromCurrentUrl = function () {
    var url = new URL(window.location.href);
    return url.searchParams.get(URL_PARAMS.TEST_CODE);
};
export var getIsTestDomainUrl = function () {
    var hostname = window.location.hostname;
    if (!isLocalhost &&
        (hostname === 'project-test-sta.dynamicly.io' ||
            hostname.includes('-test.dynamiclyapps.com'))) {
        return true;
    }
    else {
        return false;
    }
};
