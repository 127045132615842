export var ViewportMediaQueries;
(function (ViewportMediaQueries) {
    ViewportMediaQueries["TabletAndAbove"] = "@media screen and (min-width: 768px)";
    ViewportMediaQueries["TabletAndBelow"] = "@media screen and (max-width: 768px)";
})(ViewportMediaQueries || (ViewportMediaQueries = {}));
export var Viewports;
(function (Viewports) {
    Viewports["Mobile"] = "Mobile";
    Viewports["Tablet"] = "Tablet";
    Viewports["Desktop"] = "Desktop";
})(Viewports || (Viewports = {}));
