import { createSlice } from '@reduxjs/toolkit';
import flowTracking from '../thunks/flowTracking';
;
var flowSlice = createSlice({
    name: 'flow',
    initialState: {
        fullUserPath: [],
        userSectionPath: [],
        currentPageSeenAt: null,
    },
    reducers: {
        setFlowDuration: function (state, action) {
            var stepIdToUpdate = action.payload;
            state.fullUserPath.forEach(function (flowStep, index) {
                if (flowStep.step.id === stepIdToUpdate && (flowStep === null || flowStep === void 0 ? void 0 : flowStep.duration) === 0) {
                    flowStep.duration = Date.now() - flowStep.timestamp;
                }
            });
        },
    },
    extraReducers: function (builder) {
        builder.addCase(flowTracking.fulfilled, function (state, action) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            // Only add the item to the full user path if it's different from the last
            // one. This will prevent duplicates from being added on page refresh.
            if (((_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.step) === null || _b === void 0 ? void 0 : _b.id) && ((_e = (_d = (_c = state.fullUserPath) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.step) === null || _e === void 0 ? void 0 : _e.id) !== action.payload.step.id) {
                state.fullUserPath.unshift(action.payload);
            }
            var previousSection = (_f = state.userSectionPath) === null || _f === void 0 ? void 0 : _f[1];
            // If the next section is equal to the previous section, we detect this as
            // a "back" action and remove the section from the stack. The layout of
            // the study *should* prevent false positives.
            if (action.payload.step.groupId === ((_g = previousSection === null || previousSection === void 0 ? void 0 : previousSection.step) === null || _g === void 0 ? void 0 : _g.groupId)) {
                state.userSectionPath.shift();
            }
            // Otherwise, it's a new section
            else {
                // Prevent duplicates on refresh
                if (action.payload.step.groupId !== ((_k = (_j = (_h = state.userSectionPath) === null || _h === void 0 ? void 0 : _h[0]) === null || _j === void 0 ? void 0 : _j.step) === null || _k === void 0 ? void 0 : _k.groupId)) {
                    state.userSectionPath.unshift(action.payload);
                }
            }
        });
    }
});
export var flowSelectors = {
    fullUserPath: function (store) { return store.flow.fullUserPath; },
    userSectionPath: function (store) { return store.flow.userSectionPath; },
};
export var flowActions = flowSlice.actions;
export default flowSlice;
