var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
/**
 * Note: If you change the value of TIMEOUT_MILLISECONDS, you have to update the
 * values for `animation` of the selector `show` in `./styles.ts` file.
 */
var TIMEOUT_MILLISECONDS = 3000;
// @todo: make this component for various purposes by receiving like `type` prop.
var Toast = function (_a) {
    var show = _a.show, type = _a.type, _b = _a.autoClose, autoClose = _b === void 0 ? false : _b, children = _a.children;
    var styles = useStyles();
    var _c = __read(useState(false), 2), animClass = _c[0], setAnimClass = _c[1];
    useEffect(function () {
        var startTimeOut = function () {
            return setTimeout(function () {
                setAnimClass(false);
            }, TIMEOUT_MILLISECONDS);
        };
        var timeOut;
        if (show) {
            setAnimClass(true);
            if (autoClose) {
                timeOut = startTimeOut();
            }
        }
        else {
            setAnimClass(false);
        }
        return function () {
            if (autoClose) {
                clearTimeout(timeOut);
            }
        };
    }, [show]);
    return (React.createElement("div", { className: "".concat(styles.toast, " ").concat(animClass ? "".concat(styles.show, " ").concat(autoClose ? styles.animationAutoClose : '') : '', " ").concat(styles[type]) },
        type === 'success' && React.createElement(CheckCircleOutlinedIcon, null),
        type === 'warning' && React.createElement(ReportProblemOutlinedIcon, null),
        type === 'error' && React.createElement(ErrorOutlineOutlinedIcon, null),
        children));
};
export default Toast;
