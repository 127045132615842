import { createSlice } from '@reduxjs/toolkit';
import pkg from '../../../package.json';
import authenticateStudy from '../thunks/authenticateStudy';
import { authActions } from './auth';
var appSlice = createSlice({
    name: 'app',
    initialState: {
        version: pkg.version,
        loaded: false,
    },
    reducers: {
        setLoaded: function (state, action) {
            state.loaded = action.payload;
        },
    },
    extraReducers: function (builder) {
        // set `loaded` false when it tries to authenticate a project app instance.
        builder.addCase(authenticateStudy.pending, function (state, action) {
            state.loaded = false;
        });
        builder.addCase(authActions.userSessionStillAuthenticatedFailure, function (state, action) {
            state.loaded = false;
        });
    },
});
export var appSelectors = {
    version: function (store) { return store.app.version; },
    loaded: function (store) { return store.app.loaded; },
};
export var appActions = appSlice.actions;
export default appSlice;
