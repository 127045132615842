import { createSlice } from "@reduxjs/toolkit";
import submitForm from "../thunks/submitForm";
var formSubmissionSlice = createSlice({
    name: 'formSubmission',
    initialState: {
        loading: false,
        submitted: false,
        error: null,
        contactInfo: null,
        formSubmissions: [],
    },
    reducers: {
        saveContactInfo: function (state, action) {
            state.contactInfo = action.payload;
        },
        clearFormSubmissions: function (state) {
            state.formSubmissions = [];
            state.loading = false;
            state.submitted = false;
            state.error = null;
        },
        resetSubmitted: function (state) {
            state.submitted = false;
        }
    },
    extraReducers: function (builder) {
        builder.addCase(submitForm.pending, function (state, action) {
            state.loading = true;
            state.submitted = false;
            state.error = null;
        });
        builder.addCase(submitForm.fulfilled, function (state, action) {
            var data = action.payload;
            if (data) {
                state.loading = false;
                state.submitted = true;
                state.formSubmissions.push(data);
                state.error = null;
            }
            else {
                state.loading = false;
                state.submitted = false;
                state.error = true;
            }
        });
        builder.addCase(submitForm.rejected, function (state, action) {
            state.loading = false;
        });
    }
});
export var formSubmissionSelectors = {
    loading: function (store) { return store.formSubmission.loading; },
    submitted: function (store) { return store.formSubmission.submitted; },
    error: function (store) { return store.formSubmission.error; },
    contactInfo: function (store) { return store.formSubmission.contactInfo; },
    formSubmissions: function (store) { return store.formSubmission.formSubmissions; },
};
export var formSubmissionActions = formSubmissionSlice.actions;
export default formSubmissionSlice;
