import { useEffect } from 'react';
import packageJson from '../../../package.json';
var AppVersion = function () {
    useEffect(function () {
        var version = packageJson.version;
        console.log(version);
    }, []);
    return null;
};
export default AppVersion;
