var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toastSelectors } from '../../store/slices/toast';
import Toast from '../Toast';
var GlobalToast = function () {
    var id = useSelector(toastSelectors.id);
    var type = useSelector(toastSelectors.type);
    var content = useSelector(toastSelectors.content);
    var _a = __read(useState(id), 2), initialId = _a[0], _ = _a[1];
    return (React.createElement(Toast, { key: id, show: initialId !== id, type: type, autoClose: true }, content));
};
export default GlobalToast;
