import { makeStyles } from '@material-ui/core';
import { ViewportMediaQueries } from '../../constants/viewport';
var opacity = 0.85;
var bottomDesktop = '10px';
export var useStyles = makeStyles(function (theme) {
    var _a, _b;
    var _c, _d, _e, _f, _g, _h;
    return ({
        '@-webkit-keyframes fadein': {
            from: {
                bottom: 0,
                opacity: 0,
            },
            to: {
                bottom: bottomDesktop,
                opacity: opacity,
            },
        },
        '@keyframes fadein': {
            from: {
                bottom: 0,
                opacity: 0,
            },
            to: {
                bottom: bottomDesktop,
                opacity: opacity,
            },
        },
        '@-webkit-keyframes fadeout': {
            from: {
                bottom: bottomDesktop,
                opacity: opacity,
            },
            to: {
                bottom: 0,
                opacity: 0,
            },
        },
        '@keyframes fadeout': {
            from: {
                bottom: bottomDesktop,
                opacity: opacity,
            },
            to: {
                bottom: 0,
                opacity: 0,
            },
        },
        '@-webkit-keyframes fadein-mobile': {
            from: {
                opacity: 0,
            },
            to: {
                opacity: opacity,
            },
        },
        '@keyframes fadein-mobile': {
            from: {
                opacity: 0,
            },
            to: {
                opacity: opacity,
            },
        },
        '@-webkit-keyframes fadeout-mobile': {
            from: {
                opacity: opacity,
            },
            to: {
                opacity: 0,
            },
        },
        '@keyframes fadeout-mobile': {
            from: {
                opacity: opacity,
            },
            to: {
                opacity: 0,
            },
        },
        toast: (_a = {
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
                visibility: 'hidden',
                minWidth: '250px',
                minHeight: '53px',
                color: '#fff',
                textAlign: 'center',
                borderRadius: '4px',
                padding: '6px 26px',
                position: 'fixed',
                zIndex: 9999,
                bottom: bottomDesktop,
                left: '50%',
                transform: 'translateX(-50%)',
                opacity: opacity,
                cursor: 'default',
                pointerEvents: 'none',
                fontSize: '14px',
                lineHeight: 1.43,
                letterSpacing: '0.15px'
            },
            _a[ViewportMediaQueries.TabletAndBelow] = {
                maxWidth: '90%',
                padding: '6px 10px',
                textAlign: 'left'
            },
            _a),
        show: {
            visibility: 'visible',
        },
        success: {
            backgroundColor: ((_d = (_c = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _c === void 0 ? void 0 : _c.success) === null || _d === void 0 ? void 0 : _d.main) || '#4caf50',
        },
        warning: {
            backgroundColor: ((_f = (_e = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _e === void 0 ? void 0 : _e.warning) === null || _f === void 0 ? void 0 : _f.main) || '#ff9800',
        },
        error: {
            backgroundColor: ((_h = (_g = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _g === void 0 ? void 0 : _g.error) === null || _h === void 0 ? void 0 : _h.main) || '#f44336',
        },
        // If you set the sum of the duration for fade out and delay to the exactly
        // same as `TIMEOUT_MILLISECONDS`(defined in SnackBar), the SnackBar
        // sometimes could be blinking after the animation is finished. That's why
        // the sum of the durations are over TIMEOUT_MILLISECONDS here.
        animationAutoClose: (_b = {
                '-webkit-animation': '$fadein 0.5s, $fadeout 0.5s 2.55s',
                animation: '$fadein 0.5s, $fadeout 0.5s 2.55s'
            },
            _b[ViewportMediaQueries.TabletAndBelow] = {
                '-webkit-animation': '$fadein-mobile 0.5s, $fadeout-mobile 0.5s 2.55s',
                animation: '$fadein-mobile 0.5s, $fadeout-mobile 0.5s 2.55s',
            },
            _b),
    });
});
