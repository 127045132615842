import React from 'react';
import Button from '../../components/Button';
import { useStyles } from './styles';
import { useDispatch } from 'react-redux';
import { langActions } from '../../store/slices/lang';
var LanguagePrompt = function (_a) {
    var languages = _a.languages;
    var styles = useStyles();
    var dispatch = useDispatch();
    var handleButtonClick = function (language) {
        // Note: the difference between `setByUser` and `set` is whether or not the
        // `selected` value in `lang` state is changed.
        dispatch(langActions.setByUser(language));
    };
    var generateButtons = function () {
        return languages.map(function (language) {
            var displayNames = new Intl.DisplayNames([language], {
                type: 'language',
            });
            var localizedTitle = displayNames.of(language);
            return localizedTitle ? (React.createElement(Button, { key: "lang-button-".concat(language), className: styles.button, theme: 'outline', onClick: function () { return handleButtonClick(language); } }, localizedTitle)) : null;
        });
    };
    return (React.createElement("div", { className: styles.page },
        React.createElement("div", { className: styles.wrapper },
            React.createElement("img", { className: styles.logo, src: '/images/biospective-logo.svg' }),
            React.createElement("div", { className: styles.buttons }, generateButtons()))));
};
export default LanguagePrompt;
