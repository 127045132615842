import { ENVS } from '../constants/env';
import { STAGING_URL_HOST } from '../constants/url';
var getCurrentEnv = function () {
    if (process.env.NODE_ENV === 'development' || window.location.href.includes('localhost:') || window.location.href.includes('//192.168.')) {
        return ENVS.DEV;
    }
    else if (window.location.host === STAGING_URL_HOST || window.location.host.includes('.dynamicly.io')) {
        return ENVS.STAGING;
    }
    else {
        return ENVS.PROD;
    }
};
export default getCurrentEnv;
