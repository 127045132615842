import { createSlice } from "@reduxjs/toolkit";
import downloadPdf from "../thunks/downloadPdf";
var pdfDownloadSlice = createSlice({
    name: 'pdfDownload',
    initialState: {
        loading: false,
        success: false,
        error: null,
        data: null,
    },
    reducers: {},
    extraReducers: function (builder) {
        builder.addCase(downloadPdf.pending, function (state, action) {
            state.loading = true;
            state.success = false;
            state.error = null;
            state.data = null;
        });
        builder.addCase(downloadPdf.fulfilled, function (state, action) {
            var data = action.payload;
            if (data) {
                state.loading = false;
                state.success = true;
                state.error = null;
                state.data = data;
            }
            else {
                state.loading = false;
                state.success = false;
                state.error = true;
                state.data = null;
            }
        });
        builder.addCase(downloadPdf.rejected, function (state, action) {
            state.loading = false;
        });
    }
});
export var pdfDownloadSelectors = {
    loading: function (store) { return store.pdfDownload.loading; },
    success: function (store) { return store.pdfDownload.success; },
    error: function (store) { return store.pdfDownload.error; },
    data: function (store) { return store.pdfDownload.data; },
};
export var pdfDownloadActions = pdfDownloadSlice.actions;
export default pdfDownloadSlice;
