import { lighten, makeStyles } from '@material-ui/core/styles';
import { ViewportMediaQueries } from '../../constants/viewport';
var navGutter = 16;
export var useStyles = makeStyles(function (theme) {
    var _a, _b, _c, _d, _e, _f, _g;
    var _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    return ({
        nav: (_a = {
                width: '100%',
                position: 'relative',
                alignSelf: 'flex-end',
                zIndex: 1000,
                paddingBottom: theme.spacing(6),
                '& .study-progress-bar': {
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    right: 0,
                },
                '& .progress-bar-total': {
                    height: 2,
                }
            },
            _a[ViewportMediaQueries.TabletAndAbove] = {
                paddingTop: 22,
                position: 'static',
                borderTop: 'none',
                display: 'flex',
                alignSelf: 'flex-end',
                flexDirection: 'column',
            },
            _a),
        hasStickyAudioPlayer: (_b = {},
            _b[ViewportMediaQueries.TabletAndBelow] = {
                paddingTop: 105,
            },
            _b),
        stepNav: (_c = {
                gap: 10
            },
            _c[ViewportMediaQueries.TabletAndAbove] = {
                paddingTop: 0,
                position: 'static',
                borderTop: 'none',
                '& button': {
                    maxWidth: 330,
                    minWidth: 330,
                },
            },
            _c),
        stepNavSpacing: (_d = {
                padding: "".concat(navGutter, "px 25px")
            },
            _d['&[data-num-buttons="0"]'] = {
                padding: 3,
            },
            _d),
        stepNavSingleButton: {
            width: '100%',
        },
        buttonContainer: (_e = {
                display: 'flex',
                gap: '10px',
                '& button': {
                    margin: '0 auto',
                }
            },
            _e[ViewportMediaQueries.TabletAndBelow] = {
                width: '100% !important',
            },
            _e),
        prevButton: {
            flex: '1 1 0',
            border: "1px solid ".concat(((_h = theme.palette.navigationButton) === null || _h === void 0 ? void 0 : _h.main) ||
                theme.palette.secondary.main),
            color: ((_j = theme.palette.navigationButton) === null || _j === void 0 ? void 0 : _j.main) || theme.palette.secondary.main,
            '&:hover': {
                backgroundColor: ((_k = theme.palette.navigationButton) === null || _k === void 0 ? void 0 : _k.hover) ||
                    lighten(((_l = theme.palette.navigationButton) === null || _l === void 0 ? void 0 : _l.main) || theme.palette.secondary.main, 0.125),
            },
        },
        previousSectionButton: {
            flex: '1 1 0',
            maxWidth: '100% !important',
        },
        commonNavContained: {
            backgroundColor: (_m = theme.palette.navigationButton) === null || _m === void 0 ? void 0 : _m.main,
            color: ((_o = theme.palette.navigationButton) === null || _o === void 0 ? void 0 : _o.contrastText) ||
                theme.palette.secondary.contrastText,
            '&:hover': {
                backgroundColor: (_p = theme.palette.navigationButton) === null || _p === void 0 ? void 0 : _p.main,
            },
        },
        nextButton: {
            flex: '1 1 0',
        },
        conditionalButton: {
            maxWidth: "calc(50% - ".concat(navGutter / 2, "px)"),
            flexGrow: 1,
            marginLeft: navGutter / 2,
        },
        suggestionButton: (_f = {
                width: '100%',
                backgroundColor: (_q = theme.palette.suggestionButton) === null || _q === void 0 ? void 0 : _q.main,
                color: ((_r = theme.palette.suggestionButton) === null || _r === void 0 ? void 0 : _r.contrastText) ||
                    theme.palette.secondary.contrastText
            },
            _f[ViewportMediaQueries.TabletAndAbove] = {
                '&:hover': {
                    backgroundColor: ((_s = theme.palette.suggestionButton) === null || _s === void 0 ? void 0 : _s.hover) ||
                        ((_t = theme.palette.suggestionButton) === null || _t === void 0 ? void 0 : _t.main) ||
                        theme.palette.secondary.main,
                },
            },
            _f),
        suggestionButtonGroup: {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
        },
        swiper: {
            width: '100% !important', // This might cause issues when there are more than 1 suggestion button, but it's ok for the phase 1 launch
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            '& .swiper-wrapper': {
                width: '100% !important', // This might cause issues when there are more than 1 suggestion button, but it's ok for the phase 1 launch
            },
            '& .swiper-slide': {
                width: '100% !important', // This might cause issues when there are more than 1 suggestion button, but it's ok for the phase 1 launch
            },
            '& button': {
                margin: '0 auto',
            },
            '& .swiper-pagination': {
                position: 'static',
                '& .swiper-pagination-bullet': {
                    backgroundColor: ((_u = theme.palette.suggestionButton) === null || _u === void 0 ? void 0 : _u.main) || theme.palette.secondary.main,
                },
            },
        },
        formSubmitContainer: {
            '& button': (_g = {
                    width: '100% !important',
                    margin: '0 25px'
                },
                _g[ViewportMediaQueries.TabletAndAbove] = {
                    maxWidth: 330,
                    minWidth: 330,
                },
                _g),
        },
    });
});
