var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import StudyStepNav from '../StudyStepNav';
import Question from '../Question';
import useSessionAnswers from '../../hooks/useSessionAnswers';
import useCurrentQuestionIds from '../../hooks/useCurrentQuestionIds';
import { flowActions, flowSelectors, } from '../../store/slices/flow';
import flowTracking from '../../store/thunks/flowTracking';
import { selectors as taskStepperSelectors, actions as taskStepperActions, } from 'dyn-design/dist/components/TaskStepper/slice';
import { URL_PARAMS } from '../../constants/url';
import { useStyles } from './styles';
import { studySelectors } from '../../store/slices/study';
import { formSubmissionActions, formSubmissionSelectors, } from '../../store/slices/formSubmission';
import { useAppEngineDynalytics } from '../../hooks/useAppEngineDynalytics';
import useCurrentStep from '../../hooks/useCurrentStep';
import generateTrackedButtonData from '../../utils/generateTrackedButtonData';
import { SESSION_STORAGE_KEYS } from '../../constants/storageKey';
import { COMPONENT_TYPES } from 'dyn-design/dist/interfaces/StudyPageDataTypes';
import { navigationActions, navigationSelectors, } from '../../store/slices/navigation';
var StudyStepBody = function () {
    var dispatch = useDispatch();
    var dynalytics = useAppEngineDynalytics();
    var styles = useStyles();
    var fullUserPath = useSelector(flowSelectors.fullUserPath);
    var currentStudyPage = useSelector(studySelectors.currentPage);
    var currentPageQuestions = useSelector(studySelectors.questionsInCurrentPage);
    var steps = useSelector(taskStepperSelectors.steps);
    var currentStepNumber = useSelector(taskStepperSelectors.currentStep);
    var currentStep = useCurrentStep();
    var sessionAnswersForThisPage = useSessionAnswers({
        forCurrentPageOnly: true,
    });
    var currentQuestionIds = useCurrentQuestionIds();
    var _a = __read(useState({}), 2), currentPageAnswers = _a[0], setCurrentPageAnswers = _a[1];
    var _b = __read(useState({}), 2), stepViewTimes = _b[0], setStepViewTimes = _b[1];
    var _c = __read(useState(false), 2), hasEnteredAllAnswersForCurrentPage = _c[0], setHasEnteredAllAnswersForCurrentPage = _c[1];
    var url = new URL(window.location.href);
    var stepIdToReturnTo = url.searchParams.get(URL_PARAMS.EDIT_ANSWER_THEN_RETURN_TO_REVIEW_PAGE);
    var stepNumToReturnTo = steps.findIndex(function (step) { return (step === null || step === void 0 ? void 0 : step.id) === stepIdToReturnTo; });
    var formSubmissions = useSelector(formSubmissionSelectors.formSubmissions);
    var isThereFormInCurrentPage = !!currentStudyPage.pageForm &&
        currentStudyPage.id === currentStudyPage.pageForm.pageId;
    var alreadyFormSubmitted = isThereFormInCurrentPage &&
        formSubmissions.findIndex(function (submission) {
            var _a, _b;
            return submission.pageId === ((_a = currentStudyPage === null || currentStudyPage === void 0 ? void 0 : currentStudyPage.pageForm) === null || _a === void 0 ? void 0 : _a.pageId) &&
                submission.formId === ((_b = currentStudyPage === null || currentStudyPage === void 0 ? void 0 : currentStudyPage.pageForm) === null || _b === void 0 ? void 0 : _b.id);
        }) >= 0;
    var formSubmittedSuccessfully = useSelector(formSubmissionSelectors.submitted);
    var questionsInCurrentPage = useSelector(studySelectors.questionsInCurrentPage);
    var formQuestions = questionsInCurrentPage.filter(function (inputData) { var _a; return (_a = inputData.question) === null || _a === void 0 ? void 0 : _a.formId; });
    var formQuestionIds = formQuestions.map(function (inputData) { return inputData.question.id; });
    var areAllFormQuestionsOptional = formQuestions.every(function (inputData) { return inputData.question.optional; });
    var _d = __read(useState([]), 2), resetQuestionIds = _d[0], setResetQuestionIds = _d[1];
    // Note: IOMP study has this value only.
    var isStudyFromIOMP = useSelector(studySelectors.requireLanguagePrompt);
    var allMediaPlayedStepIds = useSelector(navigationSelectors.allMediaPlayedStepIds);
    /**
     * Convert the default values to the approopriate format to add into
     * `currentPageAnswers`. SLIDER and RANGE components can have the default
     * value by PAD and it should be considered a user's answer.
     */
    var getAnswersFromDefaultValuesInForm = function () {
        var answers = formQuestions
            .filter(function (question) {
            var _a;
            return (question.componentData.component === COMPONENT_TYPES.RANGE ||
                question.componentData.component === COMPONENT_TYPES.SLIDER) &&
                ((_a = question.componentData.props) === null || _a === void 0 ? void 0 : _a.defaultInterval);
        })
            .reduce(function (prev, inputData) {
            prev[inputData.question.id] = {
                // @ts-ignore
                answer: inputData.componentData.props.defaultInterval,
                input: inputData.componentData,
                question: inputData.question,
                step: currentStep,
                timestamp: Date.now(),
            };
            return prev;
        }, {});
        return answers;
    };
    /**
     * Reset questions in a form. This will clear the answers.
     */
    useEffect(function () {
        if (formSubmittedSuccessfully) {
            setResetQuestionIds(formQuestionIds);
            // set non-form questions answers to `currentPageAnswers`
            var newCurrentPageAnswers_1 = __assign({}, currentPageAnswers);
            formQuestionIds.forEach(function (formQuestionId) { return delete newCurrentPageAnswers_1[formQuestionId]; });
            // set the default values of form questions to `currentPageAnswers`
            setCurrentPageAnswers(__assign(__assign({}, newCurrentPageAnswers_1), getAnswersFromDefaultValuesInForm()));
        }
    }, [formSubmittedSuccessfully]);
    /**
     * On init of this page's questions, if we have session answers saved in the
     * store, load them into the state of this component to unlock the "Next"
     * button and to edit their answer.
     */
    useLayoutEffect(function () {
        if (Object.keys(sessionAnswersForThisPage).length &&
            Object.keys(currentQuestionIds).length) {
            setCurrentPageAnswers(__assign({}, sessionAnswersForThisPage));
        }
    }, []);
    /**
     * Handle all onChange events for all inputs that the user can use.
     */
    var onAnswerChange = function (allAnswersOnCurrentPage) {
        var e_1, _a, _b;
        try {
            for (var _c = __values(Object.entries(allAnswersOnCurrentPage)), _d = _c.next(); !_d.done; _d = _c.next()) {
                var _e = __read(_d.value, 2), questionId = _e[0], answerData = _e[1];
                var answer = answerData.answer;
                if (
                // Only add/update the answer if the answer is...
                // not empty array
                ((Array.isArray(answer) && answer.length) ||
                    // or a non empty object
                    (typeof answer === 'object' && Object.keys(answer).length) ||
                    // or a non empty string
                    (typeof answer === 'string' && answer.length) ||
                    // or it is a number
                    typeof answer === 'number') &&
                    // AND it not null and not undefined
                    answer !== null &&
                    answer !== undefined) {
                    // Better to save the page (step) on which a question was answered at
                    // "answer time", rather than app init, in case the app needs to
                    // create new pages at runtime (APP-54).
                    answerData.step = currentStep;
                    answerData.timestamp = Date.now();
                    setCurrentPageAnswers(__assign(__assign({}, currentPageAnswers), (_b = {}, _b[questionId] = answerData, _b)));
                }
                // The answer is empty, remove it's previous entry
                else {
                    var tempAnswers = __assign({}, currentPageAnswers);
                    delete tempAnswers[questionId];
                    setCurrentPageAnswers(tempAnswers);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    /**
     * The user can only click the Next button if they have provided an answer for
     * each required question on the page.
     */
    useEffect(function () {
        var hasAnsweredAllRequiredQuestions = true;
        currentPageQuestions.forEach(function (_a) {
            var _b;
            var question = _a.question;
            var isOptionalQuestion = !!(question === null || question === void 0 ? void 0 : question.optional);
            var isAnswered = Object.keys(currentPageAnswers).includes(question.id);
            // The validation for the questions of a form is handled separately, so
            // this handles questions outside the form only and please see the
            // `shouldNextButtonDisabled` function to check the combined validation.
            var isFormQuestion = !!question.formId;
            if (!isOptionalQuestion && !isAnswered && !isFormQuestion) {
                hasAnsweredAllRequiredQuestions = false;
            }
            // Track first interaction(typing/selecting) with a form
            if (isFormQuestion && isAnswered) {
                var refreshCount = Number(sessionStorage.getItem(SESSION_STORAGE_KEYS.REFRESH_COUNT) || 0);
                var currentInteraction = "".concat(refreshCount, "-").concat(question.formId);
                var formInteractions = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.FORM_INTERACTIONS) ||
                    '[]');
                // Send the form id and name to dynalytics only if it's first
                // interaction (it will be first interaction again if the page is
                // refreshed).
                if (!formInteractions.includes(currentInteraction)) {
                    formInteractions.push(currentInteraction);
                    sessionStorage.setItem(SESSION_STORAGE_KEYS.FORM_INTERACTIONS, JSON.stringify(formInteractions));
                    dynalytics({
                        factName: 'form_first_interaction',
                        data: {
                            formId: question.formId,
                            formName: (_b = currentStudyPage.pageForm) === null || _b === void 0 ? void 0 : _b.name,
                            step: currentStep,
                        },
                    });
                }
            }
        });
        setHasEnteredAllAnswersForCurrentPage(hasAnsweredAllRequiredQuestions);
    }, [currentPageAnswers]);
    /**
     * Whenever the user loads a new step, add that to their flow history.
     */
    useEffect(function () {
        var type = 'csr';
        var startFlowObj = {
            timestamp: Date.now(),
            event: 'page_load',
            duration: 0,
            step: currentStep,
            chronologicalStep: fullUserPath.length,
            type: type,
        };
        // send 2 beacons: one on mount, one on dismount (with duration)
        dispatch(flowTracking(startFlowObj));
        delete startFlowObj.event;
        dynalytics({ factName: 'page_load', data: startFlowObj });
        return function () {
            var endFlowObj = {
                timestamp: Date.now(),
                event: 'page_leave',
                duration: Date.now() - startFlowObj.timestamp,
                step: currentStep,
                type: type,
            };
            dispatch(flowActions.setFlowDuration(currentStep.id));
            dispatch(flowTracking(endFlowObj));
            delete endFlowObj.event;
            dynalytics({ factName: 'page_leave', data: endFlowObj });
        };
    }, [currentStepNumber]);
    /**
     * Save all page answers in the store.
     *
     * TODO DS-96 this will need to support more than 1 question per page
     */
    var submitPageAnswers = function (e) {
        // User is editing their answer
        if (stepIdToReturnTo) {
            // Remove the edit param
            window.history.replaceState({}, '', window.location.origin + window.location.pathname);
            dispatch(taskStepperActions.completeStep({
                name: Object.keys(currentPageAnswers)[0],
                data: Object.values(currentPageAnswers)[0],
                // FIXME: Remove the math after DS-98 is completed
                nextStep: parseInt(stepNumToReturnTo) - currentStepNumber,
            }));
        }
        // User is entering their initial answer
        else {
            if (Object.keys(currentPageAnswers).length) {
                var formatted = Object.keys(currentPageAnswers).map(function (questionId) {
                    return {
                        name: questionId,
                        data: currentPageAnswers[questionId],
                    };
                });
                // Form data shouldn't be saved into regular study data.
                if (isThereFormInCurrentPage) {
                    dispatch(taskStepperActions.upsertAnswers(formatted.filter(function (answer) { return !formQuestionIds.includes(answer.name); })));
                }
                else {
                    dispatch(taskStepperActions.upsertAnswers(formatted));
                }
            }
            dynalytics({
                factName: 'study_step_next',
                data: __assign({ step: currentStep }, generateTrackedButtonData(e.target)),
            });
            dispatch(taskStepperActions.completeStep({ nextStep: 1 }));
        }
    };
    var checkDefaultValues = function () {
        setCurrentPageAnswers(__assign(__assign({}, getAnswersFromDefaultValuesInForm()), currentPageAnswers));
    };
    /**
     * The Submitted Form should be available for re-submission
     */
    useEffect(function () {
        // Enabling the SUBMIT button could work by the `FormSubmissionButton`'s
        // condition for disabling the button, but it's important that
        // `currentPageAnswers` has the default values as answers because it is
        // passed to `StudyStepNav` and `TaskStepper` uses the data.
        checkDefaultValues();
        // It results setting `alreadyFormSubmitted` to false, so the inputs in the
        // form are enabled and the navigation button is disabled when users get
        // back to the page.
        // Note: The code for clearing the inputs(it means not
        // giving the previous answers of the form to `StepLayout` component) is in
        // `useSessionAnswers` hook.
        return function () {
            if (isThereFormInCurrentPage) {
                dispatch(formSubmissionActions.clearFormSubmissions());
            }
        };
    }, []);
    var handleStepMediaEnded = function (status) {
        if (status.noMedia || status.allEnded) {
            dispatch(navigationActions.addAllMediaPlayedStepId(currentStep.id));
        }
    };
    var shouldNextButtonDisabled = function () {
        // If IOMP study, the next button is disabled until all media files are
        // played (a media with error is considered played). Once the current page
        // is flagged, the next button is enabled when user's back.
        if (isStudyFromIOMP) {
            return !allMediaPlayedStepIds.includes(currentStep.id);
        }
        // The Default Rule: user should answer to all required questions to move on
        // to the next page.
        // If there's no form in the current page, it follows the default rule.
        if (!isThereFormInCurrentPage) {
            return !hasEnteredAllAnswersForCurrentPage;
        }
        // If there's a form and the questions in the form are all optional, it
        // follows the default rule.
        if (areAllFormQuestionsOptional) {
            return !hasEnteredAllAnswersForCurrentPage;
        }
        // If there's a form and at least one question in the form is mandatory, the
        // form must be submitted and the default rule has to be kept for the other
        // questions outside the form
        if (alreadyFormSubmitted) {
            return !hasEnteredAllAnswersForCurrentPage;
        }
        return !formSubmittedSuccessfully;
    };
    return (React.createElement("div", { className: "".concat(styles.stepBody) },
        React.createElement(Question, { onAnswerChange: onAnswerChange, resetQuestionIds: resetQuestionIds, onStepMediaEnded: handleStepMediaEnded }),
        React.createElement(StudyStepNav, { nextButtonDisabled: shouldNextButtonDisabled(), onNextStepClick: submitPageAnswers, currentPageAnswers: currentPageAnswers })));
};
export default StudyStepBody;
