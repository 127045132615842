import { makeStyles } from '@material-ui/core';
import { ViewportMediaQueries } from '../../constants/viewport';
var opacity = 0.85;
var bottomDesktop = '36px';
export var useStyles = makeStyles(function (theme) {
    var _a, _b;
    return ({
        '@-webkit-keyframes fadein': {
            from: {
                bottom: 0,
                opacity: 0,
            },
            to: {
                bottom: bottomDesktop,
                opacity: opacity,
            },
        },
        '@keyframes fadein': {
            from: {
                bottom: 0,
                opacity: 0,
            },
            to: {
                bottom: bottomDesktop,
                opacity: opacity,
            },
        },
        '@-webkit-keyframes fadeout': {
            from: {
                bottom: bottomDesktop,
                opacity: opacity,
            },
            to: {
                bottom: 0,
                opacity: 0,
            },
        },
        '@keyframes fadeout': {
            from: {
                bottom: bottomDesktop,
                opacity: opacity,
            },
            to: {
                bottom: 0,
                opacity: 0,
            },
        },
        '@-webkit-keyframes fadein-mobile': {
            from: {
                opacity: 0,
            },
            to: {
                opacity: opacity,
            },
        },
        '@keyframes fadein-mobile': {
            from: {
                opacity: 0,
            },
            to: {
                opacity: opacity,
            },
        },
        '@-webkit-keyframes fadeout-mobile': {
            from: {
                opacity: opacity,
            },
            to: {
                opacity: 0,
            },
        },
        '@keyframes fadeout-mobile': {
            from: {
                opacity: opacity,
            },
            to: {
                opacity: 0,
            },
        },
        snackBar: (_a = {
                visibility: 'hidden',
                minWidth: '250px',
                minHeight: '42px',
                backgroundColor: '#333',
                color: '#fff',
                textAlign: 'center',
                lineHeight: 1.4,
                borderRadius: '4px',
                padding: '8px 16px',
                position: 'fixed',
                zIndex: 9999,
                bottom: bottomDesktop,
                right: '30px',
                opacity: opacity,
                cursor: 'default',
                pointerEvents: 'none',
                fontSize: '16px'
            },
            _a[ViewportMediaQueries.TabletAndBelow] = {
                right: 'unset',
                bottom: 'unset',
                top: '60px',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '95%',
            },
            _a),
        show: {
            visibility: 'visible',
        },
        // If you set the sum of the duration for fade out and delay to the exactly
        // same as `TIMEOUT_MILLISECONDS`(defined in SnackBar), the SnackBar
        // sometimes could be blinking after the animation is finished. That's why
        // the sum of the durations are over TIMEOUT_MILLISECONDS here.
        animationAutoClose: (_b = {
                '-webkit-animation': '$fadein 0.5s, $fadeout 0.5s 4.55s',
                animation: '$fadein 0.5s, $fadeout 0.5s 4.55s'
            },
            _b[ViewportMediaQueries.TabletAndBelow] = {
                '-webkit-animation': '$fadein-mobile 0.5s, $fadeout-mobile 0.5s 4.55s',
                animation: '$fadein-mobile 0.5s, $fadeout-mobile 0.5s 4.55s',
            },
            _b),
    });
});
