import { useSelector } from 'react-redux';
import { studySelectors } from '../store/slices/study';
import { PAGE_DATA_TYPE } from 'dyn-design/dist/interfaces/StudyPageDataTypes';
var useIsReviewPage = function () {
    var currentStudyPage = useSelector(studySelectors.currentPage);
    return function (pageData) {
        var _a, _b, _c, _d;
        var pageToAnalyze = pageData || currentStudyPage;
        // The only way to determine if a page is a "Review Page" is to look at the first column type.
        return ((_d = (_c = (_b = (_a = pageToAnalyze === null || pageToAnalyze === void 0 ? void 0 : pageToAnalyze.pageData) === null || _a === void 0 ? void 0 : _a.rows) === null || _b === void 0 ? void 0 : _b[0].cols) === null || _c === void 0 ? void 0 : _c[0].content) === null || _d === void 0 ? void 0 : _d.type) === PAGE_DATA_TYPE.REVIEW;
    };
};
export default useIsReviewPage;
