/**
 * For Local, always redirect to port 3103(saas-study-data-service)
 * For Staging & Production, let application gateway handle the request redirect to the corresponding study server url
 */
export var DEV_STUDYDATA_SERVER_URL = 'http://localhost:3103';
export var isLocalhost = Boolean(window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/) ||
    window.location.origin.includes('//192.168.'));
// In other words:
// if window._env_?.PRIVATE_MEDIA_DISABLED = undefined -> getPrivateMediaDisabled() = true -> private media is disabled
// if window._env_?.PRIVATE_MEDIA_DISABLED = true -> getPrivateMediaDisabled() = true -> private media is disabled
// if window._env_?.PRIVATE_MEDIA_DISABLED = false -> getPrivateMediaDisabled() = false -> private media is enabled
// if window._env_?.PRIVATE_MEDIA_DISABLED = "false" -> getPrivateMediaDisabled() = false -> private media is enabled
export var getPrivateMediaDisabled = function () { var _a; return String((_a = window._env_) === null || _a === void 0 ? void 0 : _a.PRIVATE_MEDIA_DISABLED) !== 'false'; };
var getServerUrl = function () { var _a; return isLocalhost ? DEV_STUDYDATA_SERVER_URL : (_a = window._env_) === null || _a === void 0 ? void 0 : _a.STUDYDATA_SERVER_URL; };
export default getServerUrl;
