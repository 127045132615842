import { makeStyles } from '@material-ui/core';
import { ViewportMediaQueries } from '../../constants/viewport';
export var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        stepBody: (_a = {
                width: '100%',
                paddingTop: 10,
                display: 'flex',
                flexWrap: 'wrap'
            },
            _a[ViewportMediaQueries.TabletAndAbove] = {
                height: '100%',
                paddingTop: 'var(--studyNavHeight)',
                paddingBottom: 20,
                '& .step-layout-row': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
                '& .step-layout-col': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            },
            _a),
    });
});
