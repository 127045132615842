import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { login, loginSuccess, loginFailure } from '../slices/user';
import getServerUrl from '../../utils/getServerUrl';
// Create an anonymous user when login
var loginEpic$ = function (action$, state$) {
    return action$.pipe(ofType(login.type), mergeMap(function () {
        var url = getServerUrl();
        return ajax({
            url: "".concat(url, "/api/users"),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: {},
        }).pipe(map(function (data) { return loginSuccess({ userId: data.response.uid }); }), catchError(function (error) { return of(loginFailure("Could not login : ".concat(error))); }));
    }));
};
export default combineEpics(loginEpic$);
