import { makeStyles } from '@material-ui/core';
import { ViewportMediaQueries } from '../../constants/viewport';
export var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        progressBar: (_a = {},
            _a[ViewportMediaQueries.TabletAndAbove] = {
                background: theme.palette.background.default,
            },
            _a['& .progress-bar-total'] = {
                marginTop: 2,
                height: 4,
            },
            _a)
    });
});
