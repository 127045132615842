import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_LANGUAGE } from '../../utils/i18n';
var langSlice = createSlice({
    name: 'lang',
    initialState: {
        current: DEFAULT_LANGUAGE,
        selected: false,
    },
    reducers: {
        set: function (state, action) {
            state.current = action.payload;
        },
        setByUser: function (state, action) {
            state.current = action.payload;
            state.selected = true;
        },
    },
});
export var langSelectors = {
    currentLang: function (store) { return store.lang.current; },
    selected: function (store) { return store.lang.selected; },
};
export var langActions = langSlice.actions;
export default langSlice;
