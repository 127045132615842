var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeStyles } from '@material-ui/core/styles';
import { ViewportMediaQueries } from '../../constants/viewport';
export var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        va: (_a = {
                margin: '0 auto',
                display: 'flex',
                gap: '16px',
                padding: '32px 16px 16px 24px'
            },
            _a[ViewportMediaQueries.TabletAndAbove] = {
                width: 500,
                position: 'relative',
                left: -94,
            },
            _a),
        soleil: {
            width: '48px',
            height: '48px',
            flexShrink: 0,
        },
        messageContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            marginTop: '6px',
        },
        message: __assign(__assign({}, theme.typography.body1), { lineHeight: 1.27, letterSpacing: 'normal', color: theme.palette.primary.contrastText, '& a, & a:visited, & a:link': {
                textDecoration: 'underline',
                color: theme.palette.action.active,
            }, '& p': {
                color: theme.palette.primary.contrastText,
            } }),
    });
});
