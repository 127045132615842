import { makeLeft, makeRight } from 'dyn-design/dist/interfaces/Either';
import { useSelector } from 'react-redux';
import { studySelectors } from '../store/slices/study';
import { PAGE_DATA_TYPE, } from 'dyn-design/dist/interfaces/StudyPageDataTypes';
import { useMemo } from 'react';
/**
 * TODO: Complete this as needed (Basic functionality added)
 * Find the answers given for the current form
 * @param formId current form ID
 * @returns all the answers based on the current formID
 */
export var useCurrentFormAnswers = function (formId, currentPageAnswers, requiredFormQuestions) {
    var currentStudyPage = useSelector(studySelectors.currentPage);
    var shouldDisableSubmitForm = useMemo(function () {
        // Check if all values exist for the all answer-required question IDs in the form
        return !requiredFormQuestions.every(function (inputData) { return currentPageAnswers[inputData.question.id]; });
    }, [formId, currentPageAnswers, currentStudyPage]);
    var handleFindFormQuestionData = function (pageRows) {
        if (Object.keys(currentPageAnswers)) {
            var formAnswers = pageRows.reduce(function (acc, row) {
                row.cols.forEach(function (col) {
                    var colData = col.content;
                    if (colData && colData.type === PAGE_DATA_TYPE.INPUT) {
                        colData.data.forEach(function (pageInput) {
                            var questionAnswer = currentPageAnswers[pageInput.question.id] ||
                                currentPageAnswers[pageInput.question.questionDataId];
                            if (questionAnswer &&
                                pageInput.question.formId === formId &&
                                questionAnswer.question.formId === formId) {
                                acc.push({
                                    answer: questionAnswer.answer,
                                    questionDetails: questionAnswer.question,
                                });
                            }
                        });
                    }
                });
                return acc;
            }, []);
            return formAnswers.length
                ? makeRight(formAnswers)
                : makeLeft('No form answers found');
        }
        else {
            return makeLeft('No answers found for the given page');
        }
    };
    /**
     * Find all the answers for a current form
     * @returns ordered form input answers based on there order on the page
     */
    var findCurrentFormAnswers = function () {
        var _a, _b;
        if (((_a = currentStudyPage.pageForm) === null || _a === void 0 ? void 0 : _a.id) === formId &&
            ((_b = currentStudyPage.pageData) === null || _b === void 0 ? void 0 : _b.rows.length)) {
            return handleFindFormQuestionData(currentStudyPage.pageData.rows);
        }
        else {
            return makeLeft('Incorrect form for the given page');
        }
    };
    return { findCurrentFormAnswers: findCurrentFormAnswers, shouldDisableSubmitForm: shouldDisableSubmitForm }; // TODO: Change this to whatever is needed here
};
