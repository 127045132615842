var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AUTH_METHODS, } from 'dyn-design/dist/interfaces/StudyDataTypes';
import { createSlice } from '@reduxjs/toolkit';
import authenticateStudy from '../thunks/authenticateStudy';
var authSlice = createSlice({
    name: 'auth',
    initialState: {
        loading: false,
        method: undefined,
        authenticated: false,
        error: null,
        magicLink: {
            valid: null,
            link: undefined,
            data: undefined,
        },
        authenticationData: undefined,
        pollingUserAuth: { polling: false, count: 0 },
        customErrorMessage: '',
    },
    reducers: {
        startPolling: function (state, action) {
            state.pollingUserAuth = { polling: true, count: 0 };
        },
        stopPolling: function (state, action) {
            state.pollingUserAuth = { count: 0, polling: false };
        },
        incrementPollingFailureCount: function (state, action) {
            var _a;
            state.pollingUserAuth = __assign(__assign({}, state.pollingUserAuth), { count: state.pollingUserAuth.count + ((_a = action.payload) !== null && _a !== void 0 ? _a : 1) });
        },
        userSessionStillAuthenticatedSuccess: function (state, action) {
            state.pollingUserAuth = __assign(__assign({}, state.pollingUserAuth), { count: 0 });
        },
        userSessionStillAuthenticatedFailure: function (state, action) {
            state.loading = false;
            state.authenticated = false;
            state.magicLink = {
                valid: null,
                link: undefined,
                data: undefined,
            };
            state.authenticationData = undefined;
            state.method = undefined;
            state.error = 403;
        },
    },
    extraReducers: function (builder) {
        builder.addCase(authenticateStudy.pending, function (state, action) {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(authenticateStudy.fulfilled, function (state, action) {
            state.loading = false;
            var authData = action.payload.authData;
            state.authenticated = authData.authenticated;
            state.method = authData.method;
            if (authData.method === AUTH_METHODS.MAGIC_LINK) {
                state.magicLink = {
                    link: authData.link,
                    valid: true,
                    data: authData.magicLinkData,
                };
                state.authenticationData = authData.magicLinkData
                    ? {
                        type: AUTH_METHODS.MAGIC_LINK,
                        data: authData.magicLinkData,
                    }
                    : undefined;
            }
            else if (authData.method === AUTH_METHODS.PUBLIC) {
                state.authenticationData = !action.payload.testMode
                    ? {
                        type: AUTH_METHODS.PUBLIC,
                    }
                    : undefined;
            }
            // To handle errors from SaaS.
            if (action.payload.errorStatus) {
                state.error = action.payload.errorStatus;
            }
            if (action.payload.customErrorMessage) {
                state.customErrorMessage = action.payload.customErrorMessage;
            }
        });
        builder.addCase(authenticateStudy.rejected, function (state, action) {
            state.loading = false;
            state.authenticated = false;
        });
    },
});
export var authSelectors = {
    loading: function (store) { return store.auth.loading; },
    type: function (store) { return store.auth.type; },
    method: function (store) { return store.auth.method; },
    authenticated: function (store) { return store.auth.authenticated; },
    error: function (store) { return store.auth.error; },
    customErrorMessage: function (store) { return store.auth.customErrorMessage; },
    magicLink: function (store) { return store.auth.magicLink.link; },
    magicLinkIsValid: function (store) { return store.auth.magicLink.valid; },
    magicLinkData: function (store) {
        return store.auth.magicLink.data;
    },
    authData: function (store) {
        return store.auth.authenticationData;
    },
};
export var authActions = authSlice.actions;
export default authSlice;
