import { connectRouter } from 'connected-react-router';
import taskStepperSlice from 'dyn-design/dist/components/TaskStepper/slice';
import media from 'dyn-design/dist/components/MediaPlayer/slice';
import { combineReducers } from 'redux';
import history from '../history';
import app from './app';
import auth from './auth';
import study from './study';
import langSlice from './lang';
import remoteData from './remoteData';
import user from './user';
import flow from './flow';
import formSubmission from './formSubmission';
import pdfDownload from './pdfDownload';
import toast from './toast';
import navigation from './navigation';
export default combineReducers({
    router: connectRouter(history),
    app: app.reducer,
    auth: auth.reducer,
    study: study.reducer,
    remoteData: remoteData.reducer,
    taskStepper: taskStepperSlice.reducer,
    lang: langSlice.reducer,
    user: user.reducer,
    flow: flow.reducer,
    mediaPlayer: media.reducer,
    formSubmission: formSubmission.reducer,
    pdfDownload: pdfDownload.reducer,
    toast: toast.reducer,
    navigation: navigation.reducer,
});
