import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../../components/Button';
import { langSelectors } from '../../store/slices/lang';
import { i18n } from '../../utils/i18n';
import i18nStrings from './i18n.json';
import { useStyles } from './styles';
var Maintenance = function () {
    var styles = useStyles();
    var lang = useSelector(langSelectors.currentLang);
    return (React.createElement("div", { className: styles.fourOhFour },
        React.createElement("div", { className: styles.graphic }),
        React.createElement("div", { className: styles.messageBox },
            React.createElement("h1", { className: styles.title }, i18n(i18nStrings['title'], lang)),
            React.createElement("p", { className: styles.message }, i18n(i18nStrings['message'], lang)),
            React.createElement(Button, { href: "/" }, i18n(i18nStrings['home-button'], lang)))));
};
export default Maintenance;
