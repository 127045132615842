/**
 * Generates a button name for tracking clicks.
 *
 * If two buttons/links on the same page have the same text, their name will
 * include their index on the page.
 *
 * If the app has a unique page title for each page, then button names are
 * guaranteed to be unique in the global scope.
 *
 * @param e: Click event.
 * @param [extra]: Optional text to add to the end of the string.
 */
export default function generateButtonName(el, extra) {
    if (!el) {
        console.error('Invalid element');
        return '';
    }
    var pageName = document.title || window.location.pathname || 'no-page-title-or-path';
    var otherButtonsOnPage = Array.from(document.querySelectorAll('a, button'));
    var buttonText = el.innerText;
    // If there is a collision, add the button's instance number to the name
    otherButtonsOnPage
        .filter(function (btn) { return (btn === null || btn === void 0 ? void 0 : btn.innerText) === buttonText; })
        .forEach(function (btn, i, arr) {
        if (arr.length > 1 && btn === el) {
            buttonText = buttonText + " (".concat((i + 1).toString(), ")");
        }
    });
    var nameParts = [buttonText, pageName];
    if (extra) {
        nameParts.push(extra);
    }
    return nameParts.join(' - ');
}
