import { COMPONENT_TYPES } from 'dyn-design/dist/interfaces/StudyPageDataTypes';
var secondsToHHMMSS = function (seconds) {
    // @FIXME: I think that the TimeField returns a string like (HH:MM)
    // instead of a number of seconds when the initial value is submitted without
    // being changed. Needs to be fixed in the DS.
    if (typeof seconds !== 'number') {
        return seconds;
    }
    var date = new Date(0);
    date.setSeconds(seconds);
    return date.toISOString().substr(11, 8);
};
var handleDateString = function (rawAnswer) {
    return new Date(rawAnswer).toDateString();
};
/**
 * Given any answer object, this will format the answer in a human readable
 * string that can be shown to the user.
 */
var useAnswerValueFormatter = function () {
    return function (answerObj) {
        var _a, _b, _c, _d;
        var rawAnswer = answerObj.answer;
        var inputType = answerObj.input.component;
        switch (inputType) {
            case COMPONENT_TYPES.TEXTBOX:
            case COMPONENT_TYPES.MULTILINE_TEXT_BOX:
                return rawAnswer;
            case COMPONENT_TYPES.MULTI_SELECT:
            case COMPONENT_TYPES.SINGLE_SELECT:
            case COMPONENT_TYPES.DROPDOWN:
            case COMPONENT_TYPES.BUTTON:
                return Object.values(rawAnswer).join('\n');
            case COMPONENT_TYPES.RANGE:
                // TODO this will need to need to be updated for ranges that have more than 1 handle
                var selectedIntervalValue_1 = Object.values(rawAnswer)[0];
                var intervalObj = (_c = (_b = (_a = answerObj === null || answerObj === void 0 ? void 0 : answerObj.input) === null || _a === void 0 ? void 0 : _a.props) === null || _b === void 0 ? void 0 : _b.intervals) === null || _c === void 0 ? void 0 : _c.find(function (interval) {
                    return interval.positionValue === selectedIntervalValue_1;
                });
                return (_d = intervalObj === null || intervalObj === void 0 ? void 0 : intervalObj.label) === null || _d === void 0 ? void 0 : _d['EN'];
            case COMPONENT_TYPES.TIME:
            case COMPONENT_TYPES.DURATION:
                return secondsToHHMMSS(rawAnswer);
            case COMPONENT_TYPES.DATE:
                return handleDateString(rawAnswer);
            default:
                return '(Unknown)';
        }
    };
};
export default useAnswerValueFormatter;
