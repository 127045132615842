export var URL_PARAMS;
(function (URL_PARAMS) {
    URL_PARAMS["EDIT_ANSWER_THEN_RETURN_TO_REVIEW_PAGE"] = "e";
    URL_PARAMS["SET_LANGUAGE_ON_INIT"] = "lang";
    // This is a temporary measure to allow study admins to test on prod without
    // adding a bunch of junk data. This should be removed once we have wmnHealth
    // accounts
    URL_PARAMS["DISABLE_PARTIAL_SESSION_SAVING"] = "dynamicly";
    URL_PARAMS["MAGIC_LINK"] = "link";
    URL_PARAMS["TEST_CODE"] = "testcode";
})(URL_PARAMS || (URL_PARAMS = {}));
// FIXME the app engine should not care about any specific study, and all
// external URLs should come from PAD. This will only work until we have a 2nd
// study.
export var DISCRIMINATION_URL;
(function (DISCRIMINATION_URL) {
    DISCRIMINATION_URL["DEV"] = "http://localhost:3000";
    DISCRIMINATION_URL["STAGING"] = "https://wmn002-discrimination.dynamicly.io";
    DISCRIMINATION_URL["PROD"] = "https://discrimination.wmnhealth.org";
})(DISCRIMINATION_URL || (DISCRIMINATION_URL = {}));
export var STAGING_URL_HOST = 'project-test-sta.dynamicly.io';
