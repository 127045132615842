import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { push } from 'connected-react-router';
import { ENVS } from '../../constants/env';
import getCurrentEnv from '../../utils/getCurrentEnv';
import { fetchStudyData, fetchStudyDataSuccess, fetchStudyDataFailure, } from '../slices/study';
import ROUTES from '../../routes';
import getServerUrl from '../../utils/getServerUrl';
var fetchStudyDataEpic$ = function (action$, state$) {
    return action$.pipe(ofType(fetchStudyData.type), mergeMap(function (_a) {
        var payload = _a.payload;
        var url = getServerUrl();
        return ajax({
            url: "".concat(url, "/services/studydata?version=").concat(payload.version),
            method: 'GET',
            headers: {},
        }).pipe(map(function (data) { return fetchStudyDataSuccess(data.response); }), catchError(function (error) {
            return of(fetchStudyDataFailure("Could not fetch studydata : ".concat(error)));
        }));
    }));
};
/**
 * Redirect to  Maintenance page if fetching the studydata fails
 */
var fetchStudyDataFailureEpic$ = function (action$, state$) {
    return action$.pipe(ofType(fetchStudyDataFailure.type), mergeMap(function (_a) {
        var payload = _a.payload;
        // When running locally, use the hardcoded study
        if (getCurrentEnv() === ENVS.DEV) {
            return [];
        }
        // On staging and prod, use the study that was fetched
        else {
            return of(push(ROUTES.MAINTENANCE.path));
        }
    }));
};
export default combineEpics(fetchStudyDataEpic$, fetchStudyDataFailureEpic$);
