var _a;
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { PAGE_DATA_TYPE, } from 'dyn-design/dist/interfaces/StudyPageDataTypes';
import { isQaMode } from 'dyn-design/dist/utils/isQaMode';
import { isLocalhost } from '../../utils/getServerUrl';
import authenticateStudy from '../thunks/authenticateStudy';
import { authActions } from './auth';
var studySlice = createSlice({
    name: 'study',
    // @ts-ignore
    initialState: {
        studyData: undefined,
        status: 'idle',
        error: null,
        sessionId: uuid(),
        sessionIsComplete: false,
    },
    reducers: {
        fetchStudyData: function (state, action) {
            state.status = 'loading';
            state.error = null;
        },
        fetchStudyDataSuccess: function (state, action) {
            state.studyData = action.payload;
            state.status = 'succeeded';
            state.error = null;
        },
        fetchStudyDataFailure: function (state, action) {
            // TODO: uncomment once the engine fetches from PAD and the example inital state is removed
            //state.studyData = {};
            // Allow hardcoded study in dev, or on staging in QA mode
            if (isQaMode() || isLocalhost) {
                state.status = 'succeeded';
            }
            else {
                state.status = 'failed';
            }
            state.error = action.payload;
        },
        setNewSessionId: function (state, action) {
            state.sessionId = uuid();
        },
        setSessionIsComplete: function (state, action) {
            state.sessionIsComplete = action.payload;
        },
    },
    extraReducers: function (builder) {
        builder.addCase(authenticateStudy.pending, function (state, action) {
            state.status = 'loading';
        });
        builder.addCase(authenticateStudy.fulfilled, function (state, action) {
            var studyData = action.payload.studyData;
            if (studyData) {
                state.status = 'succeeded';
            }
            else {
                state.status = 'failed';
            }
            state.studyData = studyData;
        });
        builder.addCase(authenticateStudy.rejected, function (state, action) {
            state.status = 'failed';
        });
        builder.addCase(authActions.userSessionStillAuthenticatedFailure, function (state, action) {
            state.status = 'failed';
            state.studyData = undefined;
        });
    },
});
var rootSelector = function (state) { return state.study || {}; };
export var getStudySection = createSelector(rootSelector, function (_a) {
    var studyData = _a.studyData;
    return (studyData === null || studyData === void 0 ? void 0 : studyData.sections) || undefined;
});
// TODO: None of the below are typed and they should all be (I.e state should not be of type "any") look at selector above (RootState type)
export var studySelectors = {
    status: function (state) { return state.study.status; },
    studySystemVersion: function (state) { var _a, _b; return ((_b = (_a = state.study) === null || _a === void 0 ? void 0 : _a.studyData) === null || _b === void 0 ? void 0 : _b.systemVersion) || 'Unknown'; },
    studyCustomVersion: function (state) { var _a, _b; return ((_b = (_a = state.study) === null || _a === void 0 ? void 0 : _a.studyData) === null || _b === void 0 ? void 0 : _b.customVersion) || 'Unknown'; },
    studyData: function (state) { var _a; return (_a = state.study) === null || _a === void 0 ? void 0 : _a.studyData; },
    sessionId: function (state) { var _a; return (_a = state.study) === null || _a === void 0 ? void 0 : _a.sessionId; },
    sessionIsComplete: function (state) { var _a; return (_a = state.study) === null || _a === void 0 ? void 0 : _a.sessionIsComplete; },
    studyId: function (state) { var _a, _b; return (_b = (_a = state.study) === null || _a === void 0 ? void 0 : _a.studyData) === null || _b === void 0 ? void 0 : _b.studyId; },
    studyName: function (state) { var _a, _b; return (_b = (_a = state.study) === null || _a === void 0 ? void 0 : _a.studyData) === null || _b === void 0 ? void 0 : _b.studyName; },
    studyPlatform: function (state) { var _a, _b; return (_b = (_a = state.study) === null || _a === void 0 ? void 0 : _a.studyData) === null || _b === void 0 ? void 0 : _b.platform; },
    masterStyles: function (state) { var _a, _b; return (_b = (_a = state.study) === null || _a === void 0 ? void 0 : _a.studyData) === null || _b === void 0 ? void 0 : _b.masterStyling; },
    languages: function (state) { var _a, _b; return (_b = (_a = state.study) === null || _a === void 0 ? void 0 : _a.studyData) === null || _b === void 0 ? void 0 : _b.languages; },
    sections: getStudySection,
    requireLanguagePrompt: function (state) { var _a, _b; return (_b = (_a = state.study) === null || _a === void 0 ? void 0 : _a.studyData) === null || _b === void 0 ? void 0 : _b.requireLanguagePrompt; },
    currentSection: function (state) {
        var _a, _b, _c, _d, _e;
        // TaskStepper has not initialized
        if (!(state === null || state === void 0 ? void 0 : state.taskStepper) || !((_a = state === null || state === void 0 ? void 0 : state.taskStepper) === null || _a === void 0 ? void 0 : _a.currentTaskStepper)) {
            return {};
        }
        var currentTaskStepperGroup = (_c = (_b = state === null || state === void 0 ? void 0 : state.taskStepper) === null || _b === void 0 ? void 0 : _b.groups) === null || _c === void 0 ? void 0 : _c.currentGroup;
        return (_e = (_d = state === null || state === void 0 ? void 0 : state.study) === null || _d === void 0 ? void 0 : _d.studyData) === null || _e === void 0 ? void 0 : _e.sections.find(function (section) {
            var _a;
            return typeof section.slug === 'string'
                ? section.slug === currentTaskStepperGroup
                : section.slug[((_a = state === null || state === void 0 ? void 0 : state.lang) === null || _a === void 0 ? void 0 : _a.current) || 'EN'] ===
                    currentTaskStepperGroup;
        });
    },
    questionsInCurrentSection: function (state) {
        var _a, _b, _c, _d, _e;
        // TaskStepper has not initialized
        if (!(state === null || state === void 0 ? void 0 : state.taskStepper) || !((_a = state === null || state === void 0 ? void 0 : state.taskStepper) === null || _a === void 0 ? void 0 : _a.currentTaskStepper)) {
            return [];
        }
        var currentTaskStepperGroup = (_c = (_b = state === null || state === void 0 ? void 0 : state.taskStepper) === null || _b === void 0 ? void 0 : _b.groups) === null || _c === void 0 ? void 0 : _c.currentGroup;
        var currentSection = (_e = (_d = state === null || state === void 0 ? void 0 : state.study) === null || _d === void 0 ? void 0 : _d.studyData) === null || _e === void 0 ? void 0 : _e.sections.find(function (section) {
            var _a;
            return typeof section.slug === 'string'
                ? section.slug === currentTaskStepperGroup
                : section.slug[((_a = state === null || state === void 0 ? void 0 : state.lang) === null || _a === void 0 ? void 0 : _a.current) || 'EN'] ===
                    currentTaskStepperGroup;
        });
        // This only supports 1 question level deep. Will we need more than that?
        var questions = [];
        currentSection.pages.forEach(function (page) {
            page.pageData.rows.forEach(function (row) {
                // @ts-ignore
                row.cols.forEach(function (row) { return questions.push(row); });
            });
        });
        return questions;
    },
    currentPage: function (state) {
        var _a, _b, _c, _d, _e, _f;
        // TODO: This return type is incorrect
        // TaskStepper has not initialized
        if (!(state === null || state === void 0 ? void 0 : state.taskStepper) || !((_a = state === null || state === void 0 ? void 0 : state.taskStepper) === null || _a === void 0 ? void 0 : _a.currentTaskStepper)) {
            return {};
        }
        var currentTaskStepperGroup = (_c = (_b = state === null || state === void 0 ? void 0 : state.taskStepper) === null || _b === void 0 ? void 0 : _b.groups) === null || _c === void 0 ? void 0 : _c.currentGroup;
        var currentTaskStepperStepNumInGroup = (_d = state === null || state === void 0 ? void 0 : state.taskStepper) === null || _d === void 0 ? void 0 : _d.groups.currentStepInGroup;
        var currentSection = (_f = (_e = state === null || state === void 0 ? void 0 : state.study) === null || _e === void 0 ? void 0 : _e.studyData) === null || _f === void 0 ? void 0 : _f.sections.find(function (section) {
            var _a;
            return typeof section.slug === 'string'
                ? section.slug === currentTaskStepperGroup
                : section.slug[((_a = state === null || state === void 0 ? void 0 : state.lang) === null || _a === void 0 ? void 0 : _a.current) || 'EN'] ===
                    currentTaskStepperGroup;
        });
        return currentSection.pages[currentTaskStepperStepNumInGroup - 1];
    },
    questionsInCurrentPage: function (state) {
        var _a, _b, _c, _d, _e, _f;
        // TODO: So much is error prone below because state is not typed (Add :RootState to the state type here and you will see all the errors)
        // TaskStepper has not initialized
        if (!(state === null || state === void 0 ? void 0 : state.taskStepper) ||
            !((_a = state === null || state === void 0 ? void 0 : state.taskStepper) === null || _a === void 0 ? void 0 : _a.currentTaskStepper) ||
            !((_b = state === null || state === void 0 ? void 0 : state.taskStepper) === null || _b === void 0 ? void 0 : _b.currentStep)) {
            return [];
        }
        var currentStep = state.taskStepper.steps[state.taskStepper.currentStep - 1];
        var currentTaskStepperGroup = (_d = (_c = state === null || state === void 0 ? void 0 : state.taskStepper) === null || _c === void 0 ? void 0 : _c.groups) === null || _d === void 0 ? void 0 : _d.currentGroup;
        var currentSection = (_f = (_e = state === null || state === void 0 ? void 0 : state.study) === null || _e === void 0 ? void 0 : _e.studyData) === null || _f === void 0 ? void 0 : _f.sections.find(function (section) {
            var _a;
            return typeof section.slug === 'string'
                ? section.slug === currentTaskStepperGroup
                : section.slug[((_a = state === null || state === void 0 ? void 0 : state.lang) === null || _a === void 0 ? void 0 : _a.current) || 'EN'] ===
                    currentTaskStepperGroup;
        });
        var currentPage = currentSection.pages.find(function (page) { return page.id === currentStep.id; });
        var questions = [];
        currentPage.pageData.rows.forEach(function (row) {
            row.cols.forEach(function (col) {
                var _a, _b, _c;
                if (((_a = col === null || col === void 0 ? void 0 : col.content) === null || _a === void 0 ? void 0 : _a.type) === PAGE_DATA_TYPE.INPUT) {
                    (_c = (_b = col.content) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.forEach(function (question) {
                        questions.push(question);
                    });
                }
            });
        });
        return questions;
    },
    currentSectionProgressDefinition: function (state) {
        var _a, _b, _c, _d, _e;
        // TaskStepper has not initialized
        if (!(state === null || state === void 0 ? void 0 : state.taskStepper) || !((_a = state === null || state === void 0 ? void 0 : state.taskStepper) === null || _a === void 0 ? void 0 : _a.currentTaskStepper)) {
            return undefined;
        }
        var currentTaskStepperGroup = (_c = (_b = state === null || state === void 0 ? void 0 : state.taskStepper) === null || _b === void 0 ? void 0 : _b.groups) === null || _c === void 0 ? void 0 : _c.currentGroup; // TODO: Type??
        var currentStudySection = (_e = (_d = state === null || state === void 0 ? void 0 : state.study) === null || _d === void 0 ? void 0 : _d.studyData) === null || _e === void 0 ? void 0 : _e.sections.find(function (section) {
            var _a;
            return typeof section.slug === 'string'
                ? section.slug === currentTaskStepperGroup
                : section.slug[((_a = state === null || state === void 0 ? void 0 : state.lang) === null || _a === void 0 ? void 0 : _a.current) || 'EN'] ===
                    currentTaskStepperGroup;
        });
        return currentStudySection === null || currentStudySection === void 0 ? void 0 : currentStudySection.progressBarData;
    },
    studyFormSettings: function (state) {
        var _a, _b;
        return (_b = (_a = state.study) === null || _a === void 0 ? void 0 : _a.studyData) === null || _b === void 0 ? void 0 : _b.formModalSettings;
    },
    tableOfContentsData: function (state) {
        // return the same format of data for ToC as SaaS does.
        var _a, _b, _c, _d;
        var sections = (_b = (_a = state === null || state === void 0 ? void 0 : state.study) === null || _a === void 0 ? void 0 : _a.studyData) === null || _b === void 0 ? void 0 : _b.sections;
        var options = (_d = (_c = state === null || state === void 0 ? void 0 : state.study) === null || _c === void 0 ? void 0 : _c.studyData) === null || _d === void 0 ? void 0 : _d.tableOfContents;
        if (!options) {
            return undefined;
        }
        if (!sections) {
            return undefined;
        }
        var tocSections = sections.map(function (section) {
            var pages = section.pages.map(function (page) {
                return {
                    name: (page === null || page === void 0 ? void 0 : page.shortName) || page.name,
                    slug: page.slug,
                };
            });
            return {
                name: (section === null || section === void 0 ? void 0 : section.shortName) || section.name,
                slug: section.slug,
                pages: pages,
            };
        });
        return {
            data: {
                sections: tocSections,
            },
            options: options,
        };
    },
};
export var fetchStudyData = (_a = studySlice.actions, _a.fetchStudyData), fetchStudyDataSuccess = _a.fetchStudyDataSuccess, fetchStudyDataFailure = _a.fetchStudyDataFailure, setNewSessionId = _a.setNewSessionId, setSessionIsComplete = _a.setSessionIsComplete;
export default studySlice;
