var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useSelector } from "react-redux";
import { selectors as taskStepperSelectors } from 'dyn-design/dist/components/TaskStepper/slice';
/**
 * Returns an object of info about the current step.
 */
export default function useCurrentStep() {
    var steps = useSelector(taskStepperSelectors.steps);
    var currentStepNum = useSelector(taskStepperSelectors.currentStep);
    return __assign(__assign({}, steps[currentStepNum - 1]), { number: currentStepNum });
}
