import React from 'react';
import { Link } from 'react-router-dom';
import { useStyles } from './styles';
var Button = function (_a) {
    var href = _a.href, target = _a.target, className = _a.className, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.theme, theme = _c === void 0 ? 'solid' : _c, _d = _a.onClick, onClick = _d === void 0 ? function () { } : _d, children = _a.children;
    var styles = useStyles();
    var standardClassName = className
        ? className
        : !className && theme === 'solid'
            ? styles.standardContained
            : styles.standardOutlined;
    return (
    // If an href is given, use a <a>. Otherwise, use a <button>.
    href ? (React.createElement(Link
    // @ts-ignore
    , { 
        // @ts-ignore
        className: "button ".concat(styles.button, " ").concat(standardClassName, " ").concat(theme === 'solid' ? styles.solid : styles.outline), to: href || '#', target: target, onClick: onClick }, children)) : (React.createElement("button", { className: "button ".concat(styles.button, " ").concat(standardClassName, " ").concat(theme === 'solid' ? styles.solid : styles.outline), disabled: disabled, onClick: onClick }, children)));
};
export default Button;
