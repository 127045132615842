import { makeStyles } from '@material-ui/core';
export var useStyles = makeStyles(function (theme) { return ({
    studyPage: {
        paddingTop: 'var(--studyHeaderHeight)',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
    },
    noHeader: {
        paddingTop: 0,
    },
    studySteps: {
        width: '100%',
        height: '100%',
        // TODO: move to className after next dyn-design update
        '& .taskstepper': {
            height: '100%',
        },
        // TODO: remove after next dyn-design update
        '& .steps': {
            height: '100%',
        },
        '& .step': {
            height: '100%',
        },
    },
}); });
