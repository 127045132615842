import { useSelector } from 'react-redux';
import { langSelectors } from '../store/slices/lang';
import { studySelectors } from '../store/slices/study';
import { PAGE_DATA_TYPE } from 'dyn-design/dist/interfaces/StudyPageDataTypes';
import { selectors as taskStepperSelectors } from 'dyn-design/dist/components/TaskStepper/slice';
import { VARIABLE_OPTIONS } from 'dyn-design/dist/interfaces/StudyDataTypes';
/**
 * This will return a function that returns the correct progress label
 * ("Question x of n" or "Page x of n") for this section, depending on the PAD
 * configuration.
 *
 * Returns undefined if the progress bar is disabled for this section.
 */
var useQuestionProgressString = function () {
    var lang = useSelector(langSelectors.currentLang);
    var currentPageQuestions = useSelector(studySelectors.questionsInCurrentPage);
    var progressDefinition = useSelector(studySelectors.currentSectionProgressDefinition);
    var steps = useSelector(taskStepperSelectors.steps);
    var groups = useSelector(taskStepperSelectors.groups);
    var currentStepName = useSelector(taskStepperSelectors.currentStepName);
    if (!progressDefinition || !progressDefinition.includeLabel) {
        return undefined;
    }
    // We never want to count the Review Page when showing progress
    var currentSectionPagesWithoutReview = steps.filter(function (step) {
        return (step === null || step === void 0 ? void 0 : step.type) === PAGE_DATA_TYPE.INPUT && (step === null || step === void 0 ? void 0 : step.group) === groups.currentGroup;
    });
    var currentIndexInSectionPagesWithoutReview = currentSectionPagesWithoutReview.findIndex(function (step) {
        return step.name === currentStepName;
    });
    // We never want to count the Review Page when showing progress
    var currentSectionPagesWithQuestionsWithoutReview = steps.filter(function (step) {
        return (step === null || step === void 0 ? void 0 : step.group) === groups.currentGroup && (step === null || step === void 0 ? void 0 : step.hasQuestions);
    });
    var currentIndexInSectionPagesWithQuestionsWithoutReview = currentSectionPagesWithQuestionsWithoutReview.findIndex(function (step) {
        return step.name === currentStepName;
    });
    return function (inputData) {
        var _a;
        if (progressDefinition.type === 'InputProgress' || progressDefinition.type === 'PageProgress') {
            var substitutions = progressDefinition.variablesString.map(function (variable) {
                switch (variable) {
                    case VARIABLE_OPTIONS.PAGE_NUMBER:
                        return currentIndexInSectionPagesWithoutReview + 1;
                    case VARIABLE_OPTIONS.PAGE_TOTAL_COUNT:
                        return currentSectionPagesWithoutReview.length;
                    case VARIABLE_OPTIONS.INPUT_NUMBER:
                        var positionInCurrentPageQuestions = currentPageQuestions.findIndex(function (input) { var _a, _b; return ((_a = input === null || input === void 0 ? void 0 : input.question) === null || _a === void 0 ? void 0 : _a.id) === ((_b = inputData === null || inputData === void 0 ? void 0 : inputData.question) === null || _b === void 0 ? void 0 : _b.id); });
                        return positionInCurrentPageQuestions + 1;
                    case VARIABLE_OPTIONS.INPUT_TOTAL_COUNT:
                        return currentPageQuestions.length;
                }
            });
            // This was added because there was a bug with the JSON data, where
            // it could be missing translations of the Question Progress String.
            // This can be removed after PAD-414 is merged.
            var langToUseForProgressString = !((_a = progressDefinition === null || progressDefinition === void 0 ? void 0 : progressDefinition.labelText) === null || _a === void 0 ? void 0 : _a[lang]) ? 'EN' : lang;
            return progressDefinition
                .labelText[langToUseForProgressString]
                .replace('$1', substitutions[0].toString())
                .replace('$2', substitutions[1].toString());
        }
        else {
            console.warn('Invalid progress definition');
            return '';
        }
    };
};
export default useQuestionProgressString;
