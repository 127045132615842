var _a;
import { createSlice } from "@reduxjs/toolkit";
export var SAVE_STATUS;
(function (SAVE_STATUS) {
    SAVE_STATUS["idle"] = "idle";
    SAVE_STATUS["loading"] = "loading";
    SAVE_STATUS["succeeded"] = "succeeded";
    SAVE_STATUS["failed"] = "failed";
})(SAVE_STATUS || (SAVE_STATUS = {}));
/**
 * This state is used for managing the status of communication with the backend
 * server for now. `data` will be always the initial object until we have more
 * specific requirements about returing data from the backend.
 */
var dataSlice = createSlice({
    name: 'remoteData',
    initialState: {
        data: {},
        status: SAVE_STATUS.idle,
        error: null,
    },
    reducers: {
        saveRemoteData: function (state, action) {
            state.status = SAVE_STATUS.loading;
            state.error = null;
        },
        saveRemoteDataSuccess: function (state, action) {
            state.status = SAVE_STATUS.succeeded;
            state.error = null;
        },
        saveRemoteDataFailure: function (state, action) {
            state.status = SAVE_STATUS.failed;
            state.error = action.payload;
        }
    }
});
export var remoteDataSelectors = {
    resultOfLastSaveAttempt: function (state) { var _a; return (_a = state.remoteData) === null || _a === void 0 ? void 0 : _a.status; },
};
export var saveRemoteData = (_a = dataSlice.actions, _a.saveRemoteData), saveRemoteDataSuccess = _a.saveRemoteDataSuccess, saveRemoteDataFailure = _a.saveRemoteDataFailure;
export default dataSlice;
