import React from 'react';
import { useSelector } from 'react-redux';
import { authSelectors } from '../../store/slices/auth';
import { langSelectors } from '../../store/slices/lang';
import Loader from 'dyn-design/dist/components/Loader';
import DisplayText from 'dyn-design/dist/components/StepLayout/contentLayouts/DisplayText';
import { i18n } from '../../utils/i18n';
import i18nStrings from './i18n.json';
import { useStyles } from './styles';
/**
 * This is a page that shows a loading indicator or an error message.
 * It occupies the whole window to hide another page behind it which is in the process of loading.
 *
 * Loading indicator disppears if
 * - Authentication is passed and `appLoaded` is set to true by `Study` component
 *   when images are completely loaded (or failed) on the first page to be seen.
 * - Authentication is failed and an error is returned from SaaS
 *   (instead, an error message is displayed in this case)
 */
var LoadingStudy = function () {
    var styles = useStyles();
    var lang = useSelector(langSelectors.currentLang);
    var authError = useSelector(authSelectors.error);
    var customErrorMessage = useSelector(authSelectors.customErrorMessage);
    // Note: SaaS returns 403 when a magic link is invalid or expired.
    var errorMessage = function (errorStatus) {
        switch (errorStatus) {
            case 403:
                return i18n(i18nStrings['invalid-magic-link'], lang);
            default:
                return i18n(i18nStrings['unknown-error'], lang);
        }
    };
    return (React.createElement("div", { className: styles.loading },
        React.createElement("div", { className: styles.messageBox },
            !authError && (React.createElement("div", { className: styles.loader },
                React.createElement(Loader, null))),
            authError &&
                (customErrorMessage ? (React.createElement(DisplayText, { textContent: customErrorMessage, viewMode: 'desktop' }) // `viewMode` is doing nothing here but required.
                ) : (React.createElement("p", { className: styles.errorMessage }, errorMessage(authError)))))));
};
export default LoadingStudy;
