var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useMemo, useState } from 'react';
import { styled } from '@material-ui/core';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
var PREFIX = 'TableOfContents';
var styles = {
    position: "".concat(PREFIX, "-position"),
    hidden: "".concat(PREFIX, "-hidden"),
    floatingButton: "".concat(PREFIX, "-floatingButton"),
    container: "".concat(PREFIX, "-container"),
    icon: "".concat(PREFIX, "-icon"),
    header: "".concat(PREFIX, "-header"),
    headerTitle: "".concat(PREFIX, "-header-title"),
    headerButton: "".concat(PREFIX, "-header-button"),
    section: "".concat(PREFIX, "-section"),
    page: "".concat(PREFIX, "-page"),
    pageNum: "".concat(PREFIX, "-page-num"),
    pageClickable: "".concat(PREFIX, "-page-clickable"),
    pageSelected: "".concat(PREFIX, "-page-selected"),
    list: "".concat(PREFIX, "-list"),
    listSpace: "".concat(PREFIX, "-list-space"),
};
var TableOfContentsRoot = styled('div')(function (_a) {
    var _b, _c, _d, _e, _f;
    var _g;
    var theme = _a.theme;
    return (_b = {},
        _b["&.".concat(styles.position)] = {
            position: 'absolute',
            top: '16px',
            right: '18px',
            '@media screen and (max-width: 768px)': {
                top: '8px',
                right: '8px',
            },
            zIndex: '1000000000',
        },
        _b["& .".concat(styles.floatingButton)] = (_c = {
                width: '40px',
                height: '40px',
                padding: '8px',
                border: 0,
                borderRadius: '8px',
                boxShadow: '0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14)',
                backgroundColor: '#fafafa',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
            },
            _c["&.".concat(styles.hidden)] = {
                display: 'none',
            },
            _c),
        _b["& .".concat(styles.container)] = (_d = {
                display: 'flex',
                flexDirection: 'column',
                width: '335px',
                maxHeight: "calc(100vh - 202px)", // 170px: site header + header, appbar 32px: vertical paddings, this is the PAD2WEB's max height.
                borderRadius: '8px',
                boxShadow: '0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14)',
                backgroundColor: '#fafafa',
                fontFamily: ((_g = theme === null || theme === void 0 ? void 0 : theme.typography) === null || _g === void 0 ? void 0 : _g.fontFamily) || 'FilsonProRegular',
                paddingBottom: '10px',
                overflowY: 'hidden'
            },
            _d["&.".concat(styles.hidden)] = {
                display: 'none',
            },
            _d['@media screen and (max-width: 768px)'] = {
                width: 'calc(100vw - 16px)',
                maxHeight: '50vh',
            },
            _d),
        _b["& .".concat(styles.icon)] = {
            fontSize: '24px',
            color: 'rgba(0, 0, 0, 0.87)',
        },
        _b["& .".concat(styles.header)] = (_e = {
                padding: '16px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            },
            _e["& .".concat(styles.headerTitle)] = {
                fontSize: '20px',
                fontWeight: 'normal',
                lineHeight: 1.6,
                letterSpacing: '0.15px',
                color: 'rgba(0, 0, 0, 0.87)',
                margin: 0,
            },
            _e["& .".concat(styles.headerButton)] = {
                padding: 0,
                backgroundColor: 'transparent',
                border: 0,
                cursor: 'pointer',
            },
            _e),
        _b["& .".concat(styles.section)] = {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: 1.75,
            letterSpacing: '0.15px',
            color: '#000',
            padding: '0 16px',
            marginTop: '16px',
            '&:first-of-type': {
                marginTop: 0,
            },
        },
        _b["& .".concat(styles.page)] = (_f = {
                display: 'flex',
                fontSize: '16px',
                fontWeight: 'normal',
                lineHeight: 1.5,
                letterSpacing: '0.15px',
                color: 'rgba(41, 112, 224, 0.87)',
                padding: '4px 8px 4px 24px',
                cursor: 'pointer',
                textDecoration: 'none'
            },
            _f["&.".concat(styles.pageSelected)] = {
                backgroundColor: 'rgba(216, 216, 216, 0.3)',
                cursor: 'default',
            },
            _f),
        _b["& .".concat(styles.pageNum)] = {
            width: '30px',
        },
        _b["& .".concat(styles.list)] = {
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: '6px',
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#bebebe',
                borderRadius: '3px',
            },
        },
        _b);
});
var TOCListItemType;
(function (TOCListItemType) {
    TOCListItemType["SECTION"] = "SECTION";
    TOCListItemType["PAGE"] = "PAGE";
})(TOCListItemType || (TOCListItemType = {}));
var Page = function (_a) {
    var type = _a.type, name = _a.name, section = _a.section, page = _a.page, num = _a.num, selected = _a.selected, isLandingPage = _a.isLandingPage, lang = _a.lang, basePath = _a.basePath, hideLandingPagePath = _a.hideLandingPagePath, onSelect = _a.onSelect;
    var locale = lang.toUpperCase() === 'EN' ? '' : "/".concat(lang);
    var parentPath = basePath || '';
    var pagePath = isLandingPage && hideLandingPagePath ? '' : "/".concat(section, "/").concat(page);
    var url = "".concat(locale).concat(parentPath).concat(pagePath);
    var handleSelect = function (e) {
        // use callback function instead of href if onSelect is provided.
        if (onSelect) {
            e.preventDefault();
            onSelect({
                section: section,
                page: page,
                locale: locale,
            });
        }
    };
    return (React.createElement("a", { className: "".concat(styles.page, " ").concat(selected ? styles.pageSelected : ''), href: url, onClick: handleSelect },
        num && (React.createElement("span", { className: styles.pageNum },
            num.toString().padStart(2, '0'),
            ".\u00A0")),
        React.createElement("span", null, name)));
};
var Section = function (_a) {
    var type = _a.type, name = _a.name, section = _a.section;
    return React.createElement("div", { className: styles.section }, name);
};
var SESSION_STORAGE_TOC = 'toc_s_oc';
var TableOfContents = function (_a) {
    var data = _a.data, _b = _a.lang, locale = _b === void 0 ? 'EN' : _b, currentSection = _a.section, currentPage = _a.page, basePath = _a.basePath, customStyles = _a.style, _c = _a.hideLandingPagePath, hideLandingPagePath = _c === void 0 ? false : _c, onSelect = _a.onSelect;
    var _d = __read(useState(false), 2), open = _d[0], setOpen = _d[1];
    var getTitle = function (lang) {
        switch (lang) {
            case 'fr':
            case 'FR':
                return 'Table des matières';
            default:
                return 'Table of Contents';
        }
    };
    var linearData = useMemo(function () {
        var lang = locale.toUpperCase();
        var pageCountStartAt = 1;
        return data.data.sections
            .map(function (section, sectionIndex) {
            var pages = section.pages.map(function (page, pageIndex) {
                return __assign(__assign({ type: TOCListItemType.PAGE }, (data.options.showPageNumbers && {
                    num: pageCountStartAt + pageIndex,
                })), { name: page.name[lang], section: section.slug[lang], page: page.slug[lang], selected: currentSection === section.slug[lang] &&
                        currentPage === page.slug[lang], isLandingPage: sectionIndex === 0 && pageIndex === 0 });
            });
            if (!data.options.showSectionNames) {
                pageCountStartAt += pages.length;
            }
            return __spreadArray(__spreadArray([], __read((data.options.showSectionNames
                ? [
                    {
                        type: TOCListItemType.SECTION,
                        name: section.name[lang],
                        section: section.slug[lang],
                    },
                ]
                : [])), false), __read(pages), false);
        })
            .flat();
    }, [data, locale]);
    var handleFloatingButtonClick = function () {
        setOpen(true);
        saveStateIntoSessionStorage('true');
    };
    var handleCloseButtonClick = function () {
        setOpen(false);
        saveStateIntoSessionStorage('false');
    };
    var loadStateFromSessionStorage = function () {
        if (!sessionStorage) {
            return null;
        }
        return sessionStorage.getItem(SESSION_STORAGE_TOC);
    };
    var saveStateIntoSessionStorage = function (value) {
        if (sessionStorage) {
            sessionStorage.setItem(SESSION_STORAGE_TOC, value);
        }
    };
    useEffect(function () {
        var isMobile = window && window.innerWidth <= 768;
        // for mobile, always stay closed on page load
        if (isMobile) {
            setOpen(false);
        }
        // for desktop,
        else {
            var state = loadStateFromSessionStorage();
            // if first load in the current session, use the option `openByDefault`
            if (state === null) {
                // this option is for desktop only
                var initialState = !!data.options.openByDefault;
                setOpen(initialState);
                saveStateIntoSessionStorage("".concat(initialState));
            }
            else {
                // if user has interacted with TOC in the current session, use the value
                // from sessionStorage
                setOpen(state === 'true');
            }
        }
    }, []);
    return (React.createElement(TableOfContentsRoot, { className: styles.position, style: __assign({}, customStyles) },
        React.createElement("button", { className: "".concat(styles.floatingButton, " ").concat(open ? styles.hidden : ''), onClick: handleFloatingButtonClick },
            React.createElement(FormatListBulletedIcon, { className: styles.icon })),
        React.createElement("div", { className: "".concat(styles.container, " ").concat(!open ? styles.hidden : '') },
            React.createElement("div", { className: styles.header },
                React.createElement("p", { className: styles.headerTitle }, getTitle(locale)),
                React.createElement("button", { className: styles.headerButton, onClick: handleCloseButtonClick },
                    React.createElement("svg", { className: styles.icon, style: {
                            transform: 'rotate(90deg)',
                        }, focusable: 'false', "aria-hidden": 'true', viewBox: '0 0 24 24', "data-testid": 'ArrowBackIosNewIcon', width: 24, height: 24 },
                        React.createElement("path", { d: 'M17.77 3.77 16 2 6 12l10 10 1.77-1.77L9.54 12z' })))),
            React.createElement("div", { className: styles.list }, linearData &&
                linearData.length > 0 &&
                linearData.map(function (item) {
                    switch (item.type) {
                        case TOCListItemType.SECTION:
                            return (React.createElement(Section, __assign({}, item, { key: "toc-section-".concat(item.section) })));
                        case TOCListItemType.PAGE:
                            return (React.createElement(Page, __assign({}, item, { lang: locale, basePath: basePath, hideLandingPagePath: hideLandingPagePath, key: "toc-section-".concat(item.section, "-page-").concat(item.page), onSelect: onSelect })));
                    }
                })))));
};
export default TableOfContents;
