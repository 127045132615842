import { useSelector } from 'react-redux';
import { studySelectors } from '../store/slices/study';
import { PAGE_DATA_TYPE } from 'dyn-design/dist/interfaces/StudyPageDataTypes';
/**
 * Returns a function that checks if the given (or the current) page contains 1
 * or more sticky audio players. If true, it returns the audio player data from
 * the study data for the current viewport.
 */
var useGetStickyAudioPlayerDefinition = function () {
    var currentStudyPage = useSelector(studySelectors.currentPage);
    return function (pageData) {
        var _a, _b;
        var pageToAnalyze = pageData || currentStudyPage;
        var stickyAudioPlayer = null;
        (_b = (_a = pageToAnalyze === null || pageToAnalyze === void 0 ? void 0 : pageToAnalyze.pageData) === null || _a === void 0 ? void 0 : _a.rows) === null || _b === void 0 ? void 0 : _b.forEach(function (row) {
            var _a;
            (_a = row === null || row === void 0 ? void 0 : row.cols) === null || _a === void 0 ? void 0 : _a.forEach(function (col) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
                if (((_a = col === null || col === void 0 ? void 0 : col.content) === null || _a === void 0 ? void 0 : _a.type) === PAGE_DATA_TYPE.NARRATION
                    && (((_f = (_e = (_d = (_c = (_b = col === null || col === void 0 ? void 0 : col.content) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.styles) === null || _e === void 0 ? void 0 : _e.desktop) === null || _f === void 0 ? void 0 : _f.screenCorner) || ((_l = (_k = (_j = (_h = (_g = col === null || col === void 0 ? void 0 : col.content) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.styles) === null || _k === void 0 ? void 0 : _k.mobile) === null || _l === void 0 ? void 0 : _l.screenCorner))) {
                    stickyAudioPlayer = col;
                }
            });
        });
        return stickyAudioPlayer;
    };
};
export default useGetStickyAudioPlayerDefinition;
