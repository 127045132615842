var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { COMPONENT_TYPES, } from 'dyn-design/dist/interfaces/StudyPageDataTypes';
import { isRight } from 'dyn-design/dist/interfaces/Either';
import useDynalyticsData from 'dyn-design/dist/hooks/useDynalyticsData';
import Button from '../../Button';
import Toast from '../../Toast';
import ContactInfoModal from '../../ContactInfoModal';
import { langSelectors } from '../../../store/slices/lang';
import { studySelectors } from '../../../store/slices/study';
import { authSelectors } from '../../../store/slices/auth';
import { formSubmissionActions, formSubmissionSelectors, } from '../../../store/slices/formSubmission';
import submitForm from '../../../store/thunks/submitForm';
import { useCurrentFormAnswers } from '../../../hooks/useCurrentFormData';
import { useAppEngineDynalytics } from '../../../hooks/useAppEngineDynalytics';
import { useStyles } from '../styles';
import { i18n } from '../../../utils/i18n';
import i18nStrings from './i18n.json';
import generateTrackedButtonData from '../../../utils/generateTrackedButtonData';
import useCurrentStep from '../../../hooks/useCurrentStep';
import { getTestCodeFromCurrentUrl } from '../../../utils/magicLinks';
import { getLanguageValue } from '../../../utils/languageUtils';
var FormSubmissionButton = function (_a) {
    var _b;
    var pageForm = _a.pageForm, currentPageAnswers = _a.currentPageAnswers;
    var dispatch = useDispatch();
    var dynalytics = useAppEngineDynalytics();
    var styles = useStyles();
    var lang = useSelector(langSelectors.currentLang);
    var sessionId = useDynalyticsData().sessionId;
    var studyName = useSelector(studySelectors.studyName);
    var currentPage = useSelector(studySelectors.currentPage);
    var questionsInCurrentPage = useSelector(studySelectors.questionsInCurrentPage);
    var magicLink = useSelector(authSelectors.magicLink);
    var savedContactInfo = useSelector(formSubmissionSelectors.contactInfo);
    var formSubmissionError = useSelector(formSubmissionSelectors.error);
    var pendingFormSubmission = useSelector(formSubmissionSelectors.loading);
    var submittedSuccessfully = useSelector(formSubmissionSelectors.submitted);
    var currentStep = useCurrentStep();
    var toastType = submittedSuccessfully
        ? 'success'
        : formSubmissionError
            ? 'warning'
            : 'normal';
    var requiredFormQuestions = questionsInCurrentPage.filter(function (inputData) { var _a, _b; return ((_a = inputData.question) === null || _a === void 0 ? void 0 : _a.formId) && !((_b = inputData.question) === null || _b === void 0 ? void 0 : _b.optional); });
    /**
     * Check if all required form questions have the default value. If so, the SUBMIT button should be enabled.
     */
    var isAllRequiredFormQuestionsHavingDefaultValues = function () {
        return requiredFormQuestions.every(function (question) {
            var _a;
            return (question.componentData.component === COMPONENT_TYPES.RANGE ||
                question.componentData.component === COMPONENT_TYPES.SLIDER) &&
                ((_a = question.componentData.props) === null || _a === void 0 ? void 0 : _a.defaultInterval);
        });
    };
    var _c = useCurrentFormAnswers(pageForm.id, currentPageAnswers, requiredFormQuestions), findCurrentFormAnswers = _c.findCurrentFormAnswers, shouldDisableSubmitForm = _c.shouldDisableSubmitForm;
    var _d = __read(useState(false), 2), showSubmitFormModal = _d[0], setShowFormModal = _d[1];
    var handleFormSubmitClicked = function (e, newContactInfo) {
        dynalytics({
            factName: 'submit_form_button_click',
            data: __assign({ step: currentStep, formName: pageForm.name }, generateTrackedButtonData(e.target)),
        });
        var formAnswers = findCurrentFormAnswers();
        if (isRight(formAnswers)) {
            if (!savedContactInfo && !(newContactInfo === null || newContactInfo === void 0 ? void 0 : newContactInfo.email)) {
                setShowFormModal(true);
                return;
            }
            if ((newContactInfo === null || newContactInfo === void 0 ? void 0 : newContactInfo.email) &&
                (newContactInfo === null || newContactInfo === void 0 ? void 0 : newContactInfo.fullName) &&
                (newContactInfo === null || newContactInfo === void 0 ? void 0 : newContactInfo.agree)) {
                setShowFormModal(false);
                dispatch(formSubmissionActions.saveContactInfo(newContactInfo));
            }
            var contactInfo = savedContactInfo || newContactInfo;
            var formAnswersData = formAnswers.right;
            var dataToSend = {
                projectId: pageForm.projectId,
                pageId: pageForm.pageId,
                formId: pageForm.id,
                formName: pageForm.name,
                contactName: contactInfo.fullName,
                contactEmail: contactInfo.email,
                data: formAnswersData,
                magicLinkUrl: magicLink ? "?link=".concat(magicLink) : undefined,
                sessionId: sessionId ? sessionId : undefined,
                projectAppName: studyName,
                pageName: getLanguageValue(lang, currentPage.name),
                testCode: getTestCodeFromCurrentUrl() || undefined,
            };
            dynalytics({
                factName: 'submitted_form_validation_success',
                data: __assign({ step: currentStep, formName: pageForm.name }, generateTrackedButtonData(e.target)),
            });
            dispatch(submitForm(dataToSend));
        }
        else {
            dynalytics({
                factName: 'submitted_form_validation_fail',
                data: __assign({ step: currentStep, formName: pageForm.name }, generateTrackedButtonData(e.target)),
            });
            console.error(formAnswers.left);
        }
    };
    var handleSubmitFormModalClose = function () {
        setShowFormModal(false);
    };
    /**
     * Avoid the Submit button blinking between when the Submit is done and when
     * the inputs are reset by adding a condition for the disabled to the Button.
     * This is needed to make the button enabled again when the required answers
     * are filled.
     */
    useEffect(function () {
        if (!shouldDisableSubmitForm && submittedSuccessfully) {
            dispatch(formSubmissionActions.resetSubmitted());
        }
    }, [shouldDisableSubmitForm]);
    return (React.createElement(Grid, { container: true, justifyContent: 'center', alignItems: 'center', style: { paddingTop: '10px', paddingBottom: '4px' }, "data-num-buttons": 1, className: styles.formSubmitContainer },
        React.createElement(Button, { theme: 'solid', className: "".concat(styles.commonNavContained, " ").concat(styles.nextButton), onClick: handleFormSubmitClicked, disabled: shouldDisableSubmitForm ||
                pendingFormSubmission ||
                (submittedSuccessfully &&
                    !isAllRequiredFormQuestionsHavingDefaultValues()) },
            pendingFormSubmission && i18n(i18nStrings.formSubmitting, lang),
            !pendingFormSubmission && i18n(pageForm.metaData.buttonText, lang)),
        React.createElement(ContactInfoModal, { open: showSubmitFormModal, onSubmit: function (e, formData) {
                handleFormSubmitClicked(e, formData);
            }, onClose: handleSubmitFormModalClose }),
        React.createElement(Toast, { show: submittedSuccessfully || formSubmissionError, type: toastType, autoClose: true }, i18n((_b = i18nStrings.formSubmissionResult) === null || _b === void 0 ? void 0 : _b[toastType], lang))));
};
export default React.memo(FormSubmissionButton);
