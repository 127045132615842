var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Grid from '@material-ui/core/Grid';
import { selectors as taskStepperSelectors, actions as taskStepperActions, } from 'dyn-design/dist/components/TaskStepper/slice';
import { NAVIGATION_BUTTON_TYPE, } from 'dyn-design/dist/interfaces/StudyPageDataTypes';
import { isRight } from 'dyn-design/dist/interfaces/Either';
import { useValidateConditions } from 'dyn-design/dist/hooks/ConditionalHook/useValidateConditions';
import { isQaMode } from 'dyn-design/dist/utils/isQaMode';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css/bundle';
import Button from '../../Button';
import { URL_PARAMS } from '../../../constants/url';
import useSessionAnswers from '../../../hooks/useSessionAnswers';
import useSectionFinder from '../../../hooks/useSectionFinder';
import { useStyles } from '../styles';
import { studySelectors } from '../../../store/slices/study';
import { langSelectors } from '../../../store/slices/lang';
import { i18n } from '../../../utils/i18n';
import i18nStrings from '../i18n.json';
import { useAppNavigation } from '../../../hooks/useAppNavigation';
import { useAppEngineDynalytics } from '../../../hooks/useAppEngineDynalytics';
import generateTrackedButtonData from '../../../utils/generateTrackedButtonData';
import useCurrentStep from '../../../hooks/useCurrentStep';
var ButtonContainer = function (_a) {
    var children = _a.children;
    var styles = useStyles();
    return React.createElement("div", { className: styles.buttonContainer }, children);
};
var QuestionControls = function (_a) {
    var _b, _c;
    var currentPageAnswers = _a.currentPageAnswers, nextButtonDisabled = _a.nextButtonDisabled, onNextStepClick = _a.onNextStepClick, onPrevStepClick = _a.onPrevStepClick;
    var dispatch = useDispatch();
    var dynalytics = useAppEngineDynalytics();
    var styles = useStyles();
    var _d = useAppNavigation(), goToSection = _d.goToSection, goToUrl = _d.goToUrl;
    var currentStepNum = useSelector(taskStepperSelectors.currentStep);
    var steps = useSelector(taskStepperSelectors.steps);
    var currentStep = useCurrentStep();
    var studySections = useSelector(studySelectors.sections);
    var currentStudyPage = useSelector(studySelectors.currentPage);
    var groups = useSelector(taskStepperSelectors.groups);
    var pageNavigation = currentStudyPage.pageNavigation;
    var sessionAnswers = useSessionAnswers({ format: 'conditionals' }, currentPageAnswers);
    var userCameToEditFromReviewAnswerPage = window.location.search.includes("?" + URL_PARAMS.EDIT_ANSWER_THEN_RETURN_TO_REVIEW_PAGE);
    var lang = useSelector(langSelectors.currentLang);
    var currentStudySection = useSelector(studySelectors.currentSection);
    var _e = useValidateConditions((_b = currentStudySection === null || currentStudySection === void 0 ? void 0 : currentStudySection.conditionals) === null || _b === void 0 ? void 0 : _b.defaultCondition), runSectionValidation = _e.runSectionValidation, runningValidation = _e.runningValidation;
    var sectionFinder = useSectionFinder();
    /**
     * The default "previous" action is to go back 1 step.
     */
    var defaultOnPrevStepClick = function (e) {
        dynalytics({
            factName: 'study_step_prev',
            data: __assign({ step: currentStep }, generateTrackedButtonData(e.target)),
        });
        dispatch(taskStepperActions.goToStep(currentStepNum - 1));
    };
    /**
     * The default "next" action is to go forward 1 step.
     */
    var defaultOnNextStepClick = function (e) {
        dynalytics({
            factName: 'study_step_next',
            data: __assign({ step: currentStep }, generateTrackedButtonData(e.target)),
        });
        dispatch(taskStepperActions.goToStep(currentStepNum + 1));
    };
    /**
     * Creates a single button for the navigation.
     */
    var makeButton = function (text, className, theme, clickHandler, disabled) {
        if (theme === void 0) { theme = 'solid'; }
        if (disabled === void 0) { disabled = false; }
        return (React.createElement(Button, { className: className, theme: theme, onClick: clickHandler, disabled: disabled }, text));
    };
    /**
     * Creates the buttons for the "navigation" type nav. These go forward and backward.
     */
    var renderNavigationButtons = function (data) {
        var previousButtonClickHandler = onPrevStepClick || defaultOnPrevStepClick;
        var nextButtonClickHandler = onNextStepClick || defaultOnNextStepClick;
        var previousButton = data.previousButton &&
            makeButton(i18n(data.previousButton.text, lang), styles.prevButton, 'outline', previousButtonClickHandler);
        var nextButton = data.nextButton &&
            makeButton(i18n(data.nextButton.text, lang), "".concat(styles.commonNavContained, " ").concat(styles.nextButton), 'solid', nextButtonClickHandler, !!nextButtonDisabled);
        // Return buttons that exist only
        return {
            buttons: (React.createElement(ButtonContainer, null,
                previousButton,
                nextButton)),
            buttonCount: (previousButton ? 1 : 0) + (nextButton ? 1 : 0),
        };
    };
    /**
     * Creates buttons for when the user is editing their answer(s) on this page.
     */
    var renderReviewingButtons = function () {
        var completeButton = makeButton(i18n(i18nStrings['button-update-step'], lang), "".concat(styles.commonNavContained, " ").concat(styles.nextButton), 'solid', onNextStepClick, !!nextButtonDisabled);
        return {
            buttons: React.createElement(ButtonContainer, null, completeButton),
            buttonCount: 1,
        };
    };
    /**
     * Creates the buttons that are seen when the user is on the "end page".
     */
    var renderConditionalButtons = function (data) {
        var _a;
        var buttons = [];
        if ('previousButton' in data) {
            // @ts-ignore
            var previousButton = makeButton(i18n((_a = data.previousButton) === null || _a === void 0 ? void 0 : _a.text, lang), styles.prevButton, 'outline', onPrevStepClick || defaultOnPrevStepClick);
            buttons.push(previousButton);
        }
        var conditionalLogicButton = makeButton(i18n(data.text, lang), "".concat(styles.conditionalButton, " ").concat(styles.commonNavContained), 'solid', function () { return __awaiter(void 0, void 0, void 0, function () {
            var conditionalResult, nextStepDeterminedByCondition, nextStepDeterminedByCondition;
            var _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        // Since the End Page can have its own inputs, save their data in the
                        // session data before running the conditional logic.
                        // @TODO add support for multiple inputs on this page
                        if (Object.keys(currentPageAnswers).length) {
                            dispatch(taskStepperActions.completeStep({
                                name: Object.keys(currentPageAnswers)[0],
                                data: Object.values(currentPageAnswers)[0],
                                nextStep: false,
                            }));
                        }
                        if (!(currentStudySection === null || currentStudySection === void 0 ? void 0 : currentStudySection.conditionals)) return [3 /*break*/, 2];
                        if (isQaMode()) {
                            console.log('%c --- Evaluating Conditionals ---', 'color: #ff00dd');
                            console.log('%c Using these rules:', 'color: #53b3ff');
                            console.log(currentStudySection.conditionals.conditionalRules);
                            console.log('%c With these session answers:', 'color: #53b3ff');
                            console.log(sessionAnswers);
                        }
                        return [4 /*yield*/, runSectionValidation({
                                rules: currentStudySection.conditionals.conditionalRules,
                                // @ts-ignore
                                userData: sessionAnswers,
                            })];
                    case 1:
                        conditionalResult = _e.sent();
                        if (!isRight(conditionalResult)) {
                            console.error('Something went wrong when running conditional logic.');
                        }
                        else {
                            if ((_a = conditionalResult === null || conditionalResult === void 0 ? void 0 : conditionalResult.right) === null || _a === void 0 ? void 0 : _a.resultEvent) {
                                nextStepDeterminedByCondition = sectionFinder((_b = conditionalResult.right.resultEvent.params) === null || _b === void 0 ? void 0 : _b.value);
                                if (isQaMode()) {
                                    console.log('%c The next section is:', 'color: #34ff6a');
                                    console.log(studySections === null || studySections === void 0 ? void 0 : studySections[nextStepDeterminedByCondition]);
                                }
                                dispatch(taskStepperActions.goToStep(nextStepDeterminedByCondition));
                            }
                            else {
                                if ((_c = conditionalResult === null || conditionalResult === void 0 ? void 0 : conditionalResult.right) === null || _c === void 0 ? void 0 : _c.resultEvent) {
                                    nextStepDeterminedByCondition = sectionFinder((_d = conditionalResult.right.resultEvent.params) === null || _d === void 0 ? void 0 : _d.value);
                                    dispatch(taskStepperActions.goToStep(nextStepDeterminedByCondition));
                                }
                                else {
                                    console.error('Something went wrong when reading the result of the conditional logic.');
                                }
                            }
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        if (isQaMode()) {
                            console.log('%c This section does not have conditionals to evaluate', 'color: #e9ff34');
                        }
                        _e.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); }, !!nextButtonDisabled);
        buttons.push(conditionalLogicButton);
        return {
            buttons: buttons,
            buttonCount: buttons.length,
        };
    };
    /**
     *  Handle a click on fixed suggestion button.
     */
    var handleFixedSuggestionClick = function (type, linkId, e) {
        var _a;
        if (!linkId) {
            return;
        }
        var effectiveLinkId = type === 'URL' || type === 'URL_NEW_TAB'
            ? (linkId === null || linkId === void 0 ? void 0 : linkId[lang]) || (linkId === null || linkId === void 0 ? void 0 : linkId['EN']) || linkId
            : linkId;
        if (type === 'Fixed') {
            // Find the step number matching to the linkId
            var sectionId_1 = (_a = studySections === null || studySections === void 0 ? void 0 : studySections.find(function (section) { return section.id === linkId; })) === null || _a === void 0 ? void 0 : _a.id;
            if (sectionId_1) {
                var nextStepDeterminedByLinkId = __spreadArray([], __read(steps), false).findIndex(function (step) { return step.groupId === sectionId_1; });
                if (nextStepDeterminedByLinkId !== -1) {
                    // TODO: Before this was >= 1 I'm not sure why?
                    // Save the answers before moving to another question.
                    Object.keys(currentPageAnswers).forEach(function (questionId) {
                        dispatch(taskStepperActions.completeStep({
                            name: questionId,
                            data: currentPageAnswers[questionId],
                            nextStep: false,
                        }));
                    });
                    goToSection(sectionId_1);
                }
            }
        }
        else {
            // TODO: I don't know how the below is meant to be handled when leaving the App.
            Object.keys(currentPageAnswers).forEach(function (questionId) {
                dispatch(taskStepperActions.completeStep({
                    name: questionId,
                    data: currentPageAnswers[questionId],
                    nextStep: false,
                }));
            });
            goToUrl(effectiveLinkId, type === 'URL_NEW_TAB' ? true : false);
        }
        dynalytics({
            factName: 'suggestion_button_click',
            data: __assign(__assign({}, generateTrackedButtonData(e.target)), { step: currentStep, type: type }),
        });
    };
    /**
     * Creates suggestion buttons.
     */
    var renderSuggestionsButtons = function (data) {
        // How many buttons should be in a slide at maximum.
        var MAX_COUNT_IN_GROUP = 4;
        // We handle `Fixed` | 'URL' | 'URL_NEW_TAB' actionType only for now.
        var suggestions = data.suggestions.filter(function (suggestion) {
            return suggestion.actionType === 'Fixed' ||
                suggestion.actionType === 'URL' ||
                suggestion.actionType === 'URL_NEW_TAB';
        });
        // Group suggestions by `MAX_COUNT_IN_GROUP`
        var groupedSuggestions = [];
        for (var i = 0; i < suggestions.length; i += MAX_COUNT_IN_GROUP) {
            groupedSuggestions.push(suggestions.slice(i, i + MAX_COUNT_IN_GROUP));
        }
        // No need to use a carousel if there is only 1 suggestion button
        return {
            buttons: groupedSuggestions.map(function (group, index) { return (React.createElement(Grid, { item: true, xs: 12, key: "suggestion-group-".concat(index) },
                React.createElement(Swiper, { modules: [Pagination], slidesPerView: 1, grabCursor: true, pagination: { clickable: true }, spaceBetween: 25, className: "swiper ".concat(styles.swiper) },
                    React.createElement(SwiperSlide, { className: 'swiper-card' },
                        React.createElement("div", { className: styles.suggestionButtonGroup }, group.map(function (suggestion, index) {
                            var _a;
                            return (React.createElement(React.Fragment, { key: "swiper-question-button-".concat(index) }, makeButton(i18n((_a = suggestion.fixedAction) === null || _a === void 0 ? void 0 : _a.text, lang) || '', styles.suggestionButton, 'solid', function (e) {
                                var _a;
                                return handleFixedSuggestionClick(suggestion.actionType, // TODO: This should be typed in the DS (Something to improve)
                                (_a = suggestion.fixedAction) === null || _a === void 0 ? void 0 : _a.linkId, e);
                            }, !!nextButtonDisabled)));
                        })))))); }),
            buttonCount: groupedSuggestions.length,
        };
    };
    /**
     * Creates buttons that allow the user to complete the study.
     */
    var renderCompletionButtons = function (data) {
        var _a;
        var handleClick = function () {
            // Since the End Page can have its own inputs, save their data in the
            // session data before ending the study.
            // @TODO add support for multiple inputs on this page
            if (Object.keys(currentPageAnswers).length) {
                dispatch(taskStepperActions.completeStep({
                    name: Object.keys(currentPageAnswers)[0],
                    data: Object.values(currentPageAnswers)[0],
                    nextStep: false,
                }));
            }
            // The session is now completed
            dispatch(taskStepperActions.complete({}));
        };
        var completeButton = makeButton((_a = data === null || data === void 0 ? void 0 : data.text) === null || _a === void 0 ? void 0 : _a[lang], "".concat(styles.commonNavContained, " ").concat(styles.nextButton), 'solid', handleClick, !!nextButtonDisabled);
        return {
            buttons: React.createElement(ButtonContainer, null, completeButton),
            buttonCount: 1,
        };
    };
    /**
     * App Button EndPage Navigation Buttons
     */
    var renderAppButtonTypeEndPageNav = function (data) {
        var prevBtn = data.previousButton ? renderNavigationButtons(data) : null;
        // TODO: Add previous section btn
        return prevBtn;
    };
    /**
     * Create the appropiate buttons for the navigation type that the current page
     * uses.
     */
    var renderButtons = function (pageNavigation) {
        var _a;
        if (userCameToEditFromReviewAnswerPage) {
            return renderReviewingButtons();
        }
        else {
            var renderedButtons_1 = null;
            switch (pageNavigation === null || pageNavigation === void 0 ? void 0 : pageNavigation.type) {
                case NAVIGATION_BUTTON_TYPE.NAVIGATION:
                    renderedButtons_1 = renderNavigationButtons(pageNavigation.data);
                    break;
                case NAVIGATION_BUTTON_TYPE.CONDITIONAL:
                    renderedButtons_1 = renderConditionalButtons(pageNavigation.data);
                    break;
                case NAVIGATION_BUTTON_TYPE.SUGGESTIONS:
                    var renderedSuggestionsButtons = renderSuggestionsButtons(pageNavigation.data);
                    var renderedAppButtonTypeEndPageNav = renderAppButtonTypeEndPageNav(pageNavigation.data);
                    renderedButtons_1 = {
                        buttons: [
                            renderedSuggestionsButtons.buttons,
                            renderedAppButtonTypeEndPageNav === null || renderedAppButtonTypeEndPageNav === void 0 ? void 0 : renderedAppButtonTypeEndPageNav.buttons,
                        ],
                        buttonCount: renderedSuggestionsButtons.buttonCount +
                            ((_a = renderedAppButtonTypeEndPageNav === null || renderedAppButtonTypeEndPageNav === void 0 ? void 0 : renderedAppButtonTypeEndPageNav.buttonCount) !== null && _a !== void 0 ? _a : 0),
                    };
                    break;
                case NAVIGATION_BUTTON_TYPE.COMPLETION:
                    renderedButtons_1 = renderCompletionButtons(pageNavigation.data);
                    break;
                case NAVIGATION_BUTTON_TYPE.APP_BTN_END_PAGE:
                    renderedButtons_1 = renderNavigationButtons(pageNavigation.data); // TODO: This needs to include Previous Section Butto
                    break;
            }
            return renderedButtons_1;
        }
    };
    var renderPreviousSectionButton = function (pageNavigation) {
        var _a;
        // @ts-ignore
        var previousSectionBtn = (pageNavigation === null || pageNavigation === void 0 ? void 0 : pageNavigation.previousSectionBtn) || ((_a = pageNavigation === null || pageNavigation === void 0 ? void 0 : pageNavigation.data) === null || _a === void 0 ? void 0 : _a.previousSectionBtn);
        if (!previousSectionBtn) {
            return null;
        }
        var handlePreviousSectionButtonClick = function (e) {
            dynalytics({
                factName: 'study_section_prev',
                data: __assign({ step: currentStep }, generateTrackedButtonData(e.target)),
            });
            dispatch(taskStepperActions.goToStep(currentStepNum - groups.currentStepInGroup));
        };
        var button = makeButton(i18n(previousSectionBtn.text, lang), "".concat(styles.commonNavContained, " ").concat(styles.previousSectionButton), 'solid', handlePreviousSectionButtonClick);
        return button;
    };
    var renderedButtons = renderButtons(pageNavigation);
    var previousSectionButton = renderPreviousSectionButton(pageNavigation);
    return (React.createElement(Grid, { container: true, justifyContent: 'center', alignItems: 'center', className: "".concat(styles.stepNav, " ").concat(styles.stepNavSpacing), "data-num-buttons": (_c = renderedButtons === null || renderedButtons === void 0 ? void 0 : renderedButtons.buttonCount) !== null && _c !== void 0 ? _c : 0 }, renderedButtons === null || renderedButtons === void 0 ? void 0 :
        renderedButtons.buttons,
        previousSectionButton));
};
export default QuestionControls;
