var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import { dynamicButtonAction } from 'dyn-design/dist/components/StepLayout/utils/showDynamicContent';
import StepLayout from 'dyn-design/dist/components/StepLayout/StepLayout';
import { selectors as taskStepperSelectors } from 'dyn-design/dist/components/TaskStepper/slice';
import DynamicContentProvider from 'dyn-design/dist/components/StepLayout/ReusableComponents/DynamicContentProvider';
import { useSelector, useDispatch } from 'react-redux';
import QuestionReview from '../QuestionReview';
import useSessionAnswers from '../../hooks/useSessionAnswers';
import useIsReviewPage from '../../hooks/useIsReviewPage';
import { studySelectors } from '../../store/slices/study';
import { langSelectors } from '../../store/slices/lang';
import { mediaPlayerSelectors, mediaPlayerActions, } from 'dyn-design/dist/components/MediaPlayer/slice';
import { useStyles } from './styles';
import { Viewports } from '../../constants/viewport';
import useQuestionProgressString from '../../hooks/useQuestionProgressString';
import { useMagicLinkLogo } from '../../hooks/useMagicLinkData';
import useCurrentViewport from '../../hooks/useCurrentViewport';
import useCurrentStep from '../../hooks/useCurrentStep';
import { useAppNavigation } from '../../hooks/useAppNavigation';
import { useAppEngineDynalytics } from '../../hooks/useAppEngineDynalytics';
import { appActions, appSelectors } from '../../store/slices/app';
import { usePdfDownload } from '../../hooks/usePdfDownload';
import generateTrackedButtonData from '../../utils/generateTrackedButtonData';
import { authSelectors } from '../../store/slices/auth';
import { toastActions } from '../../store/slices/toast';
import i18nStringsToast from '../GlobalToast/i18n.json';
import { i18n } from '../../utils/i18n';
import { getLanguageValue } from '../../utils/languageUtils';
var Question = function (_a) {
    var onAnswerChange = _a.onAnswerChange, resetQuestionIds = _a.resetQuestionIds, onStepMediaEnded = _a.onStepMediaEnded;
    var dispatch = useDispatch();
    var dynalytics = useAppEngineDynalytics();
    var styles = useStyles();
    var _b = useAppNavigation(), goToSection = _b.goToSection, goToUrl = _b.goToUrl;
    var authData = useSelector(authSelectors.authData);
    var currentViewport = useCurrentViewport();
    var magicLinkLogo = useMagicLinkLogo();
    var questionProgressString = useQuestionProgressString();
    var sessionAnswers = useSessionAnswers({ format: 'raw' });
    var currentStudyPage = useSelector(studySelectors.currentPage);
    var currentSection = useSelector(studySelectors.currentSection);
    var currentStudySection = useSelector(studySelectors.currentSection);
    var currentStep = useCurrentStep();
    var groups = useSelector(taskStepperSelectors.groups); // SAME HERE!!
    var lang = useSelector(langSelectors.currentLang);
    var isReviewPage = useIsReviewPage();
    var audioVolume = useSelector(mediaPlayerSelectors.audioVolume);
    var audioMute = useSelector(mediaPlayerSelectors.audioMuted);
    var appLoaded = useSelector(appSelectors.loaded);
    var _c = usePdfDownload(), downloadPDF = _c.downloadPDF, pdfDownloadError = _c.pdfDownloadError;
    var generatePDF = function () {
        dispatch(toastActions.showToast({
            type: 'success',
            content: i18n(i18nStringsToast.pdfDownload.generating, lang),
        }));
        downloadPDF(window.location.href, getLanguageValue(lang, currentStudyPage.name));
    };
    useEffect(function () {
        if (pdfDownloadError) {
            dispatch(toastActions.showToast({
                type: 'error',
                content: i18n(i18nStringsToast.pdfDownload.error, lang),
            }));
        }
    }, [pdfDownloadError]);
    var handleAppStyleButtonClick = function (appButton, e) {
        var _a, _b, _c;
        var type = appButton.type;
        var btnData = (appButton.type === 'CustomBtn'
            ? { type: 'CellBtn', componentData: appButton.data }
            : { type: 'AppStyleBtn', componentData: appButton });
        var dynamicAction = dynamicButtonAction(btnData, authData);
        if (dynamicAction.type === 'hide') {
            // This button should not be displayed if this is returned
            return;
        }
        var action = dynamicAction.action || appButton.data.action;
        // MUI in the design system has an inner <span> for the event target, but we need the button
        var nativeTarget = (_a = e.nativeEvent) === null || _a === void 0 ? void 0 : _a.target;
        var appStyleButtonElement = nativeTarget === null || nativeTarget === void 0 ? void 0 : nativeTarget.closest('button');
        var dynalyticsData = __assign({ action: action, step: currentStep }, generateTrackedButtonData(appStyleButtonElement));
        if (type === 'CustomBtn') {
            // Page Button: CustomBtn
            dynalytics({ factName: 'page_button_click', data: dynalyticsData });
        }
        else {
            // App Style Button: Text_App_Button, Media_AppButton
            dynalytics({ factName: 'app_style_button_click', data: dynalyticsData });
        }
        switch (action.type) {
            case 'GoTo':
                // Do nothing if we are already somewhere in the desired section
                if (action.value === currentSection.id) {
                    return;
                }
                goToSection(action.value);
                break;
            case 'URL_NEW_TAB':
            case 'URL':
                if (!action.value) {
                    return;
                }
                else {
                    goToUrl(((_b = action.value) === null || _b === void 0 ? void 0 : _b[lang]) || ((_c = action.value) === null || _c === void 0 ? void 0 : _c['EN']) || action.value, action.type === 'URL_NEW_TAB' ? true : false);
                }
                break;
            case 'PDF':
                generatePDF();
                break;
            default:
                console.error('Invalid action');
        }
    };
    var handleAudioVolumeChange = function (volume) {
        dispatch(mediaPlayerActions.setAudioVolume(volume));
    };
    var handleAudioMuteChange = function (mute) {
        dispatch(mediaPlayerActions.setAudioMuted(mute));
    };
    var handleShowNarration = function (showNarration) {
        dispatch(mediaPlayerActions.setNarrationOpen(showNarration));
    };
    var handleStepMediaLoaded = function (status) {
        if (!appLoaded && status.allComplete) {
            dispatch(appActions.setLoaded(true));
        }
        // the content height should be calculated when all media is loaded
        if (status.allComplete) {
            pushStickyAudioPlayerWhenPageReachesBottom();
        }
    };
    /**
     * Track all clicks of external links.
     */
    useEffect(function () {
        var trackExternalClick = function (e) {
            var _a, _b, _c;
            if (((_a = e.target) === null || _a === void 0 ? void 0 : _a.matches('a')) &&
                ((_b = e.target) === null || _b === void 0 ? void 0 : _b.getAttribute('target')) === '_blank') {
                var href = ((_c = e.target) === null || _c === void 0 ? void 0 : _c.getAttribute('href')) || '(Not set in DOM)';
                var currentPageUrl = window.location.href;
                var step = currentStep;
                dynalytics({
                    factName: 'external_anchor_click',
                    data: __assign({ step: step, href: href, currentPageUrl: currentPageUrl, pageId: currentStudyPage === null || currentStudyPage === void 0 ? void 0 : currentStudyPage.id, sectionId: currentStudySection === null || currentStudySection === void 0 ? void 0 : currentStudySection.id }, generateTrackedButtonData(e.target)),
                });
            }
        };
        document.addEventListener('click', trackExternalClick);
        return function () { return document.removeEventListener('click', trackExternalClick); };
    }, []);
    var getNavigationHeight = function () {
        var pageNavigation = currentStudyPage.pageNavigation;
        // where the page does not have pageNavigation.
        // i.e., http://localhost:3100/project/synuclein-models-overview/injection-sites
        if (!pageNavigation) {
            return 0;
        }
        var navElem = document.querySelector('#study-step-nav');
        if (!navElem) {
            return 0;
        }
        return navElem.clientHeight;
    };
    var pushStickyAudioPlayerWhenPageReachesBottom = function () {
        var _a, _b;
        var scrollableElement = document.querySelector('html');
        if (!scrollableElement || currentViewport !== Viewports.Mobile) {
            return;
        }
        var contentElem = document.querySelector('#question');
        var contentHeight = contentElem === null || contentElem === void 0 ? void 0 : contentElem.clientHeight;
        var stepBodyHeight = (_a = contentElem === null || contentElem === void 0 ? void 0 : contentElem.parentElement) === null || _a === void 0 ? void 0 : _a.clientHeight;
        // Note: `window.innerHeight` is needed to get the consistent value between
        // different devices.
        var remainingPixelsUntilBottom = Math.max(0, scrollableElement.scrollHeight -
            window.innerHeight -
            scrollableElement.scrollTop +
            25);
        var stickyPlayer = (_b = document.querySelector('.audio-player-mini.is-sticky')) === null || _b === void 0 ? void 0 : _b.firstElementChild;
        var navigationHeight = getNavigationHeight();
        // Note: if the content does not fill enough space, the content and the
        // navigation area does not stick to each other and there will be some space
        // between them. In this case, the bottom value requires an extra value as
        // much as the gap. We might need to handle more cases.
        var distanceFromNavigation = stepBodyHeight &&
            contentHeight &&
            contentHeight + navigationHeight < stepBodyHeight
            ? (stepBodyHeight - navigationHeight - contentHeight) / 2
            : 0;
        // Note: `position: fixed` with dynamic bottom values makes the player
        // shake. That is why `position: absolute` is used. Also please note that
        // `bottom: 0px` is not actual the bottom of screen so negative value is
        // used.
        if (remainingPixelsUntilBottom <= navigationHeight - 105 + 8) {
            // 105: padding-top, 8: to prevent the player from frame skipping
            stickyPlayer === null || stickyPlayer === void 0 ? void 0 : stickyPlayer.setAttribute('style', "bottom: ".concat(-distanceFromNavigation -
                105, "px !important; position: absolute !important;"));
        }
        else {
            stickyPlayer === null || stickyPlayer === void 0 ? void 0 : stickyPlayer.removeAttribute('style');
        }
    };
    /**
     * Push the sticky audio player up a bit when the user reaches the bottom of
     * the page so that it doesn't overlap the buttons.
     */
    useEffect(function () {
        window.addEventListener('scroll', pushStickyAudioPlayerWhenPageReachesBottom);
        return function () {
            return window.removeEventListener('scroll', pushStickyAudioPlayerWhenPageReachesBottom);
        };
    }, [currentViewport]);
    useEffect(function () {
        pushStickyAudioPlayerWhenPageReachesBottom();
    }, [currentStep]);
    return isReviewPage() ? (React.createElement(QuestionReview, { group: groups.currentGroup })) : (
    // @todo: Dropdown and the second-depth checkboxes don't seem to display the
    // saved answers. The other inputs need to be tested.
    React.createElement("div", { id: 'question', className: styles.question },
        React.createElement(DynamicContentProvider, { authData: { authData: authData } },
            React.createElement(StepLayout, { lang: lang, onInputValueChange: function (answer) { return onAnswerChange(answer); }, onAppStyleButtonClick: handleAppStyleButtonClick, questionProgressString: questionProgressString, answerValues: sessionAnswers, resetQuestionIds: resetQuestionIds, layout: currentStudyPage.pageData, magicLinkLogo: magicLinkLogo, audioVolume: audioVolume, audioMute: audioMute, onAudioVolumeChange: handleAudioVolumeChange, onAudioMuteChange: handleAudioMuteChange, onShowNarrationChange: handleShowNarration, onStepMediaLoaded: handleStepMediaLoaded, onMediaEnded: onStepMediaEnded, dynalyticsData: {
                    pageId: currentStudyPage.id,
                    sectionId: currentSection.id,
                } }))));
};
export default Question;
