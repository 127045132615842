import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ROUTES from './routes';
var Router = function () {
    return (React.createElement(Switch, null,
        React.createElement(Route, { path: ROUTES.HOME.path, exact: true, component: ROUTES.HOME.component, element: true }),
        React.createElement(Route, { path: ROUTES.STUDY_PAGE.path, component: ROUTES.STUDY_PAGE.component }),
        React.createElement(Route, { path: ROUTES.STUDY.path, component: ROUTES.PAGE_NOT_FOUND.component }),
        React.createElement(Route, { path: ROUTES.STUDY_END.path, exact: true, component: ROUTES.STUDY_END.component }),
        React.createElement(Route, { path: ROUTES.MAINTENANCE.path, exact: true, component: ROUTES.MAINTENANCE.component }),
        React.createElement(Route, { component: ROUTES.PAGE_NOT_FOUND.component })));
};
export default Router;
