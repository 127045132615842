var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useCustomThemeAdditions } from 'dyn-design/dist/components/StepLayout/hooks/useCustomThemeAdditions';
import { useViewMode } from 'dyn-design/dist/components/StepLayout/hooks/useViewMode';
import StudyStepHeader from '../StudyStepHeader';
import StudyStepBody from '../StudyStepBody';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { studySelectors } from '../../store/slices/study';
import TableOfContents from '../TableOfContents';
import { langSelectors } from '../../store/slices/lang';
import { selectors as taskStepperSelectors, actions as taskStepperActions, } from 'dyn-design/dist/components/TaskStepper/slice';
/**
 * Each StudyStep is a single page from PAD.
 */
var StudyStep = function (_a) {
    var _b, _c, _d, _e;
    var showHeader = _a.showHeader;
    var masterStylesDefaultSettings = (_b = useCustomThemeAdditions()) === null || _b === void 0 ? void 0 : _b.pageComponents;
    var viewMode = useViewMode();
    var _f = ((_c = masterStylesDefaultSettings === null || masterStylesDefaultSettings === void 0 ? void 0 : masterStylesDefaultSettings.page) === null || _c === void 0 ? void 0 : _c[viewMode]) || {}, minWidth = _f.minWidth, customStyles = __rest(_f, ["minWidth"]);
    var styles = useStyles({ customStyles: customStyles });
    var lang = useSelector(langSelectors.currentLang);
    var tableOfContentsData = useSelector(studySelectors.tableOfContentsData);
    var currentSection = useSelector(studySelectors.currentSection);
    var currentPage = useSelector(studySelectors.currentPage);
    var steps = useSelector(taskStepperSelectors.steps);
    var dispatch = useDispatch();
    /**
     * Navigate to the page which is clicked in ToC, but it does with TaskStepper
     * instead of using the default behaviour of anchor element (href). This is to
     * prevent page blinking from reloading the app by url.
     */
    var handleToCItemSelected = function (data) {
        var destIndex = steps.findIndex(function (step) { return step.group === data.section && step.name === data.page; });
        if (destIndex != -1) {
            dispatch(taskStepperActions.goToStep(destIndex + 1));
        }
    };
    return (React.createElement("div", { className: styles.step },
        showHeader && React.createElement(StudyStepHeader, null),
        React.createElement(StudyStepBody, null),
        tableOfContentsData && (React.createElement(TableOfContents, { data: tableOfContentsData, lang: lang, section: (_d = currentSection === null || currentSection === void 0 ? void 0 : currentSection.slug) === null || _d === void 0 ? void 0 : _d[lang], page: (_e = currentPage === null || currentPage === void 0 ? void 0 : currentPage.slug) === null || _e === void 0 ? void 0 : _e[lang], basePath: '/project', style: {
                position: 'fixed',
                top: "".concat(showHeader ? 70 + 16 : 16, "px"), // 70: Header
            }, onSelect: handleToCItemSelected }))));
};
export default StudyStep;
