import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../components/Button';
import { langSelectors } from '../../store/slices/lang';
import { setSessionIsComplete } from '../../store/slices/study';
import { ENVS } from '../../constants/env';
import { DISCRIMINATION_URL } from '../../constants/url';
import useCurrentEnv from '../../hooks/useCurrrentEnv';
import { i18n } from '../../utils/i18n';
import i18nStrings from './i18n.json';
import { useStyles } from './styles';
var Home = function () {
    var dispatch = useDispatch();
    var styles = useStyles();
    var lang = useSelector(langSelectors.currentLang);
    var currentEnv = useCurrentEnv();
    var goToDiscrimination = function () {
        var redirect = '';
        switch (currentEnv) {
            case ENVS.DEV:
                redirect = DISCRIMINATION_URL.DEV;
                break;
            case ENVS.STAGING:
                redirect = DISCRIMINATION_URL.STAGING;
                break;
            case ENVS.PROD:
                redirect = DISCRIMINATION_URL.PROD;
                break;
        }
        window.location.href = "".concat(redirect, "/").concat(lang.toLowerCase());
    };
    // Reset the session ID when this page is initialized so that if the user
    // decides to do the study again without closing the current tab first, the
    // old data won't be overwritten because of a stale session ID.
    useEffect(function () {
        dispatch(setSessionIsComplete(true));
    }, []);
    return (React.createElement("div", { className: styles.end },
        React.createElement("div", { className: styles.intro },
            React.createElement("div", { className: styles.titleBox },
                React.createElement("h1", { className: styles.title, dangerouslySetInnerHTML: {
                        __html: i18n(i18nStrings['welcome'], lang),
                    } })),
            React.createElement("div", { className: styles.summary, dangerouslySetInnerHTML: {
                    __html: i18n(i18nStrings['summary'], lang),
                } }),
            React.createElement("div", { className: styles.controls },
                React.createElement(Button, { onClick: goToDiscrimination, className: styles.homeBtn }, i18n(i18nStrings['btn-end-study'], lang))))));
};
export default Home;
