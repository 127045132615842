var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeStyles } from '@material-ui/core/styles';
import { ViewportMediaQueries } from '../../constants/viewport';
// @ts-ignore
export var useStyles = makeStyles(function (theme) {
    var _a, _b, _c, _d, _e;
    var _f, _g, _h;
    return ({
        end: (_a = {
                width: '100%',
                height: '100%',
                backgroundImage: 'url(/images/blob-1-right.svg)',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '20px -110px',
                backgroundColor: theme.palette.primary.main
            },
            _a[ViewportMediaQueries.TabletAndAbove] = {
                backgroundPosition: 'calc(100% + 110px) -110px',
            },
            _a),
        intro: (_b = {
                padding: '150px var(--gutter) 0 var(--gutter)',
                textAlign: 'right',
                display: 'flex',
                justifyContent: 'flex-end',
                flexWrap: 'wrap'
            },
            _b[ViewportMediaQueries.TabletAndAbove] = {
                width: '100%',
                maxWidth: 640,
                margin: '0 auto',
            },
            _b),
        titleBox: {
            width: '100%',
            marginBottom: 20,
            display: 'flex',
            justifyContent: 'flex-end',
        },
        title: __assign(__assign({}, theme.typography.h1), (_c = { color: theme.palette.primary.contrastText }, _c[ViewportMediaQueries.TabletAndAbove] = {
            fontSize: 32,
        }, _c)),
        summary: __assign(__assign({}, theme.typography.body1), (_d = { width: '100%', color: theme.palette.primary.contrastText, maxWidth: 260, textAlign: 'right' }, _d[ViewportMediaQueries.TabletAndAbove] = {
            maxWidth: 'none',
        }, _d)),
        controls: (_e = {
                width: '100%',
                paddingTop: 210,
                marginLeft: 'auto',
                marginRight: 'auto'
            },
            _e[ViewportMediaQueries.TabletAndAbove] = {
                maxWidth: 330,
                paddingTop: 180,
            },
            _e),
        homeBtn: {
            width: '100%',
            backgroundColor: ((_f = theme.palette.navigationButton) === null || _f === void 0 ? void 0 : _f.main) || theme.palette.secondary.main,
            color: ((_g = theme.palette.navigationButton) === null || _g === void 0 ? void 0 : _g.contrastText) ||
                theme.palette.secondary.contrastText,
            '&:hover': {
                backgroundColor: (_h = theme.palette.navigationButton) === null || _h === void 0 ? void 0 : _h.main,
            },
        },
    });
});
