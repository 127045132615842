var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { push } from 'connected-react-router';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import isEmpty from 'lodash/isEmpty';
import useDynalyticsData from 'dyn-design/dist/hooks/useDynalyticsData';
import { appSelectors } from '../slices/app';
import { studySelectors } from '../slices/study';
import { saveRemoteData, saveRemoteDataSuccess, saveRemoteDataFailure, } from '../slices/remoteData';
import { userSelectors } from '../slices/user';
import { langSelectors } from '../slices/lang';
import { URL_PARAMS } from '../../constants/url';
import getServerUrl from '../../utils/getServerUrl';
import ROUTES from '../../routes';
/**
 * This is what gets saved in the database.
 */
var buildStudyDataObject = function (state, completed) {
    var taskStepperState = state.value.taskStepper;
    var language = langSelectors.currentLang(state.value);
    var studySystemVersion = studySelectors.studySystemVersion(state.value);
    var studyCustomVersion = studySelectors.studyCustomVersion(state.value);
    var appVersion = appSelectors.version(state.value);
    var sessionId = useDynalyticsData().sessionId;
    var type = taskStepperState.currentTaskStepper;
    var data = {
        studySystemVersion: studySystemVersion,
        studyCustomVersion: studyCustomVersion,
        appVersion: appVersion,
        sessionId: sessionId,
        language: language,
        sessionIsComplete: completed,
        date: new Date().toString(),
        data: taskStepperState.stepData,
    };
    return { payload: { type: type, data: data } };
};
var saveRemoteDataEpic$ = function (action$, state$) {
    return action$.pipe(ofType(saveRemoteData.type), mergeMap(function (_a) {
        var payload = _a.payload;
        var url = getServerUrl();
        var type = payload.type, data = payload.data;
        var userId = userSelectors.user(state$.value);
        if (!isEmpty(data)) {
            return ajax({
                url: "".concat(url, "/services/data/sessiondata"),
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: {
                    userId: userId,
                    type: type,
                    data: data,
                },
            }).pipe(map(function (data) {
                var _a;
                if ((_a = payload === null || payload === void 0 ? void 0 : payload.data) === null || _a === void 0 ? void 0 : _a.sessionIsComplete) {
                    return saveRemoteDataSuccess(data);
                }
                else {
                    return { type: 'DO_NOTHING' };
                }
            }), catchError(function (error) {
                return of(saveRemoteDataFailure("Could not save data : ".concat(error)));
            }));
        }
        return [];
    }));
};
/**
 * Epics for success and failure will be handled once requirements become
 * specific. `console.log` is intended for now for the development purpose.
 */
var saveRemoteDataSuccessEpic$ = function (action$, state$) {
    return action$.pipe(ofType(saveRemoteDataSuccess.type), mergeMap(function (_a) {
        var payload = _a.payload;
        return of(push(ROUTES.STUDY_END.path));
    }));
};
var saveRemoteDataFailureEpic$ = function (action$, state$) {
    return action$.pipe(ofType(saveRemoteDataFailure.type), mergeMap(function (_a) {
        var payload = _a.payload;
        return [];
    }));
};
/**
 *  At the end of every page, update the remote copy of the study data currently
 *  in progress.
 */
var onPageEndEpic$ = function (action$, state$) {
    return action$.pipe(ofType('taskStepper/completeStep'), mergeMap(function (_a) {
        var payload = _a.payload;
        // Remove once we have wmnHealth accounts with roles
        if (window.location.search.includes(URL_PARAMS.DISABLE_PARTIAL_SESSION_SAVING)) {
            console.warn('Not saving partial session data');
            return of({ type: 'DO_NOTHING' });
        }
        else {
            return of(__assign({ type: saveRemoteData.type }, buildStudyDataObject(state$, false)));
        }
    }));
};
/**
 *  Intercept TaskStepper's action and dispatch `saveData` action to manage the
 *  status of connection
 */
var completeEpic$ = function (action$, state$) {
    return action$.pipe(ofType('taskStepper/complete'), mergeMap(function (_a) {
        var payload = _a.payload;
        return of(__assign({ type: saveRemoteData.type }, buildStudyDataObject(state$, true)));
    }));
};
export default combineEpics(saveRemoteDataEpic$, saveRemoteDataSuccessEpic$, saveRemoteDataFailureEpic$, onPageEndEpic$, completeEpic$);
