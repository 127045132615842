var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useEffect, useState } from "react";
import { Viewports } from "../constants/viewport";
var useCurrentViewport = function () {
    var _a = __read(useState(window.innerWidth), 2), appWidth = _a[0], setAppWidth = _a[1];
    var updateWidth = useCallback(function () {
        setAppWidth(window.innerWidth);
    }, []);
    useEffect(function () {
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return function () { return window.removeEventListener('reize', updateWidth); };
    });
    if (appWidth <= 768) {
        return Viewports.Mobile;
    }
    else if (appWidth > 768 && appWidth <= 1024) {
        return Viewports.Tablet;
    }
    else {
        return Viewports.Desktop;
    }
};
export default useCurrentViewport;
