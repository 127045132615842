import { selectors as taskStepperSelectors, actions as taskStepperActions, } from 'dyn-design/dist/components/TaskStepper/slice';
import { useDispatch, useSelector } from 'react-redux';
export var useAppNavigation = function () {
    var dispatch = useDispatch();
    var steps = useSelector(taskStepperSelectors.steps); // TODO: This should be type
    var goToSection = function (sectionId) {
        var indexOfFirstStepInDesiredSection = steps.findIndex(function (step) { return step.groupId === sectionId; });
        if (indexOfFirstStepInDesiredSection === -1) {
            console.error('Section not found');
        }
        else {
            dispatch(taskStepperActions.goToStep(indexOfFirstStepInDesiredSection + 1));
        }
    };
    var goToUrl = function (url, newTab) {
        var newUrl = url.match(/^https?:\/\//i) === null ? "https://".concat(url) : url;
        if (newTab) {
            window.open(newUrl, '_blank');
        }
        else {
            window.location.href = newUrl;
        }
    };
    return { goToSection: goToSection, goToUrl: goToUrl };
};
