var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { lighten, makeStyles } from '@material-ui/core/styles';
import { ViewportMediaQueries } from '../../constants/viewport';
// @ts-ignore
export var useStyles = makeStyles(function (theme) {
    var _a;
    var _b, _c, _d, _e, _f, _g, _h;
    return ({
        button: __assign(__assign({}, theme.typography.button), { border: 'none', outline: 'none', '&[disabled]': {
                color: 'rgba(0, 0, 0, 0.15) !important',
                background: 'rgba(0, 0, 0, 0.12) !important',
            }, cursor: 'pointer', whiteSpace: 'nowrap', textTransform: 'uppercase !important', borderRadius: theme.shape.borderRadius, height: '42px', padding: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }),
        solid: {
            textDecoration: 'none',
            boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)',
            '&:hover': {
                boxShadow: '0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14)',
            },
        },
        outline: (_a = {
                backgroundColor: '#FFFFFF',
                boxShadow: 'none',
                '&:hover': {
                    boxShadow: 'none',
                }
            },
            _a[ViewportMediaQueries.TabletAndBelow] = {
                '&:hover': {
                    backgroundColor: '#FFFFFF !important',
                }
            },
            _a),
        standardOutlined: {
            border: "1px solid ".concat(((_b = theme.palette.navigationButton) === null || _b === void 0 ? void 0 : _b.main) ||
                theme.palette.secondary.main),
            color: ((_c = theme.palette.navigationButton) === null || _c === void 0 ? void 0 : _c.main) || theme.palette.secondary.main,
            '&:hover': {
                backgroundColor: ((_d = theme.palette.navigationButton) === null || _d === void 0 ? void 0 : _d.hover) ||
                    lighten(((_e = theme.palette.navigationButton) === null || _e === void 0 ? void 0 : _e.main) || theme.palette.secondary.main, 0.125),
            },
        },
        standardContained: {
            backgroundColor: ((_f = theme.palette.navigationButton) === null || _f === void 0 ? void 0 : _f.main) || theme.palette.secondary.main,
            color: ((_g = theme.palette.navigationButton) === null || _g === void 0 ? void 0 : _g.contrastText) ||
                theme.palette.secondary.contrastText,
            '&:hover': {
                backgroundColor: (_h = theme.palette.navigationButton) === null || _h === void 0 ? void 0 : _h.main,
            },
        },
    });
});
