import { makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) { return ({
    fourOhFour: {
        width: '100%',
        height: '100%',
        paddingTop: 80,
    },
    graphic: {
        width: '80%',
        minHeight: 300,
        margin: '0 auto 30px',
        position: 'relative',
        backgroundImage: 'url(/images/cloud-and-trees-and-maintenance.svg)',
        backgroundPosition: 'center bottom',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
    },
    title: {
        marginBottom: 20,
        textAlign: 'center',
        fontSize: 25,
        fontWeight: 600,
        fontFamily: theme.typography.h1.fontFamily || theme.typography.fontFamily,
        lineHeight: 1.28,
        letterSpacing: 0.19,
    },
    messageBox: {
        maxWidth: 400,
        margin: '0 auto',
        padding: '0 var(--gutter)',
        '& > h1, & > p': {
            maxWidth: '50%',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    message: {
        marginBottom: 130,
        textAlign: 'center',
        fontSize: 15,
        lineHeight: 1.6,
        letterSpacing: 0.14,
    },
}); });
