import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ProgressBar from '../ProgressBar';
import NavigationIcons from 'dyn-design/dist/components/StepLayout/ReusableComponents/NavigationIcons';
import { langSelectors } from '../../store/slices/lang';
import { studySelectors } from '../../store/slices/study';
import { flowSelectors } from '../../store/slices/flow';
import { selectors as taskStepperSelectors, actions as taskStepperActions, } from 'dyn-design/dist/components/TaskStepper/slice';
import useCurrentViewport from '../../hooks/useCurrentViewport';
import { Viewports } from '../../constants/viewport';
import { i18n } from '../../utils/i18n';
import { useStyles } from './styles';
var StudyStepHeader = function () {
    var _a;
    var dispatch = useDispatch();
    var styles = useStyles();
    var currentSection = useSelector(studySelectors.currentSection);
    var currentSectionProgressDefinition = useSelector(studySelectors.currentSectionProgressDefinition);
    var pageAppBar = useSelector(studySelectors.currentPage).pageAppBar;
    var lang = useSelector(langSelectors.currentLang);
    var steps = useSelector(taskStepperSelectors.steps);
    var userSectionPath = useSelector(flowSelectors.userSectionPath);
    var currentViewport = useCurrentViewport();
    /**
     * As per the PAD specs, the back button goes to the previous section, not the
     * previous page.
     */
    var handleBackButtonClick = function () {
        var prevSectionInFlow = userSectionPath === null || userSectionPath === void 0 ? void 0 : userSectionPath[1];
        if (!prevSectionInFlow) {
            return console.warn('No known previous section.');
        }
        var prevSectionIndex = steps.findIndex(function (step) { var _a; return step.groupId === ((_a = prevSectionInFlow === null || prevSectionInFlow === void 0 ? void 0 : prevSectionInFlow.step) === null || _a === void 0 ? void 0 : _a.groupId); });
        if (prevSectionIndex !== -1) {
            dispatch(taskStepperActions.goToStep(prevSectionIndex + 1));
        }
    };
    /**
     * The home button goes to the first step in the given section ID.
     */
    var handleHomeButtonClick = function (goToSectionId) {
        var homeStepIndex = steps.findIndex(function (step) { return step.groupId === goToSectionId; });
        if (homeStepIndex !== -1) {
            dispatch(taskStepperActions.goToStep(homeStepIndex + 1));
        }
        else {
            console.error('Invalid step ID');
        }
    };
    if (pageAppBar || (currentSection === null || currentSection === void 0 ? void 0 : currentSection.appBarProps)) {
        return (React.createElement("header", { className: styles.header, style: {
                backgroundColor: pageAppBar === null || pageAppBar === void 0 ? void 0 : pageAppBar.backgroundColor,
            } },
            React.createElement("div", { className: styles.bar },
                React.createElement("div", { className: styles.col }, !!(pageAppBar === null || pageAppBar === void 0 ? void 0 : pageAppBar.previousSectionBtn) && (React.createElement("button", { type: "button", onClick: handleBackButtonClick },
                    React.createElement(NavigationIcons, { btn: pageAppBar.previousSectionBtn })))),
                React.createElement("h1", { className: "".concat(styles.title, " ").concat(styles.col), style: {
                        color: pageAppBar === null || pageAppBar === void 0 ? void 0 : pageAppBar.textColor,
                    } }, i18n(pageAppBar === null || pageAppBar === void 0 ? void 0 : pageAppBar.text, lang)),
                React.createElement("div", { className: styles.col }, !!((_a = currentSection === null || currentSection === void 0 ? void 0 : currentSection.appBarProps) === null || _a === void 0 ? void 0 : _a.homeBtn) && (React.createElement("button", { type: "button", onClick: function () { var _a; return handleHomeButtonClick((_a = currentSection === null || currentSection === void 0 ? void 0 : currentSection.appBarProps) === null || _a === void 0 ? void 0 : _a.homeBtn.navigateTo); } },
                    React.createElement(NavigationIcons, { btn: currentSection.appBarProps.homeBtn }))))),
            currentViewport === Viewports.Desktop && currentSectionProgressDefinition && (React.createElement("div", { className: styles.desktopProgressBar },
                React.createElement(ProgressBar, null)))));
    }
    else {
        return React.createElement(React.Fragment, null);
    }
};
export default StudyStepHeader;
