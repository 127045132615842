var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import getServerUrl, { isLocalhost } from '../../utils/getServerUrl';
import { getMagicLinkFromCurrentUrl, getTestCodeFromCurrentUrl, } from '../../utils/magicLinks';
import { combineEpics, ofType, } from 'redux-observable';
import { catchError, exhaustMap, map, switchMap, takeUntil, } from 'rxjs/operators';
import { of, timer } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { authActions } from '../slices/auth';
import useDynalytics from 'dyn-design/dist/hooks/useDynalytics';
import useDynalyticsData from 'dyn-design/dist/hooks/useDynalyticsData';
import { generateAnalyticsFactData, } from '../../hooks/useAppEngineDynalytics';
import { AUTH_METHODS } from 'dyn-design/dist/interfaces/StudyDataTypes';
/**
 * DEFAULTS
 */
var DEFAULT_2MIN_INTERVAL = 120000;
var ERROR_THRESHOLD = 3;
var SESSION_NO_LONGER_VALID_FACT_NAME = 'session_validation_expired';
var generateAuthFactReason = function (authType) {
    switch (authType) {
        case AUTH_METHODS.MAGIC_LINK:
            return 'Magic link has expired or no longer exists';
        case AUTH_METHODS.PUBLIC:
            return 'App is no longer publicly available with the current link';
        default:
            return;
    }
};
/*
 * Used to determine if the Magic link the user authenticated with is still valid
 * THIS IS ANOTHER OPTION FOR POLLING
 */
// export const getIsStillAuthorizedApi = createApi({
//   reducerPath: 'auth',
//   baseQuery: fetchBaseQuery({
//     baseUrl: `${getServerUrl()}/services/studyvalidation/sessions`,
//   }),
//   endpoints: (builder) => ({
//     getIsUserStillAuthorized: builder.query({
//       query: () => {
//         const magicLink = getMagicLinkFromCurrentUrl();
//         const testCode = getTestCodeFromCurrentUrl();
//         let params = '';
//         if (testCode) {
//           params = `?testcode=${testCode}`;
//         }
//         if (magicLink) {
//           params = `?link=${magicLink}`;
//         }
//         return { url: `/${params}`, method: 'POST' };
//       },
//     }),
//   }),
// });
// // @ts-ignore
// export const { useGetIsUserStillAuthorizedQuery } = getIsStillAuthorizedApi;
/**
 * Temp solution for polling to check every 30s whether the session is still authorized to be open
 * @param action$
 * @param state$
 * @returns
 */
var pollUserSessionAuthorizedEpic$ = function (action$, state$) {
    return action$.pipe(ofType(authActions.startPolling.type), switchMap(function () {
        return timer(0, DEFAULT_2MIN_INTERVAL).pipe(takeUntil(action$.ofType(authActions.stopPolling.type)), exhaustMap(function () {
            var magicLink = getMagicLinkFromCurrentUrl();
            var testCode = getTestCodeFromCurrentUrl();
            var params = '';
            if (isLocalhost) {
                console.log('Dev mode: mocking auth polling response.');
                // TODO: Un-comment the 2 lines bellow to run this locally otherwise it will just stop polling
                // const myDevMagicLink = 'Wh38qQrfJHDF_6rXSkIFt'; // TODO: Put your own local mgl code here
                // params = `?link=${myDevMagicLink}`;
                // TODO: Comment out the below code to not run polling while in dev mode
                return of(authActions.userSessionStillAuthenticatedSuccess({
                    authenticated: true,
                    method: AUTH_METHODS.MAGIC_LINK,
                }), authActions.stopPolling(true));
            }
            if (testCode) {
                params = "?testcode=".concat(testCode);
            }
            if (magicLink) {
                params = "?link=".concat(magicLink);
            }
            return ajax({
                url: "".concat(getServerUrl(), "/services/studyvalidation/sessions").concat(params),
                method: 'GET',
            }).pipe(map(function (_a) {
                var response = _a.response;
                return authActions.userSessionStillAuthenticatedSuccess(response.authData);
            }), catchError(function (error) {
                var _a, _b, _c;
                var currentErrorCount = state$.value.auth.pollingUserAuth.count;
                if (currentErrorCount >= ERROR_THRESHOLD) {
                    /**
                     * useDynalytics() and useDynalyticsData() are not react hooks but instead are functions that access the window (return other functions)
                     * look at design system to get a full picture
                     */
                    var dynalytics = useDynalytics();
                    var sessionId = useDynalyticsData().sessionId;
                    var linkId = state$.value.auth.magicLink.link;
                    var authMethod = state$.value.auth.method;
                    var studyId = (_a = state$.value.study.studyData) === null || _a === void 0 ? void 0 : _a.studyId;
                    var studySystemVersion = (_b = state$.value.study.studyData) === null || _b === void 0 ? void 0 : _b.systemVersion;
                    var studyCustomVersion = (_c = state$.value.study.studyData) === null || _c === void 0 ? void 0 : _c.customVersion;
                    var lang = state$.value.lang.current;
                    var otherFactProps = {
                        sessionId: sessionId || undefined,
                        linkId: linkId,
                        authMethod: authMethod,
                        studyId: studyId,
                        studySystemVersion: studySystemVersion,
                        studyCustomVersion: studyCustomVersion,
                        lang: lang,
                        reason: generateAuthFactReason(authMethod),
                    };
                    var steps = state$.value.taskStepper.steps;
                    var currentStepNumber = state$.value.taskStepper.currentStep;
                    var currentStepData = currentStepNumber && (steps === null || steps === void 0 ? void 0 : steps.length) >= currentStepNumber
                        ? __assign(__assign({}, steps[currentStepNumber - 1]), { number: currentStepNumber })
                        : undefined;
                    var generatedFactData = generateAnalyticsFactData({
                        factName: SESSION_NO_LONGER_VALID_FACT_NAME,
                        data: {
                            step: currentStepData,
                        },
                    }, otherFactProps);
                    // Handle add fact for ending a users session
                    dynalytics(SESSION_NO_LONGER_VALID_FACT_NAME, generatedFactData);
                    return of(authActions.userSessionStillAuthenticatedFailure("Could not authorize users session: ".concat(error)), authActions.stopPolling(true));
                }
                else {
                    return of(authActions.incrementPollingFailureCount());
                }
            }));
        }));
    }));
};
export default combineEpics(pollUserSessionAuthorizedEpic$);
