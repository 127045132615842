var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeStyles } from '@material-ui/core';
import { ViewportMediaQueries } from '../../constants/viewport';
export var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        header: {
            width: '100%',
            height: 'var(--studyHeaderHeight)',
            padding: '0 10px',
            textAlign: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: theme.palette.primary.main,
            zIndex: 99999999,
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
        },
        col: {
            minWidth: 50,
        },
        bar: {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& button': {
                border: 'none',
                background: 'none',
                cursor: 'pointer',
                '&:focus': {
                    outline: 'none',
                },
            },
            '& svg': {
                width: 30,
                height: 30,
                color: theme.palette.primary.contrastText,
            },
        },
        title: __assign(__assign({}, theme.typography.h1), (_a = { color: theme.palette.primary.contrastText, marginBottom: 0, fontSize: '24px' }, _a[ViewportMediaQueries.TabletAndAbove] = {
            fontSize: theme.typography.h1.fontSize || 25,
        }, _a)),
        desktopProgressBar: {
            position: 'fixed',
            top: 'var(--studyHeaderHeight)',
            left: 0,
            right: 0,
        },
    });
});
