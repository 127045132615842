var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { Button, Checkbox, FormControlLabel, Modal, TextField, } from '@material-ui/core';
import { HTMLContentRenderer } from 'dyn-design/dist/components/StepLayout/contentLayouts/HTMLRenderer';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { langSelectors } from '../../store/slices/lang';
import i18nStrings from './i18n.json';
import { i18n } from '../../utils/i18n';
import { studySelectors } from '../../store/slices/study';
var ContactInfoModal = function (_a) {
    var _b = _a.open, open = _b === void 0 ? false : _b, onSubmit = _a.onSubmit, onClose = _a.onClose;
    var lang = useSelector(langSelectors.currentLang);
    var formModalSettings = useSelector(studySelectors.studyFormSettings);
    var styles = useStyles();
    var _c = __read(useState({
        fullName: '',
        email: '',
        agree: false,
    }), 2), contactInfo = _c[0], setContactInfo = _c[1];
    var handleChange = function (e) {
        var _a;
        setContactInfo(__assign(__assign({}, contactInfo), (_a = {}, _a[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value, _a)));
    };
    var handleCancel = function () {
        onClose();
    };
    var handleSubmit = function (e) {
        var contactInfoToSend = __assign({}, contactInfo);
        // Consider `agree` true if `displayDisclaimer` is false or
        // `hideDisclaimerCheckbox` is true.
        if (!(formModalSettings === null || formModalSettings === void 0 ? void 0 : formModalSettings.displayDisclaimer) || (formModalSettings === null || formModalSettings === void 0 ? void 0 : formModalSettings.hideDisclaimerCheckbox)) {
            contactInfoToSend.agree = true;
        }
        onSubmit(e, contactInfoToSend);
    };
    var validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(contactInfo.email);
    var validFullName = contactInfo.fullName.length > 0;
    var validAgree = ((formModalSettings === null || formModalSettings === void 0 ? void 0 : formModalSettings.displayDisclaimer) &&
        !formModalSettings.hideDisclaimerCheckbox) ||
        !formModalSettings
        ? contactInfo.agree
        : true;
    return (React.createElement("div", null,
        React.createElement(Modal, { open: open, onClose: onClose },
            React.createElement("div", { className: styles.modal },
                React.createElement("h2", { className: styles.title }, i18n(i18nStrings.title, lang)),
                React.createElement("div", { className: styles.inputFields },
                    React.createElement(TextField, { name: 'fullName', variant: 'outlined', size: 'small', classes: { root: styles.textField }, label: i18n(i18nStrings.enterFullName, lang), onChange: handleChange, value: contactInfo.fullName, InputLabelProps: { shrink: true }, InputProps: { classes: { root: styles.textFieldInput } } }),
                    React.createElement(TextField, { name: 'email', variant: 'outlined', size: 'small', classes: { root: styles.textField }, label: i18n(i18nStrings.enterEmail, lang), onChange: handleChange, value: contactInfo.email, InputLabelProps: { shrink: true }, InputProps: { classes: { root: styles.textFieldInput } } })),
                React.createElement("div", { className: styles.agree }, formModalSettings ? (formModalSettings.displayDisclaimer ? (formModalSettings.hideDisclaimerCheckbox === false ? (React.createElement(FormControlLabel, { key: "disclaimer-form-input", style: { alignItems: 'center', marginLeft: 0 }, classes: { label: styles.checkboxLabel }, control: React.createElement(Checkbox, { id: 'contact-info-agree-privacy-notice', name: 'agree', color: 'primary', onChange: handleChange, size: 'small', style: { padding: '5px' } }), label: React.createElement("div", { style: { padding: '0px 4px' } },
                        React.createElement(HTMLContentRenderer, { content: formModalSettings.disclaimerText[lang] })) })) : (React.createElement("div", { style: { padding: '4px 8px' } },
                    React.createElement(HTMLContentRenderer, { content: formModalSettings.disclaimerText[lang] })))) : null) : (React.createElement(React.Fragment, null,
                    React.createElement(Checkbox, { id: 'contact-info-agree-privacy-notice', name: 'agree', color: 'primary', onChange: handleChange, size: 'small' }),
                    React.createElement("label", { className: styles.agreeLabel, htmlFor: 'contact-info-agree-privacy-notice' },
                        i18n(i18nStrings.agreeText, lang),
                        "\u00A0",
                        React.createElement("a", { href: 'https://wmnhealth.org/privacy-notice', target: '_blank', rel: 'noreferrer' }, i18n(i18nStrings.privacyNotice, lang)))))),
                React.createElement("div", { className: styles.buttons },
                    React.createElement(Button, { className: styles.button, variant: 'outlined', color: 'primary', onClick: handleCancel }, i18n(i18nStrings.cancel, lang)),
                    React.createElement(Button, { className: styles.button, variant: 'contained', color: 'primary', disabled: !(validEmail && validFullName && validAgree), onClick: handleSubmit }, i18n(i18nStrings.submit, lang)))))));
};
export default ContactInfoModal;
