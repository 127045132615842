var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeStyles } from '@material-ui/core';
export var useStyles = makeStyles(function (theme) { return ({
    review: {
        width: '100%',
        height: '100%',
    },
    questions: {
        padding: '20px 0',
    },
    question: {
        margin: '0 15px 15px',
        padding: 12,
        borderRadius: 8,
        // TODO move to dyn-design palette.background.paper
        background: '#FAFAFA',
        boxShadow: '0 2px 9px 0 rgba(0, 0, 0, 0.11)',
    },
    questionNum: __assign(__assign({}, theme.typography.h2), { marginBottom: 8 }),
    questionName: {
        marginBottom: 8,
        display: 'flex',
        alignItems: 'flex-start',
    },
    editIcon: {
        width: 24,
        height: 24,
        cursor: 'pointer',
    },
    questionText: __assign(__assign({}, theme.typography.body2), { width: 'calc(100% - 24px)', paddingRight: 10, overflow: 'hidden' }),
    givenAnswer: {
        color: theme.palette.primary.main,
    },
    givenAnswerValues: {
        whiteSpace: 'pre-line',
        lineHeight: '1.5em',
    },
    noDataError: {
        padding: 20,
    },
}); });
