var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeStyles } from '@material-ui/core';
export var useStyles = makeStyles(function (theme) { return ({
    step: function (_a) {
        var customStyles = _a.customStyles;
        return (__assign({ width: '100%', maxWidth: 680, height: '100%', margin: '0 auto', position: 'relative', display: 'flex' }, customStyles));
    },
}); });
