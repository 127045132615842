var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_METHODS } from 'dyn-design/dist/interfaces/StudyDataTypes';
import getServerUrl, { isLocalhost } from '../../utils/getServerUrl';
import hardcodedStudyData from '../hardcodedStudyData';
import { getMagicLinkFromCurrentUrl, getTestCodeFromCurrentUrl, } from '../../utils/magicLinks';
export var ACTION_TYPE = 'app/authenticateStudy';
/**
 * For Local Development and TESTING
 * @param authMethod
 * @returns
 */
var handleGenerateLocalAuthData = function (authMethod) {
    if (authMethod === AUTH_METHODS.MAGIC_LINK) {
        return {
            authData: {
                authenticated: true,
                method: AUTH_METHODS.MAGIC_LINK,
                magicLinkData: {
                    uid: '0000018a7663a7d30000000000000000000a7db1b10be69f09690c3d282f0f60', // '0000018978d2e782000000000000000000152af6d4ba17c3e8e77706dd56b3af'
                    expired: false,
                    linkedSectionId: '0000018761150fe40000000000000300000a4ae2496c9e2fd41d8cd98f00b204', // copy a section id from the study json that you're fetching
                },
            },
            studyData: hardcodedStudyData,
            testMode: false,
        };
    }
    else {
        return {
            authData: {
                authenticated: true,
                method: AUTH_METHODS.PUBLIC,
            },
            studyData: hardcodedStudyData,
            testMode: true,
        };
    }
};
/**
 * Check the validity of the given study link with the Study Data service.
 */
var authenticateStudy = createAsyncThunk(ACTION_TYPE, function (data, thunkAPI) { return __awaiter(void 0, void 0, Promise, function () {
    var magicLink, testCode, url, params, result, data_1, error, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                // Bypass auth process on Local environment and use hardcodedStudyData as study data
                // Command out this if you wanna test auth on local
                if (isLocalhost) {
                    console.log('Dev mode: mocking auth response.');
                    // You can uncomment the line below if you want to work on the loading page related task.
                    // await new Promise((resolve) => setTimeout(resolve, 5000));
                    // Have to ignore the typescript check for the hardcoded study data, since the json file pass value without type
                    // for example : '{type:"light"}' is type string, which will violate the type <"light" | "dark" | undefined> defined in MatesterStyles
                    //@ts-ignore
                    return [2 /*return*/, handleGenerateLocalAuthData(AUTH_METHODS.PUBLIC)]; // OR AUTH_METHODS (Change here to reproduce actions for specific auth method)
                }
                magicLink = getMagicLinkFromCurrentUrl();
                testCode = getTestCodeFromCurrentUrl();
                url = "".concat(getServerUrl(), "/services/studyvalidation/validation");
                params = '';
                if (testCode) {
                    params = "?testcode=".concat(testCode);
                }
                if (magicLink) {
                    params = "?link=".concat(magicLink);
                }
                _a.label = 1;
            case 1:
                _a.trys.push([1, 7, , 8]);
                return [4 /*yield*/, fetch("".concat(url, "/").concat(params), {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })];
            case 2:
                result = _a.sent();
                if (!result.ok) return [3 /*break*/, 4];
                return [4 /*yield*/, result.json()];
            case 3:
                data_1 = _a.sent();
                return [2 /*return*/, __assign(__assign({}, data_1), { testMode: !!testCode })];
            case 4: return [4 /*yield*/, result.json()];
            case 5:
                error = _a.sent();
                console.error(error);
                return [2 /*return*/, {
                        errorStatus: result.status,
                        customErrorMessage: error.customErrorMessage,
                        authData: {
                            authenticated: false,
                        },
                        testMode: !!testCode,
                    }];
            case 6: return [3 /*break*/, 8];
            case 7:
                error_1 = _a.sent();
                // when SaaS is shut down.
                console.error(error_1);
                return [2 /*return*/, {
                        errorStatus: 500,
                        authData: {
                            authenticated: false,
                        },
                        testMode: !!testCode,
                    }];
            case 8: return [2 /*return*/];
        }
    });
}); });
export default authenticateStudy;
