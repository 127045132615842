import { makeStyles } from '@material-ui/core';
import { ViewportMediaQueries } from '../../constants/viewport';
export var useStyles = makeStyles(function (theme) {
    var _a, _b;
    return ({
        modal: (_a = {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '486px',
                backgroundColor: '#fafafa',
                padding: '16px 24px 30px',
                borderRadius: '4px',
                boxShadow: '0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14)'
            },
            _a[ViewportMediaQueries.TabletAndBelow] = {
                maxWidth: '80%',
            },
            _a),
        title: {
            fontFamily: theme.typography.fontFamily,
            fontSize: '20px',
            lineHeight: 1.6,
            letterSpacing: '0.15px',
            color: theme.palette.text.primary,
        },
        inputFields: {
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            marginTop: '16px',
        },
        // prevent the label from overlapping the border of it.
        textField: {
            '& .MuiInputLabel-root': {
                fontSize: theme.typography.body1.fontSize,
            },
            '& .MuiOutlinedInput-root': {
                fontSize: theme.typography.body1.fontSize,
                '& > fieldset > legend': {
                    fontSize: "calc(0.75 * ".concat(theme.typography.body1.fontSize, ")"),
                },
            },
        },
        textFieldInput: {
            marginBottom: 0,
            borderRadius: '4px',
            fontFamily: theme.typography.fontFamily,
            // note: font-size is set by theme.typography.body1.fontSize
        },
        agree: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '10px',
            marginLeft: '-9px', // because the ripple effect takes 9px
        },
        agreeLabel: (_b = {
                fontSize: '14px',
                lineHeight: 1.71,
                letterSpacing: '0.13px',
                color: theme.palette.text.primary
            },
            _b[ViewportMediaQueries.TabletAndBelow] = {
                lineHeight: 1.2,
            },
            _b),
        buttons: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '10px',
            gap: '10px',
        },
        button: {
            width: '150px',
            height: '30px',
            fontSize: '13px',
            lineHeight: '23px',
            letterSpacing: '0.37px',
            borderRadius: '4px',
            fontFamily: theme.typography.fontFamily,
        },
        checkboxLabel: {
            marginBottom: 0,
        },
    });
});
