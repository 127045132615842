import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useAnswerValueFormatter from '../../hooks/useAnswerValueFormatter';
import { URL_PARAMS } from '../../constants/url';
import { actions as taskStepperActions, selectors as taskStepperSelectors, } from 'dyn-design/dist/components/TaskStepper/slice';
import { langSelectors } from '../../store/slices/lang';
import { i18n } from '../../utils/i18n';
import i18nStrings from './i18n.json';
import { useStyles } from './styles';
/**
 * Allows a user to review their answers for any group of questions.
 */
var QuestionReview = function (_a) {
    var group = _a.group;
    var styles = useStyles();
    var dispatch = useDispatch();
    var answerValueFormatter = useAnswerValueFormatter();
    var steps = useSelector(taskStepperSelectors.steps);
    var stepData = useSelector(taskStepperSelectors.stepData);
    var currentStep = useSelector(taskStepperSelectors.currentStep);
    var givenAnswersForThisGroup = (stepData === null || stepData === void 0 ? void 0 : stepData[group]) || {};
    var questionSlugsForThisGroup = Object.keys(givenAnswersForThisGroup);
    var lang = useSelector(langSelectors.currentLang);
    /**
     * FIXME: When doing APP-49, we should replace window.history.replaceState()
     * with standard react-router navigation. Also do it for the click that
     * returns the user here.
     */
    var handleEditClick = function (stepNum) {
        var _a;
        var currentStepId = (_a = steps === null || steps === void 0 ? void 0 : steps[currentStep]) === null || _a === void 0 ? void 0 : _a.id;
        var reviewUrl = "".concat(window.location.pathname, "?").concat(URL_PARAMS.EDIT_ANSWER_THEN_RETURN_TO_REVIEW_PAGE, "=").concat(currentStepId);
        window.history.replaceState({}, '', reviewUrl);
        dispatch(taskStepperActions.goToStep(stepNum));
    };
    return (React.createElement("div", { className: styles.review }, questionSlugsForThisGroup.length ? (React.createElement("div", { className: styles.questions }, questionSlugsForThisGroup.map(function (questionSlug, index) {
        return (React.createElement("div", { className: styles.question, key: "question-".concat(index) },
            React.createElement("p", { className: styles.questionNum }, i18n(i18nStrings['question-number'], lang).replace('{num}', String(index + 1))),
            React.createElement("div", { className: styles.questionName },
                React.createElement("div", { className: styles.questionText, dangerouslySetInnerHTML: {
                        __html: i18n(givenAnswersForThisGroup[questionSlug].question.text, lang),
                    } }),
                React.createElement("div", { className: styles.editIcon, onClick: function () {
                        var _a;
                        return handleEditClick(((_a = givenAnswersForThisGroup[questionSlug]) === null || _a === void 0 ? void 0 : _a.step) || 1);
                    } },
                    React.createElement("img", { src: '/images/pencil.svg', alt: i18n(i18nStrings['edit-icon-alt'], lang) }))),
            React.createElement("div", { className: styles.givenAnswer },
                React.createElement("div", { className: styles.givenAnswerValues }, answerValueFormatter(givenAnswersForThisGroup[questionSlug])))));
    }))) : (React.createElement("p", { className: styles.noDataError }, "There are no recorded answers to display."))));
};
export default QuestionReview;
