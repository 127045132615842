import { makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) { return ({
    loading: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: 0,
        top: 0,
        // You can try to change the background color to debug loading functionality.
        backgroundColor: '#ffffff',
        zIndex: 9999,
    },
    messageBox: {
        maxWidth: 1000,
        margin: '0 auto',
        padding: '0 var(--gutter)',
        '& > h1, & > p': {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    message: {
        marginBottom: 60,
        textAlign: 'center',
        fontSize: 15,
        lineHeight: 1.6,
        letterSpacing: 0.14,
    },
    loader: {
        textAlign: 'center',
    },
    errorMessage: {
        fontSize: 16,
        padding: '15px 0',
        textAlign: 'center',
        fontWeight: 'bold',
    },
}); });
