import { makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) { return ({
    fourOhFour: {
        width: '100%',
        height: '100%',
        paddingTop: 80,
    },
    graphic: {
        width: '80%',
        minHeight: 300,
        margin: '0 auto 30px',
        position: 'relative',
        backgroundImage: 'url(/images/cloud-and-trees.svg)',
        backgroundPosition: 'center bottom',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
    },
    errorCode: {
        textAlign: 'center',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 30,
        fontSize: 120,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        textShadow: '0 2px 3px rgba(0, 0, 0, 0.5)',
    },
    title: {
        marginBottom: 20,
        textAlign: 'center',
        fontSize: 25,
        fontWeight: 600,
        fontFamily: theme.typography.fontFamily,
        lineHeight: 1.28,
        letterSpacing: 0.19,
    },
    messageBox: {
        maxWidth: 400,
        margin: '0 auto',
        padding: '0 var(--gutter)',
    },
    message: {
        marginBottom: 130,
        textAlign: 'center',
        fontSize: 15,
        lineHeight: 1.6,
        letterSpacing: 0.14,
    },
}); });
