import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../Button';
import { selectors as taskStepperSelectors, actions as taskStepperActions, } from 'dyn-design/dist/components/TaskStepper/slice';
import { langSelectors } from '../../../store/slices/lang';
import { i18n } from '../../../utils/i18n';
import i18nStrings from '../i18n.json';
import { useStyles } from '../styles';
var QuestionReviewControls = function (_a) {
    var onCompleteReview = _a.onCompleteReview;
    var dispatch = useDispatch();
    var styles = useStyles();
    var currentStepNum = useSelector(taskStepperSelectors.currentStep);
    var lang = useSelector(langSelectors.currentLang);
    // The default "complete review" action is to evaluate the conditionals for this section
    var defaultOnCompleteReview = function () {
        dispatch(taskStepperActions.goToStep(currentStepNum + 1));
    };
    return (React.createElement("div", { className: styles.stepNav },
        React.createElement(Button, { className: "".concat(styles.stepNavSingleButton, " ").concat(styles.commonNavContained), onClick: typeof onCompleteReview === 'function'
                ? onCompleteReview
                : defaultOnCompleteReview }, i18n(i18nStrings['button-complete-review'], lang))));
};
export default QuestionReviewControls;
