var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeStyles } from '@material-ui/core';
import { ViewportMediaQueries } from '../../constants/viewport';
export var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        question: (_a = {
                width: '100%',
                alignSelf: 'flex-end'
            },
            _a[ViewportMediaQueries.TabletAndAbove] = {
                '& .step-layout-input-multiselect-button': {
                    maxWidth: 330,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    display: 'block',
                    '&:last-of-type': {
                        marginBottom: 0,
                    }
                },
                '& .step-layout-input-range .slider-container': {
                    minHeight: 40,
                },
            },
            _a),
        pageProgress: __assign(__assign({}, theme.typography.body2), { padding: '15px 25px 25px 25px' }),
    });
});
