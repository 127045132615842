import React from 'react';
import { useSelector } from 'react-redux';
import ProgressBar from '../../components/ProgressBar';
import useIsReviewPage from '../../hooks/useIsReviewPage';
import useCurrentViewport from '../../hooks/useCurrentViewport';
import { studySelectors } from '../../store/slices/study';
import QuestionControls from './controls/QuestionControls';
import QuestionReviewControls from './controls/QuestionReviewControls';
import { Viewports } from '../../constants/viewport';
import { useStyles } from './styles';
import useStickyAudioPlayerDefinition from '../../hooks/useStickyAudioPlayerDefinition';
import { useIsFormPage } from '../../hooks/useFormSubmissionButton';
import FormSubmissionButton from './controls/FormSubmissionButton';
;
var StudyStepNav = function (_a) {
    var currentPageAnswers = _a.currentPageAnswers, nextButtonDisabled = _a.nextButtonDisabled, onNextStepClick = _a.onNextStepClick, onPrevStepClick = _a.onPrevStepClick;
    var isReviewPage = useIsReviewPage();
    var _b = useIsFormPage(), isFormPage = _b.isFormPage, pageForm = _b.pageForm;
    var currentViewport = useCurrentViewport();
    var currentSectionProgressDefinition = useSelector(studySelectors.currentSectionProgressDefinition);
    var stickyAudioPlayerDefinition = useStickyAudioPlayerDefinition();
    var styles = useStyles();
    return (React.createElement("div", { id: 'study-step-nav', className: "".concat(styles.nav, " ").concat(stickyAudioPlayerDefinition() ? styles.hasStickyAudioPlayer : undefined) },
        (currentViewport === Viewports.Mobile || currentViewport === Viewports.Tablet) && currentSectionProgressDefinition
            && React.createElement(ProgressBar, null),
        isFormPage
            && React.createElement(FormSubmissionButton, { pageForm: pageForm, currentPageAnswers: currentPageAnswers }),
        isReviewPage()
            ? React.createElement(QuestionReviewControls, null)
            : React.createElement(QuestionControls, { currentPageAnswers: currentPageAnswers, nextButtonDisabled: nextButtonDisabled, onNextStepClick: onNextStepClick, onPrevStepClick: onPrevStepClick })));
};
export default StudyStepNav;
