import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import history from './store/history';
import configureStore from './store/configureStore';
import AppVersion from './components/AppVersion';
var store = configureStore();
var Root = function () {
    return (React.createElement(HelmetProvider, null,
        React.createElement(Provider, { store: store }, 
        // @ts-ignore
        React.createElement(ConnectedRouter, { history: history },
            React.createElement(App, null),
            React.createElement(AppVersion, null)))));
};
export default Root;
