import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/Button';
import { langSelectors } from '../../store/slices/lang';
import { i18n } from '../../utils/i18n';
import i18nStrings from './i18n.json';
import { useStyles } from './styles';
import { appActions } from '../../store/slices/app';
var PageNotFound = function () {
    var dispatch = useDispatch();
    var styles = useStyles();
    var lang = useSelector(langSelectors.currentLang);
    /**
     * `LoadingStudy` page can disppear by the selector `app.loaded`. `PageNotFound`
     * page is shown only if the study data is loaded but the path is wrong. So
     * this page should set `app.loaded` true in order to hide `LoadingStudy`
     * page.
     */
    useEffect(function () {
        dispatch(appActions.setLoaded(true));
    }, []);
    return (React.createElement("div", { className: styles.fourOhFour },
        React.createElement("div", { className: styles.graphic },
            React.createElement("p", { className: styles.errorCode }, "404")),
        React.createElement("div", { className: styles.messageBox },
            React.createElement("h1", { className: styles.title }, i18n(i18nStrings['title'], lang)),
            React.createElement("p", { className: styles.message }, i18n(i18nStrings['message'], lang)),
            React.createElement(Button, { href: "/" }, i18n(i18nStrings['home-button'], lang)))));
};
export default PageNotFound;
