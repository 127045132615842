var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import generateButtonName from "./generateButtonName";
/**
 * Generates an object of data for a button click.
 *
 * @param el - Button DOM element.
 * @param [more] - Additional data to track about this button.
 */
export default function generateTrackedButtonData(el, more) {
    if (more === void 0) { more = {}; }
    if (!el) {
        console.error('Invalid element');
        return {
            buttonName: '',
            buttonHref: null,
            buttonTitle: null,
            buttonTarget: null,
        };
    }
    return __assign({ buttonName: generateButtonName(el), buttonHref: el.getAttribute('href'), buttonTitle: el.getAttribute('title'), buttonTarget: el.getAttribute('target'), buttonText: el.innerText }, more);
}
