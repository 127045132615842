var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { ThemeProvider } from '@material-ui/core/styles';
import { deepmerge } from '@material-ui/utils';
import { createCustomTheme } from 'dyn-design/dist/components/StepLayout/utils/StudyThemeProvider';
import CustomThemeAdditionsProvider from 'dyn-design/dist/components/StepLayout/ReusableComponents/CustomThemeAdditionsProvider';
import React, { useEffect, useState } from 'react';
import { useStudyMasterStyles } from '../../hooks/useMasterStyles';
var getDisplayName = function (WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};
var withStudyTheme = function (WrappedComponent) {
    var Wrapped = function (props) {
        var _a = useStudyMasterStyles(), studyTheme = _a.studyTheme, themeTypography = _a.themeTypography, loadThemefonts = _a.loadThemefonts, hasTypography = _a.hasTypography, studyDataLoaded = _a.studyDataLoaded, fontsLoaded = _a.fontsLoaded, masterStylesAdditional = _a.masterStylesAdditional;
        var theme = createCustomTheme(deepmerge(studyTheme));
        var _b = __read(useState(false), 2), fontsReady = _b[0], setFontsReady = _b[1];
        useEffect(function () {
            var _a;
            if (studyDataLoaded) {
                if (hasTypography(themeTypography) && ((_a = themeTypography.fontFamilies) === null || _a === void 0 ? void 0 : _a.length)) {
                    loadThemefonts(themeTypography.fontFamilies);
                }
                else {
                    setFontsReady(true);
                }
            }
        }, [studyDataLoaded]);
        useEffect(function () {
            if (fontsLoaded) {
                setFontsReady(true);
            }
        }, [fontsLoaded]);
        WrappedComponent.displayName = 'Wrapped With Theme';
        return (React.createElement(ThemeProvider, { theme: theme },
            React.createElement(CustomThemeAdditionsProvider, { additionalMasterStyling: masterStylesAdditional },
                React.createElement(WrappedComponent, __assign({}, props, { fontsReady: fontsReady })))));
    };
    Wrapped.displayName = "withStudyTheme".concat(getDisplayName(WrappedComponent));
    return Wrapped;
};
export default withStudyTheme;
