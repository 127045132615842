import { createSlice } from "@reduxjs/toolkit";
var toastSlice = createSlice({
    name: 'toast',
    initialState: {
        id: 0,
        type: null,
        content: null,
    },
    reducers: {
        showToast: function (state, action) {
            state.id = state.id + 1;
            state.type = action.payload.type;
            state.content = action.payload.content;
        }
    },
});
export var toastSelectors = {
    id: function (store) { return store.toast.id; },
    type: function (store) { return store.toast.type; },
    content: function (store) { return store.toast.content; },
};
export var toastActions = toastSlice.actions;
export default toastSlice;
