var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useDispatch, useSelector } from 'react-redux';
import downloadPdf from '../store/thunks/downloadPdf';
import { pdfDownloadSelectors } from '../store/slices/pdfDownload';
import { useEffect, useState } from 'react';
export var usePdfDownload = function () {
    var dispatch = useDispatch();
    var pdfDownloadSuccess = useSelector(pdfDownloadSelectors.success);
    var pdfDownloadError = useSelector(pdfDownloadSelectors.error);
    var pdfDownloadLoading = useSelector(pdfDownloadSelectors.loading);
    var pdfData = useSelector(pdfDownloadSelectors.data);
    var _a = __read(useState(), 2), newFileName = _a[0], setNewFileName = _a[1];
    var downloadPDF = function (url, newFileName) {
        if (newFileName) {
            setNewFileName(newFileName);
        }
        dispatch(downloadPdf({
            urls: [url],
            resolutions: [
                {
                    width: 1440,
                    height: 990,
                },
            ],
            fullPage: true,
            template: 'bsp',
            ignoreElements: ['narration', 'dynbutton'],
        }));
    };
    useEffect(function () {
        if (pdfDownloadSuccess && pdfData && pdfData.constructor.name === 'Blob') {
            var virtualElem = document.createElement('a');
            virtualElem.href = URL.createObjectURL(pdfData);
            virtualElem.download = "".concat(newFileName || "temp_".concat(Date.now()), ".pdf");
            virtualElem.click();
            URL.revokeObjectURL(virtualElem.href);
        }
    }, [pdfDownloadSuccess]);
    return {
        downloadPDF: downloadPDF,
        pdfDownloadSuccess: pdfDownloadSuccess,
        pdfDownloadError: pdfDownloadError,
        pdfDownloadLoading: pdfDownloadLoading,
    };
};
