var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import useDynalyticsData from 'dyn-design/dist/hooks/useDynalyticsData';
/**
 * Sends flow tracking data to the Study Data server.
 *
 * This must be an async non-blocking network request.
 */
var flowTracking = createAsyncThunk('flow/addFlowStep', function (flowData, thunkAPI) { return __awaiter(void 0, void 0, Promise, function () {
    var sessionId, store, linkId;
    var _a, _b;
    return __generator(this, function (_c) {
        sessionId = useDynalyticsData().sessionId;
        store = thunkAPI.getState();
        linkId = ((_b = (_a = store === null || store === void 0 ? void 0 : store.auth) === null || _a === void 0 ? void 0 : _a.magicLink) === null || _b === void 0 ? void 0 : _b.link) || null;
        // const fullMagicLink = store?.auth?.method === AUTH_METHODS.MAGIC_LINK && linkId
        //     ? new URL(
        //         `?${URL_PARAMS.MAGIC_LINK}=${linkId}`,
        //         window.location.origin,
        //       ).toString()
        //     : null;
        // if (window?.navigator?.sendBeacon) {
        //   const flowToSend = {
        //     ...flowData,
        //     projectId: store?.study?.studyData?.studyId,
        //     studyId: store?.study?.studyData?.studyId,
        //     sessionId: sessionId,
        //     magicLink:
        //       store?.auth?.method === AUTH_METHODS.MAGIC_LINK && linkId
        //         ? linkId
        //         : null,
        //     magicLinkUrl: fullMagicLink,
        //     studySystemVersion: store?.study?.studyData?.systemVersion,
        //     studyCustomVersion: store?.study?.studyData?.customVersion,
        //     screenWidth: window.outerWidth,
        //     screenHeight: window.outerHeight,
        //     viewportWidth: window.innerWidth,
        //     viewportHeight: window.innerHeight,
        //     lang: store?.lang?.current,
        //   };
        //   // By default beacons are "text/html", but changing it to "application/json"
        //   // makes it easier in the backend
        //   const payload = new Blob([JSON.stringify(flowToSend)], {
        //     type: 'application/json',
        //   });
        //   window.navigator.sendBeacon(
        //     `${getServerUrl()}/services/data/flow`,
        //     payload,
        //   );
        // }
        // TODO wmn-895 delete user flow tracking.
        // This is just to keep "event" out of Facts until the above ticket is done.
        return [2 /*return*/, flowData];
    });
}); });
export default flowTracking;
