var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { AUTH_METHODS } from 'dyn-design/dist/interfaces/StudyDataTypes';
import useDynalyticsCore from 'dyn-design/dist/hooks/useDynalyticsCore';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { studySelectors } from './store/slices/study';
import { langActions, langSelectors } from './store/slices/lang';
import { authSelectors } from './store/slices/auth';
import { appSelectors } from './store/slices/app';
import authenticateStudy from './store/thunks/authenticateStudy';
import LoadingStudy from './pages/LoadingStudy';
import { URL_PARAMS } from './constants/url';
import Router from './Router';
import withStudyTheme from './components/HOC/withStudyTheme';
import { SESSION_STORAGE_KEYS } from './constants/storageKey';
import LanguagePrompt from './pages/LanguagePrompt';
var App = function (_a) {
    var fontsReady = _a.fontsReady;
    var dispatch = useDispatch();
    var dynalyticsCore = useDynalyticsCore();
    var studyData = useSelector(studySelectors.studyData);
    var studySystemVersion = useSelector(studySelectors.studySystemVersion);
    var studyCustomVersion = useSelector(studySelectors.studyCustomVersion);
    var authMethod = useSelector(authSelectors.method);
    var authenticated = useSelector(authSelectors.authenticated);
    var magicLink = useSelector(authSelectors.magicLink);
    var appLoaded = useSelector(appSelectors.loaded);
    var availableLanguages = useSelector(studySelectors.languages);
    var requireLanguagePrompt = useSelector(studySelectors.requireLanguagePrompt);
    var langSelected = useSelector(langSelectors.selected);
    var _b = __read(useState(false), 2), canRenderStudy = _b[0], setCanRenderStudy = _b[1];
    /**
     * When the study is initialized, fetch the allowed authentication methods for
     * this study before attempting to fetch the actual study data.
     *
     * TODO: decouple magic links from this endpoint.
     */
    useEffect(function () {
        if (!authenticated) {
            dispatch(authenticateStudy());
        }
    }, []);
    /**
     * When a magic link is authenticated, add it to all future facts tracked in
     * this session.
     *
     * FIXME: This works for all facts except the first page_load.
     */
    useEffect(function () {
        if (!dynalyticsCore) {
            return;
        }
        var magicLinkToTrack = authMethod === AUTH_METHODS.MAGIC_LINK && magicLink ? magicLink : null;
        var fullMagicLink = authMethod === AUTH_METHODS.MAGIC_LINK && magicLink
            ? new URL("?".concat(URL_PARAMS.MAGIC_LINK, "=").concat(magicLink), window.location.origin).toString()
            : null;
        dynalyticsCore.addToAllFutureFactsThisSession('magicLink', magicLinkToTrack);
        dynalyticsCore.addToAllFutureFactsThisSession('magicLinkUrl', fullMagicLink);
        dynalyticsCore.addToAllFutureFactsThisSession('studySystemVersion', studySystemVersion);
        dynalyticsCore.addToAllFutureFactsThisSession('studyCustomVersion', studyCustomVersion);
    }, [magicLink]);
    /**
     * This is called on the first page load (or on the page refresh).
     * We can tell if user has refreshed page or not through `refreshCount`.
     */
    useEffect(function () {
        // `refreshCount` becomes 0 when user opens a first page of a study, and it
        // increased by 1 whenever the page is refreshed.
        var refreshCount = Number(sessionStorage.getItem(SESSION_STORAGE_KEYS.REFRESH_COUNT) || -1);
        sessionStorage.setItem(SESSION_STORAGE_KEYS.REFRESH_COUNT, "".concat(refreshCount + 1));
    }, []);
    /**
     * Detect if the language prompt is needed.
     * - if the study is from IOMP with more than or equal to two available languages
     * - if language is not selected (by a query parmeter or by the language
     *   prompt)
     *
     * Note: if a user opens a study by url with `lang`, the language prompt does
     * not appear, and the language cannot be changed in the same session.
     */
    var needsLanguagePrompt = function () {
        return requireLanguagePrompt && !langSelected && availableLanguages.length >= 2;
    };
    var setLanguage = function () {
        var _a;
        // When the app loads(including study data), look for "?lang" in the URL,
        // set the lanauge.
        if (window.location.search.includes("?".concat(URL_PARAMS.SET_LANGUAGE_ON_INIT))) {
            var url = new URL(window.location.href);
            var langToSet = (_a = url.searchParams
                .get(URL_PARAMS.SET_LANGUAGE_ON_INIT)) === null || _a === void 0 ? void 0 : _a.toUpperCase();
            if (availableLanguages.includes(langToSet)) {
                // changes language to use only if the given value with `lang` is in
                // available languages of the study.
                dispatch(langActions.setByUser(langToSet));
            }
            // remove the `lang` query parameter from url.
            url.searchParams.delete(URL_PARAMS.SET_LANGUAGE_ON_INIT);
            window.history.replaceState({}, '', url.href);
        }
    };
    /**
     * Determines if we can render the study.
     *
     */
    useEffect(function () {
        if (authenticated && !!studyData) {
            setLanguage();
            setCanRenderStudy(true);
        }
    }, [authenticated, studyData]);
    var isLoadingStudy = !appLoaded || !fontsReady;
    return (React.createElement("div", { id: 'app', tabIndex: 0 },
        canRenderStudy ? (needsLanguagePrompt() ? (React.createElement(LanguagePrompt, { languages: availableLanguages })) : (React.createElement(Router, null))) : null,
        isLoadingStudy && !canRenderStudy ? React.createElement(LoadingStudy, null) : null));
};
export default withStudyTheme(App);
