import { lighten, makeStyles } from '@material-ui/core';
import { ViewportMediaQueries } from '../../constants/viewport';
export var useStyles = makeStyles(function (theme) {
    var _a, _b, _c, _d;
    return ({
        page: (_a = {
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            },
            _a[ViewportMediaQueries.TabletAndBelow] = {
                paddingLeft: '38px',
                paddingRight: '38px',
            },
            _a),
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            maxWidth: '470px',
        },
        logo: (_b = {
                height: '140px',
                marginBottom: '100px'
            },
            _b[ViewportMediaQueries.TabletAndBelow] = {
                marginBottom: '60px',
            },
            _b),
        buttons: (_c = {
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
                width: '100%'
            },
            _c[ViewportMediaQueries.TabletAndBelow] = {
                flexDirection: 'column',
                gap: '11.5px',
            },
            _c),
        button: (_d = {
                fontSize: '14px',
                width: 'calc((100% - 20px)/3)',
                height: '36px',
                marginBottom: '10px',
                border: "1px solid ".concat(theme.palette.primary.main),
                color: theme.palette.primary.main,
                '&:hover': {
                    backgroundColor: lighten(theme.palette.primary.main, 0.8),
                }
            },
            _d[ViewportMediaQueries.TabletAndBelow] = {
                width: '100%',
                height: '42px',
                marginBottom: 0,
            },
            _d),
    });
});
