import { useSelector } from 'react-redux';
import { studySelectors } from '../store/slices/study';
/**
 * Reads the PAD JSON data to extract the question ID(s) for the current page.
 */
var useCurrentQuestionIds = function () {
    var _a;
    var currentStudyPage = useSelector(studySelectors.currentPage);
    var foundQuestionIds = [];
    if (!((_a = currentStudyPage === null || currentStudyPage === void 0 ? void 0 : currentStudyPage.pageData) === null || _a === void 0 ? void 0 : _a.rows)) {
        return foundQuestionIds;
    }
    // Finds question IDs by looking for an object key called "question" at any depth
    var recursivelySearchForQuestionIds = function (rows, foundCb) {
        var forcedArray = !Array.isArray(rows) && typeof rows === 'object' ? [rows] : rows;
        forcedArray.forEach(function (obj) {
            var _a;
            var keys = Object.keys(obj);
            if (keys.includes('question')) {
                foundCb((_a = obj.question) === null || _a === void 0 ? void 0 : _a.id);
            }
            else {
                Object.values(obj).forEach(function (value) {
                    if (value !== null &&
                        (typeof value === 'object' || Array.isArray(value))) {
                        recursivelySearchForQuestionIds(value, foundCb);
                    }
                });
            }
        });
    };
    recursivelySearchForQuestionIds(currentStudyPage.pageData.rows, function (id) { return foundQuestionIds.push(id); });
    return foundQuestionIds;
};
export default useCurrentQuestionIds;
