var _a;
import { createSlice } from '@reduxjs/toolkit';
var userSlice = createSlice({
    name: 'user',
    initialState: {
        userId: '',
        status: 'idle',
        error: null,
    },
    reducers: {
        login: function (state) {
            state.status = 'loading';
            state.error = null;
        },
        loginSuccess: function (state, action) {
            state.userId = action.payload.userId;
            state.status = 'succeeded';
            state.error = null;
        },
        loginFailure: function (state, action) {
            state.status = 'failed';
            state.error = action.payload;
        },
    },
});
export var userSelectors = {
    user: function (state) { var _a; return (_a = state.user) === null || _a === void 0 ? void 0 : _a.userId; },
};
export var login = (_a = userSlice.actions, _a.login), loginSuccess = _a.loginSuccess, loginFailure = _a.loginFailure;
export default userSlice;
