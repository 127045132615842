import { useSelector } from 'react-redux';
import useDynalytics from 'dyn-design/dist/hooks/useDynalytics';
import useDynalyticsData from 'dyn-design/dist/hooks/useDynalyticsData';
import { AUTH_METHODS } from 'dyn-design/dist/interfaces/StudyDataTypes';
import { authSelectors } from '../store/slices/auth';
import { studySelectors } from '../store/slices/study';
import { langSelectors } from '../store/slices/lang';
import { URL_PARAMS } from '../constants/url';
export var generateAnalyticsFactData = function (data, otherProps) {
    var _a, _b, _c;
    var studyId = otherProps.studyId, linkId = otherProps.linkId, sessionId = otherProps.sessionId, authMethod = otherProps.authMethod, studySystemVersion = otherProps.studySystemVersion, studyCustomVersion = otherProps.studyCustomVersion, lang = otherProps.lang, reason = otherProps.reason;
    var fullMagicLink = authMethod === AUTH_METHODS.MAGIC_LINK && linkId
        ? new URL("?".concat(URL_PARAMS.MAGIC_LINK, "=").concat(linkId), window.location.origin).toString()
        : null;
    var dataToSend = data.data || {};
    dataToSend.userId = null; // @todo: assign an actual value later once we have userId.
    dataToSend.projectAppId = studyId; // FIXME: move to App.tsx because this value persists throughout the whole session
    dataToSend.sessionId = sessionId; // FIXME: move to App.tsx because this value persists throughout the whole session
    dataToSend.sectionId = ((_a = dataToSend.step) === null || _a === void 0 ? void 0 : _a.groupId) || null;
    dataToSend.pageId = (_b = dataToSend === null || dataToSend === void 0 ? void 0 : dataToSend.step) === null || _b === void 0 ? void 0 : _b.id;
    dataToSend.pageTitle = (_c = dataToSend === null || dataToSend === void 0 ? void 0 : dataToSend.step) === null || _c === void 0 ? void 0 : _c.name; // FIXME: move to App.tsx because this value persists throughout the whole session
    dataToSend.magicLink =
        authMethod === AUTH_METHODS.MAGIC_LINK && linkId ? linkId : null; // FIXME: move to App.tsx because this value persists throughout the whole session
    dataToSend.magicLinkUrl = fullMagicLink; // FIXME: move to App.tsx because this value persists throughout the whole session
    dataToSend.studySystemVersion = studySystemVersion; // FIXME: move to App.tsx because this value persists throughout the whole session
    dataToSend.studyCustomVersion = studyCustomVersion; // FIXME: move to App.tsx because this value persists throughout the whole session
    dataToSend.lang = lang; // FIXME: move to App.tsx because this value persists throughout the whole session
    dataToSend.reason = reason;
    return dataToSend;
};
/**
 * This hook calls `dynalytics` function but it adds the default/extra props to the data to send.
 */
export var useAppEngineDynalytics = function () {
    var dynalytics = useDynalytics();
    var sessionId = useDynalyticsData().sessionId;
    var linkId = useSelector(authSelectors.magicLink);
    var authMethod = useSelector(authSelectors.method);
    var studyId = useSelector(studySelectors.studyId);
    var studySystemVersion = useSelector(studySelectors.studySystemVersion);
    var studyCustomVersion = useSelector(studySelectors.studyCustomVersion);
    var lang = useSelector(langSelectors.currentLang);
    var fullMagicLink = authMethod === AUTH_METHODS.MAGIC_LINK && linkId
        ? new URL("?".concat(URL_PARAMS.MAGIC_LINK, "=").concat(linkId), window.location.origin).toString()
        : null;
    var appEngineDynalytics = function (data) {
        var _a, _b, _c;
        var dataToSend = data.data || {};
        dataToSend.userId = null; // @todo: assign an actual value later once we have userId.
        dataToSend.projectAppId = studyId; // FIXME: move to App.tsx because this value persists throughout the whole session
        dataToSend.sessionId = sessionId; // FIXME: move to App.tsx because this value persists throughout the whole session
        dataToSend.sectionId = ((_a = dataToSend.step) === null || _a === void 0 ? void 0 : _a.groupId) || null;
        dataToSend.pageId = (_b = dataToSend === null || dataToSend === void 0 ? void 0 : dataToSend.step) === null || _b === void 0 ? void 0 : _b.id;
        dataToSend.pageTitle = (_c = dataToSend === null || dataToSend === void 0 ? void 0 : dataToSend.step) === null || _c === void 0 ? void 0 : _c.name; // FIXME: move to App.tsx because this value persists throughout the whole session
        dataToSend.magicLink =
            authMethod === AUTH_METHODS.MAGIC_LINK && linkId ? linkId : null; // FIXME: move to App.tsx because this value persists throughout the whole session
        dataToSend.magicLinkUrl = fullMagicLink; // FIXME: move to App.tsx because this value persists throughout the whole session
        dataToSend.studySystemVersion = studySystemVersion; // FIXME: move to App.tsx because this value persists throughout the whole session
        dataToSend.studyCustomVersion = studyCustomVersion; // FIXME: move to App.tsx because this value persists throughout the whole session
        dataToSend.lang = lang; // FIXME: move to App.tsx because this value persists throughout the whole session
        dynalytics(data.factName, dataToSend, data.options);
    };
    return appEngineDynalytics;
};
