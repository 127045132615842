import { useSelector } from 'react-redux';
import { studySelectors } from '../store/slices/study';
import { PAGE_DATA_TYPE } from 'dyn-design/dist/interfaces/StudyPageDataTypes';
/**
 * Returns a function that checks if the given (or the current) page contains 1
 * or more questions.
 */
var useIsQuestionPage = function () {
    var currentStudyPage = useSelector(studySelectors.currentPage);
    return function (pageData) {
        var _a, _b;
        var pageToAnalyze = pageData || currentStudyPage;
        var isQuestionPage = false;
        // Check if any of the columns in any of the rows is of type "Input"
        (_b = (_a = pageToAnalyze === null || pageToAnalyze === void 0 ? void 0 : pageToAnalyze.pageData) === null || _a === void 0 ? void 0 : _a.rows) === null || _b === void 0 ? void 0 : _b.forEach(function (row) {
            var _a;
            (_a = row === null || row === void 0 ? void 0 : row.cols) === null || _a === void 0 ? void 0 : _a.forEach(function (col) {
                var _a;
                if (((_a = col === null || col === void 0 ? void 0 : col.content) === null || _a === void 0 ? void 0 : _a.type) === PAGE_DATA_TYPE.INPUT) {
                    isQuestionPage = true;
                }
            });
        });
        return isQuestionPage;
    };
};
export default useIsQuestionPage;
