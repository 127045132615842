export var DEFAULT_LANGUAGE = 'EN';
/**
 * Return a string matching to `lang` from `i18nStrings` but this function
 * prevents possible errors if text for a particular language doesn't exist.
 */
export var i18n = function (i18nStrings, lang) {
    if (!i18nStrings) {
        return '';
    }
    return (i18nStrings[lang]
        || i18nStrings[lang.toUpperCase()]
        || i18nStrings[lang.toLowerCase()]
        || i18nStrings[DEFAULT_LANGUAGE.toUpperCase()]
        || i18nStrings[DEFAULT_LANGUAGE.toLowerCase()]
        || '');
};
/**
 * `PageMessageType`(for VA) has different structure which has a string in
 * deeper depth. So this function should return an object that contains the
 * remaining properties. If there is other similar structures, they will be
 * handled by this function.
 */
export var i18nThrough = function (i18nObject, lang) {
    if (!i18nObject) {
        return { messageContent: { ssml: '', text: '' } };
    }
    return (i18nObject[lang]
        || i18nObject[lang.toUpperCase()]
        || i18nObject[lang.toLowerCase()]
        || i18nObject[DEFAULT_LANGUAGE.toUpperCase()]
        || i18nObject[DEFAULT_LANGUAGE.toLowerCase()])
        || { messageContent: { ssml: '', text: '' } };
};
