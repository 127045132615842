import Study from './pages/Study';
import StudyEnd from './pages/StudyEnd';
import PageNotFound from './pages/PageNotFound';
import Maintenance from './pages/Maintenance';
;
var ROUTES = {
    HOME: {
        path: '/',
        component: Study,
    },
    STUDY: {
        path: '/project',
        component: Study,
    },
    STUDY_PAGE: {
        path: '/project/:section/:page',
        component: Study,
    },
    STUDY_END: {
        path: '/thank-you',
        component: StudyEnd,
    },
    MAINTENANCE: {
        path: '/maintenance',
        component: Maintenance,
    },
    PAGE_NOT_FOUND: {
        path: '',
        component: PageNotFound,
    },
};
export default ROUTES;
