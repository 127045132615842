var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { useTheme } from '@material-ui/core/styles';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
import TaskStepper, { Step } from 'dyn-design/dist/components/TaskStepper';
import { actions as taskStepperActions } from 'dyn-design/dist/components/TaskStepper/slice';
import MediaConfigProvider from 'dyn-design/dist/components/StepLayout/ReusableComponents/MediaConfigProvider';
import StudyStep from '../../components/StudyStep';
import StudyVA from '../../components/StudyVA';
import SnackBar from '../../components/SnackBar';
import useIsReviewPage from '../../hooks/useIsReviewPage';
import useIsQuestionPage from '../../hooks/useIsQuestionPage';
import { PAGE_DATA_TYPE } from 'dyn-design/dist/interfaces/StudyPageDataTypes';
import { studySelectors } from '../../store/slices/study';
import ROUTES from '../../routes';
import { useStyles } from './styles';
import { remoteDataSelectors, SAVE_STATUS, } from '../../store/slices/remoteData';
import { authActions, authSelectors } from '../../store/slices/auth';
import GlobalToast from '../../components/GlobalToast';
import getServerUrl, { getPrivateMediaDisabled, } from '../../utils/getServerUrl';
import SEOMetaData from '../../components/SEOMetaData';
import { langSelectors } from '../../store/slices/lang';
import { getLanguageValue } from '../../utils/languageUtils';
var Study = function () {
    var dispatch = useDispatch();
    var themeBackground = useTheme().palette.background.default;
    var styles = useStyles();
    var lang = useSelector(langSelectors.currentLang);
    var studyName = useSelector(studySelectors.studyName);
    var authData = useSelector(authSelectors.authData);
    var magicLink = useSelector(authSelectors.magicLink);
    var sections = useSelector(studySelectors.sections);
    var currentSection = useSelector(studySelectors.currentSection);
    var currentPage = useSelector(studySelectors.currentPage);
    var sessionIsComplete = useSelector(studySelectors.sessionIsComplete);
    var isReviewPage = useIsReviewPage();
    var params = useParams();
    var isQuestionPage = useIsQuestionPage();
    var lastSaveAttempt = useSelector(remoteDataSelectors.resultOfLastSaveAttempt);
    var history = useHistory();
    var _a = __read(useState(true), 2), waiting = _a[0], setWaiting = _a[1];
    var magicLinkData = useSelector(authSelectors.magicLinkData);
    var location = useLocation();
    var testcode = location.search
        ? new URLSearchParams(location.search).get('testcode') || undefined
        : undefined;
    /**
     * Return the step number of the first page in a section matching to the given
     * sectionId. It returns 0 if `linkedSectionId` is not given.
     */
    var findInitialStep = function () {
        var e_1, _a;
        if (magicLinkData === null || magicLinkData === void 0 ? void 0 : magicLinkData.linkedSectionId) {
            var pageIndex = -1;
            try {
                for (var _b = __values(sections), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var section = _c.value;
                    if (section.id === magicLinkData.linkedSectionId) {
                        pageIndex += 1;
                        break;
                    }
                    else {
                        pageIndex += section.pages.length;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            // Note: TaskStepper's currentStep starts from 1. It's not from 0 like
            // array index.
            return pageIndex + 1;
        }
        return 1;
    };
    /**
     * Return the cached `currentStep`. It means the one which was lastly seen.
     */
    var getCachedCurrentStepNum = function () {
        var _a, _b;
        return (_b = (_a = JSON.parse(sessionStorage.getItem('persistedState') || '{}')) === null || _a === void 0 ? void 0 : _a.taskStepper) === null || _b === void 0 ? void 0 : _b.currentStep;
    };
    /**
     * Return stepNumber that matches the question in the URL
     */
    var getStepNumFromParams = function (params) {
        var _a, _b;
        var steps = ((_b = (_a = JSON.parse(sessionStorage.getItem('persistedState') || '{}')) === null || _a === void 0 ? void 0 : _a.taskStepper) === null || _b === void 0 ? void 0 : _b.steps) || [];
        var stepIndex = steps.findIndex(function (step) { return (step === null || step === void 0 ? void 0 : step.group) === (params === null || params === void 0 ? void 0 : params.section) && (step === null || step === void 0 ? void 0 : step.name) === (params === null || params === void 0 ? void 0 : params.page); });
        return stepIndex + 1;
    };
    /**
     * Determines if the current page has a header.
     */
    var pageHasHeader = !!currentPage.pageAppBar;
    /**
     * If the user has already completed the study in this session, block them
     * from accessing study pages again.
     */
    useEffect(function () {
        if (sessionIsComplete) {
            location.href = ROUTES.STUDY_END.path;
        }
    });
    /**
     * Called when a user types a URL into the browser and press the return key.
     * Note: Make sure that this useEffect locates above the other useEffects with dependencies.
     */
    useEffect(function () {
        var stepNum = getStepNumFromParams(params);
        var cachedCurrentStepNum = getCachedCurrentStepNum();
        dispatch(authActions.startPolling(undefined));
        // `stepNum !== cachedCurrentStepNum` means that a user types a specific URL into the browser.
        // `stepNum === 1 && cachedCurrentStepNum === 1`: exceptionally they have the same value on the first page.
        if (stepNum !== cachedCurrentStepNum ||
            (stepNum === 1 && cachedCurrentStepNum === 1)) {
            // If it's the initial load
            if (stepNum === 0) {
                var initialStep = findInitialStep();
                dispatch(taskStepperActions.goToStep(initialStep));
            }
            // If it's a direct access by entering an url
            else {
                // `stepNum` is actually what a user wants to move to
                dispatch(taskStepperActions.goToStep(stepNum));
            }
        }
    }, []);
    /**
     * Move to a page matching to the current URL whenever `params` changes
     */
    useEffect(function () {
        var stepNum = getStepNumFromParams(params);
        var cachedCurrentStepNum = getCachedCurrentStepNum();
        // Here, `stepNum === cachedCurrentStepNum` means the page changed by
        // TaskStepper(buttons) not by manually typed URL.
        if ((params === null || params === void 0 ? void 0 : params.section) && (params === null || params === void 0 ? void 0 : params.page) && stepNum === cachedCurrentStepNum) {
            // TODO: `steps` should be possible to get from `TaskStepper`'s `steps`
            // selector. This selectors causes the infinite loop when it is called
            // outside TaskStepper
            if (stepNum >= 1) {
                dispatch(taskStepperActions.goToStep(stepNum));
            }
        }
        // But, it should change the current page when the browser's back button is clicked
        else if (history.action === 'POP') {
            if (stepNum !== 0) {
                dispatch(taskStepperActions.goToStep(stepNum));
            }
        }
    }, [params.page]);
    /**
     * Create a URL and push it to update URL for the browser whenever the current
     * section or page changes
     */
    useEffect(function () {
        // Here, `!waiting` condition is for not to `push(path)` when an URL is
        // typed. It shouldn't push the path because a user already entered the URL.
        var sectionSlug = getLanguageValue(lang, currentSection === null || currentSection === void 0 ? void 0 : currentSection.slug);
        var pageSlug = getLanguageValue(lang, currentPage === null || currentPage === void 0 ? void 0 : currentPage.slug);
        if (sectionSlug && pageSlug && !waiting) {
            var path = "/project/".concat(sectionSlug, "/").concat(pageSlug).concat(window.location.search);
            // This condition prevents from the infinite loop which is caused by the
            // useEffect above when the browser's back button is clicked.
            if (path !==
                "/project/".concat(params.section, "/").concat(params.page).concat(window.location.search)) {
                dispatch(push(path));
            }
        }
        // Allows the URL to be able to change(see the if-condition above). Without
        // this, URL won't change on right next/prev page even though the actual
        // page changes.
        setWaiting(false);
    }, [currentSection, currentPage]);
    /**
     * Set the background color for the current page on document <body>.
     */
    useEffect(function () {
        var _a, _b, _c;
        var bgColor = ((_a = currentPage === null || currentPage === void 0 ? void 0 : currentPage.pageStyles) === null || _a === void 0 ? void 0 : _a.backgroundColor) || themeBackground;
        if (bgColor) {
            (_b = document
                .querySelector('body')) === null || _b === void 0 ? void 0 : _b.setAttribute('style', "background-color: ".concat(bgColor, ";"));
        }
        else {
            (_c = document.querySelector('body')) === null || _c === void 0 ? void 0 : _c.removeAttribute('style');
        }
    }, [currentPage]);
    /**
     * Scroll to top automatically on each step.
     */
    useLayoutEffect(function () {
        setTimeout(function () {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 200);
    }, [currentPage]);
    return sections ? (React.createElement(MediaConfigProvider, { studyUrl: window.location.origin, mediaServiceUrl: getServerUrl(), magicLink: magicLink, disabled: getPrivateMediaDisabled(), testcode: testcode },
        React.createElement(SEOMetaData, { lang: lang, studyName: studyName, studyPage: currentPage }),
        React.createElement("div", { className: "".concat(styles.studyPage, " ").concat(!pageHasHeader ? styles.noHeader : '') },
            React.createElement(StudyVA, null),
            React.createElement("div", { className: styles.studySteps },
                React.createElement(TaskStepper, { name: 'study-stepper' }, Array.isArray(sections) && sections.length ? (sections.map(function (section) {
                    return section === null || section === void 0 ? void 0 : section.pages.map(function (page) {
                        var pageType = (function () {
                            if (isReviewPage(page)) {
                                return PAGE_DATA_TYPE.REVIEW;
                            }
                            else {
                                return PAGE_DATA_TYPE.INPUT;
                            }
                        })();
                        return (React.createElement(Step, { key: getLanguageValue(lang, page.slug), name: getLanguageValue(lang, page.slug), group: getLanguageValue(lang, section.slug), 
                            // @ts-ignore need to add this prop in DS
                            groupId: section.id, 
                            // @ts-ignore need to add this prop in DS
                            id: page.id, type: pageType, hasQuestions: isQuestionPage(page) },
                            React.createElement(StudyStep, { showHeader: pageHasHeader })));
                    });
                })) : (React.createElement(React.Fragment, null)))),
            React.createElement(SnackBar, { show: lastSaveAttempt === SAVE_STATUS.failed }, "Could not contact the remote server"),
            React.createElement(GlobalToast, null)))) : null;
};
export default Study;
