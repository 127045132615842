var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useSelector } from 'react-redux';
import useCurrentQuestionIds from '../hooks/useCurrentQuestionIds';
import { selectors as taskStepperSelectors } from 'dyn-design/dist/components/TaskStepper/slice';
import { COMPONENT_TYPES } from 'dyn-design/dist/interfaces/StudyPageDataTypes';
/**
 * Returns a flat object of answers for this session.
 *
 * @param {Object} [options]
 * @param {String} [options.forCurrentPageOnly]
 * @param {String} [options.format] -
 *  `saved` returns the answer objects that include question data.
 *  `raw` returns only the answer data.
 *  `conditionals` returns only the answer data, but formatted to be run through the conditions engine.
 */
var useSessionAnswers = function (options, currentPageAnswers) {
    if (options === void 0) { options = {}; }
    if (currentPageAnswers === void 0) { currentPageAnswers = null; }
    var stepData = useSelector(taskStepperSelectors.stepData);
    var currentQuestionIds = useCurrentQuestionIds();
    var answersWithoutGroups = function () {
        var flatAnswers = {};
        // Ungroup answers
        if (stepData && Object.keys(stepData).length) {
            Object.values(stepData).forEach(function (groupAnswers) {
                if (Object.keys(groupAnswers).length) {
                    Object.keys(groupAnswers).forEach(function (questionId) {
                        var _a, _b;
                        // Filter out questions that do not require user's answer.
                        if (questionId === 'undefined') {
                            return;
                        }
                        // Maybe filter out questions that are not currenly being shown to the user
                        if ((options === null || options === void 0 ? void 0 : options.forCurrentPageOnly) && !currentQuestionIds.includes(questionId)) {
                            return;
                        }
                        // Filter out questions for the submitted form(PAG.FS.24.25)
                        if ((_a = groupAnswers[questionId].question) === null || _a === void 0 ? void 0 : _a.formId) {
                            return;
                        }
                        if ((options === null || options === void 0 ? void 0 : options.format) === 'raw') {
                            flatAnswers[questionId] = (_b = groupAnswers === null || groupAnswers === void 0 ? void 0 : groupAnswers[questionId]) === null || _b === void 0 ? void 0 : _b.answer;
                        }
                        else {
                            flatAnswers[questionId] = groupAnswers === null || groupAnswers === void 0 ? void 0 : groupAnswers[questionId];
                        }
                    });
                }
            });
        }
        // Format the answer object in a way that the conditional engine supports
        if ((options === null || options === void 0 ? void 0 : options.format) === 'conditionals') {
            // TODO When the conditional button is on the same page as the input used
            // in the conditional logic, this requires the page answer
            var answersToUseForConditions_1 = __assign(__assign({}, flatAnswers), currentPageAnswers);
            return Object.keys(answersToUseForConditions_1).map(function (questionId) {
                var _a, _b;
                var answerObj = answersToUseForConditions_1[questionId];
                switch (answerObj.input.component) {
                    case COMPONENT_TYPES.MULTI_SELECT:
                    case COMPONENT_TYPES.RANGE:
                    case COMPONENT_TYPES.SLIDER:
                        return _a = {},
                            _a[questionId] = __assign(__assign({}, answerObj), { answer: Object.values(answerObj.answer) }),
                            _a;
                    default:
                        // Convert number-like strings to real numbers
                        var ensureNum = function (num) {
                            var parsed = Number(num);
                            if (Number.isNaN(parsed)) {
                                return num;
                            }
                            else {
                                return parsed;
                            }
                        };
                        return _b = {},
                            _b[questionId] = __assign(__assign({}, answerObj), { id: Array.isArray(answerObj.id) ? answerObj.id[0] : answerObj.id, answer: (typeof answerObj.answer === 'object'
                                    && !Array.isArray(answerObj.answer)
                                    && answerObj.answer !== null)
                                    ? ensureNum(Object.values(answerObj.answer)[0])
                                    : ensureNum(answerObj.answer[0]) }),
                            _b;
                }
            });
        }
        else {
            return flatAnswers;
        }
    };
    return answersWithoutGroups();
};
export default useSessionAnswers;
