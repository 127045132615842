import { DEFAULT_LANGUAGE } from './i18n';
/**
 * Generic function to extract a string name from new name type props
 * @param lang
 * @param value
 * @returns
 */
export var getLanguageValue = function (lang, value) {
    return typeof value === 'string' ? value : value === null || value === void 0 ? void 0 : value[lang || DEFAULT_LANGUAGE];
};
