import React from 'react';
import { Helmet } from 'react-helmet-async';
import { getLanguageValue } from '../../utils/languageUtils';
var SEOMetaData = function (_a) {
    var lang = _a.lang, studyName = _a.studyName, studyPage = _a.studyPage;
    var seoProps = studyPage.seoProps;
    var generateTitle = function () {
        var _a;
        var defaultTitle = studyPage.name
            ? "".concat(getLanguageValue(lang, studyPage.name), " - ").concat(studyName)
            : studyName;
        return React.createElement("title", null, ((_a = seoProps === null || seoProps === void 0 ? void 0 : seoProps.title) === null || _a === void 0 ? void 0 : _a[lang]) || defaultTitle);
    };
    var generateDescription = function () {
        var _a;
        return (React.createElement("meta", { property: 'description', content: ((_a = seoProps === null || seoProps === void 0 ? void 0 : seoProps.description) === null || _a === void 0 ? void 0 : _a[lang]) || '' }));
    };
    var generateAuthors = function () {
        var authors = [];
        ((seoProps === null || seoProps === void 0 ? void 0 : seoProps.authors) || []).forEach(function (author) {
            if (author === null || author === void 0 ? void 0 : author.name) {
                authors.push(React.createElement("meta", { name: 'author', content: author.name }));
            }
            if (author === null || author === void 0 ? void 0 : author.url) {
                authors.push(React.createElement("link", { rel: 'author', href: author.url }));
            }
        });
        return authors;
    };
    var generateKeywords = function () {
        var _a;
        var keywords = (_a = seoProps === null || seoProps === void 0 ? void 0 : seoProps.keywords) === null || _a === void 0 ? void 0 : _a[lang];
        if (keywords && Array.isArray(keywords) && keywords.length > 0) {
            return React.createElement("meta", { name: 'keywords', content: keywords.join(',') });
        }
        return null;
    };
    var generateStructuredMarkup = function () {
        var markups = [];
        ((seoProps === null || seoProps === void 0 ? void 0 : seoProps.markups) || []).forEach(function (markup) {
            var _a;
            if ((_a = markup === null || markup === void 0 ? void 0 : markup.jsonSchema) === null || _a === void 0 ? void 0 : _a[lang]) {
                try {
                    markups.push(React.createElement("script", { type: 'application/ld+json' }, JSON.stringify(JSON.parse(markup.jsonSchema[lang]))));
                }
                catch (e) {
                    console.error('Check the structured markup:', markup.id);
                }
            }
        });
        return markups;
    };
    var generateOpenGraphTags = function () {
        var _a, _b;
        var openGraphTags = [];
        var og = seoProps === null || seoProps === void 0 ? void 0 : seoProps.og;
        if ((_a = og === null || og === void 0 ? void 0 : og.title) === null || _a === void 0 ? void 0 : _a[lang]) {
            openGraphTags.push(React.createElement("meta", { property: 'og:title', content: og.title[lang] }));
        }
        if ((_b = og === null || og === void 0 ? void 0 : og.image) === null || _b === void 0 ? void 0 : _b.url) {
            openGraphTags.push(React.createElement("meta", { property: 'og:image', content: og.image.url }));
        }
        return openGraphTags;
    };
    var generateTrackingScripts = function () {
        // Note: this hardcoded script is temporary.
        if (window.location.origin.includes('targetals-funding.biospective.com')) {
            return (React.createElement("script", { async: true, src: 'https://tag.clearbitscripts.com/v1/pk_9d82ea224b8333b9993e7772bae91c10/tags.js', referrerPolicy: 'strict-origin-when-cross-origin' }));
        }
        // TODO: a field for script string such as a js function should be added,
        // and two cases(with url, with script code) should be handled differently.
        // This will be updated in PAD first and then here. Until then, the code
        // below will be commented.
        // const trackingScripts: any[] = [];
        // (seoProps?.trackingScripts || []).forEach((script) => {
        //   if (script?.name && script?.url) {
        //     trackingScripts.push(
        //       <script
        //         async
        //         id={script.name}
        //         type='text/javascript'
        //         dangerouslySetInnerHTML={{
        //           __html: script.url,
        //         }}
        //       />,
        //     );
        //   }
        // });
        return null;
    };
    return (React.createElement(Helmet, null,
        generateTitle(),
        generateDescription(),
        generateAuthors(),
        generateKeywords(),
        generateStructuredMarkup(),
        generateOpenGraphTags(),
        generateTrackingScripts()));
};
export default SEOMetaData;
