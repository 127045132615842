import { useSelector } from 'react-redux';
import { studySelectors } from '../store/slices/study';
import { selectors as taskStepperSelectors } from 'dyn-design/dist/components/TaskStepper/slice';
/**
 * Returns a function that returns a section step number using a given section ID.
 */
var useSectionFinder = function () {
    var studyData = useSelector(studySelectors.studyData);
    var taskStepperSteps = useSelector(taskStepperSelectors.steps);
    return function (sectionId) {
        var _a;
        var foundSection = studyData.sections.find(function (section) { return section.id === sectionId; });
        var firstStepInSection = (_a = foundSection === null || foundSection === void 0 ? void 0 : foundSection.pages) === null || _a === void 0 ? void 0 : _a[0];
        var indexToGoTo = taskStepperSteps.findIndex(function (step) { return (step === null || step === void 0 ? void 0 : step.id) === firstStepInSection.id; });
        return indexToGoTo + 1;
    };
};
export default useSectionFinder;
