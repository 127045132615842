var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { getDefaultMiddleware, configureStore as createStore, } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import { routerMiddleware } from 'connected-react-router';
import history from './history';
import reducers from './slices';
import rootEpic from './epics';
/**
 * Create and return the store instance for this application.
 */
function configureStore() {
    var epicMiddleware = createEpicMiddleware();
    var middlewares = [epicMiddleware, routerMiddleware(history)];
    var store = createStore({
        reducer: reducers,
        //devTools: process.env.NODE_ENV !== 'production',
        devTools: true,
        preloadedState: JSON.parse(sessionStorage.getItem('persistedState') || '{}'),
        middleware: __spreadArray(__spreadArray([], __read(getDefaultMiddleware({
            serializableCheck: false,
        })), false), __read(middlewares), false),
    });
    store.subscribe(function () {
        sessionStorage.setItem('persistedState', JSON.stringify(store.getState()));
    });
    epicMiddleware.run(rootEpic);
    return store;
}
export default configureStore;
