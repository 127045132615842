import useTheme from '@material-ui/core/styles/useTheme';
import React from 'react';
import { useSelector } from 'react-redux';
import Soleil from 'dyn-design/dist/components/Soleil';
import MessageBubble from 'dyn-design/dist/components/MessageBubbleV2/MessageBubble';
import { VA_ANIMATION_TYPE } from 'dyn-design/dist/components/Soleil/Soleil';
import { studySelectors } from '../../store/slices/study';
import { useStyles } from './styles';
import { langSelectors } from '../../store/slices/lang';
import { i18nThrough } from '../../utils/i18n';
import { useStudyMasterStyles } from '../../hooks/useMasterStyles';
var StudyVA = function () {
    var styles = useStyles();
    var theme = useTheme();
    var _a = useStudyMasterStyles(), vaDataIcon = _a.vaDataIcon, messageBubble = _a.messageBubble;
    var currentStudyPage = useSelector(studySelectors.currentPage);
    var pageMessages = currentStudyPage.pageMessages;
    var lang = useSelector(langSelectors.currentLang);
    var hasPageMessages = function () {
        if (!Array.isArray(pageMessages) || !pageMessages || !pageMessages.length) {
            return false;
        }
        var textFromEachPage = pageMessages.map(function (message) { var _a, _b, _c; return (_c = (_b = (_a = message === null || message === void 0 ? void 0 : message.text) === null || _a === void 0 ? void 0 : _a[lang]) === null || _b === void 0 ? void 0 : _b.messageContent) === null || _c === void 0 ? void 0 : _c.text; });
        return !!textFromEachPage.find(function (text) { return !!text; });
    };
    var renderMessages = function (pageMessages) {
        if (!Array.isArray(pageMessages) || !pageMessages || !pageMessages.length) {
            return '';
        }
        // `dangerouslySetInnerHTML` is used as message data from PAD wraps text with <p></p>
        var messages = pageMessages.map(function (message, index) {
            return (React.createElement(MessageBubble, { key: "message-".concat(index), variant: messageBubble === null || messageBubble === void 0 ? void 0 : messageBubble.variant, position: messageBubble === null || messageBubble === void 0 ? void 0 : messageBubble.position, padding: '10px 14px', maxWidth: '270px', minWidth: '240px', backgroundColor: theme.palette.primary.main },
                React.createElement("li", { className: styles.message, dangerouslySetInnerHTML: {
                        __html: i18nThrough(message.text, lang).messageContent.text,
                    } })));
        });
        return messages;
    };
    return hasPageMessages() ? (React.createElement("div", { className: styles.va },
        React.createElement(Soleil, { className: styles.soleil, animation: ((vaDataIcon === null || vaDataIcon === void 0 ? void 0 : vaDataIcon.type) === 'dynamikon' && vaDataIcon.value) ||
                VA_ANIMATION_TYPE.SPEAKING }),
        React.createElement("ul", { className: styles.messageContainer }, renderMessages(pageMessages)))) : null;
};
export default StudyVA;
