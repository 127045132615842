import React from 'react';
import { useSelector } from 'react-redux';
import BaseProgressBar from 'dyn-design/dist/components/ProgressBar/ProgressBar';
import { selectors as taskStepperSelectors } from 'dyn-design/dist/components/TaskStepper/slice';
import { useStyles } from './styles';
import useTheme from '@material-ui/core/styles/useTheme';
var ProgressBar = function () {
    var styles = useStyles();
    var theme = useTheme();
    var _a = useSelector(taskStepperSelectors.groups), currentStepInGroup = _a.currentStepInGroup, totalStepsInGroup = _a.totalStepsInGroup;
    var getProgress = function () { return (currentStepInGroup / totalStepsInGroup) * 100; };
    return (React.createElement("div", { className: "study-progress-bar ".concat(styles.progressBar) },
        React.createElement(BaseProgressBar, { foregroundColor: theme.palette.secondary.dark, backgroundColor: theme.palette.secondary.light, percentage: getProgress() })));
};
export default ProgressBar;
